<template>
  <app-page :use-back-icon="false" title="供应商快递">
    <fake-search class="top" placeholder="搜索订单" search-text="" @searchClick="paramsVisible = true"></fake-search>
    <div class="data-list">
      <van-pull-refresh v-model="loading" @refresh="handleReload">
        <empty-data v-if="dataList.length === 0" />
        <van-list
            class="shipment-list"
            v-model="loading"
            :finished="noMoreData"
            finished-text="没有更多数据了"
            @load="handleLoadData"
        >
          <van-swipe-cell class="list-item-inset" v-for="item in dataList" :key="item.trackNo">
            <div class="shipment-item" @click="handleNavItemList(item)">
              <div class="left-right-content" style="margin-bottom: 10px;">
                <div class="font-bold font-large text-primary full">
                  <span @click.stop.prevent="handleNavTracking(item.trackNo)">{{item.trackNo}}</span>
                  <span @click.stop.prevent="copyContent(item.trackNo)" style="margin-left: 15px; font-size: 12px; font-weight: normal;">复制</span>
                </div>
                <div class="font-mini text-muted">{{item.logisticsName}}</div>
              </div>
              <div class="left-right-content font-mini text-normal">
                <div>{{item.supplierName}}</div>
                <div>数量：<span class="font-bold font-large">{{item.total}}</span></div>
              </div>
              <div class="left-right-content text-normal font-mini">
                <div>
                  <span v-if="item.status === 1" class="text-success font-mini">已签收</span>
                  <span v-else class="text-danger font-mini">已发货</span>
                </div>
                <div>{{item.shipTime}}</div>
              </div>
            </div>
            <template #right v-if="!isSupplier">
              <van-button square type="danger" text="删除" @click="handleDelete(item)" />
              <van-button square type="default" text="编辑" @click="handleOpenEditor(item)" />
            </template>
          </van-swipe-cell>

        </van-list>
      </van-pull-refresh>
    </div>

    <editor :visible.sync="editor.visible" title="供应商快递" :saving="editor.saving" @save="handleSave">
      <van-field v-model="editor.form.trackNo" label="快递单号" clearable />
      <van-field label="是否已签收">
        <template #input>
          <van-switch v-model="editor.form.status" size="20" :active-value="1" :inactive-value="0" />
        </template>
      </van-field>
    </editor>

    <editor title="查询"
            :visible.sync="paramsVisible"
            :saving="loading"
            size="large"
            @save="handleReload"
            save-button-text="查询"
    >
      <van-field v-model="params.trackNo" label="快递单号" clearable />
    </editor>
  </app-page>
</template>

<script>

import { listSupplierShipments, deleteSupplierShipment, updateSupplierShipment } from '../http/api/produce-item-shipment'
import kit from '@/utils/kit'
import {
  ROUTE_SUPPLIER_SHIPMENT_ITEM,
  ROUTE_SUPPLIER_SHIPMENT_ITEM_TRACKING
} from '../router/router-constants'
import copyToClipboard from 'copy-to-clipboard'

export default {
  data () {
    return {
      loading: false,
      buttonLoading: false,
      noMoreData: false,
      dataList: [],
      params: {
        trackNo: null,
        supplierId: null
      },
      paramsVisible: false,
      limit: 30,
      start: 0,
      editor: {
        visible: false,
        saving: false,
        editingItem: null,
        form: {
          trackNo: null,
          status: 0
        }
      }
    }
  },
  computed: {
    isSupplier () {
      return this.$userStore.get('isSupplier')
    }
  },
  methods: {
    copyContent (content) {
      copyToClipboard(content)
      this.$message.success('复制成功。')
    },
    onTabFirstView () {
      this.handleReload()
    },
    onTabViewVisible () {
    },
    onTabViewInvisible () {
    },
    handleReload () {
      this.start = 0
      this.handleLoadData()
      this.paramsVisible = false
    },
    handleLoadData () {
      const params = Object.assign({
        start: this.start,
        limit: this.limit
      }, this.params)

      this.loading = true
      listSupplierShipments()
        .complete((success) => {
          this.loading = false
          if (!success) {
            this.noMoreData = true
          }
        })
        .success(resp => {
          // 处理分页信息
          if (this.start === 0) {
            this.dataList = []
          }
          this.start += this.limit
          this.noMoreData = this.start >= resp.data.total

          this.dataList.push(...resp.data.records)
        })
        .send(params)
    },
    handleDelete (item) {
      this.$message.confirm('确认', `确定要删除${item.trackNo}吗？`, () => {
        this.$loading.open()
        deleteSupplierShipment()
          .complete(() => (this.$loading.close()))
          .success(() => {
            kit.arr.removeItem(this.dataList, item)
          })
          .send(item.trackNo)
      })
    },
    handleOpenEditor (item) {
      this.editor.editingItem = item
      this.editor.visible = true
      this.editor.form.trackNo = item.trackNo
      this.editor.form.status = item.status
    },
    handleSave () {
      this.editor.saving = true
      updateSupplierShipment()
        .complete(() => (this.editor.saving = false))
        .success(() => {
          this.editor.editingItem.trackNo = this.editor.form.trackNo
          this.editor.editingItem.status = this.editor.form.status
          this.editor.editingItem = null
          this.editor.visible = false
          this.$message.success('保存成功。')
        })
        .send(this.editor.editingItem.trackNo, this.editor.form.trackNo, this.editor.form.status)
    },
    handleNavItemList (item) {
      this.$router.push({ name: ROUTE_SUPPLIER_SHIPMENT_ITEM, params: { trackNo: item.trackNo } })
    },
    handleNavTracking (trackNo) {
      this.$router.push({ name: ROUTE_SUPPLIER_SHIPMENT_ITEM_TRACKING, query: { title: '顺丰快递查询', url: `https://www.sf-express.com/mobile/cn/sc/dynamic_functions/waybill/detail/${trackNo}` } })
    }
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
.shipment-item {
  padding: 10px;
  background-color: #fff;
}
</style>
