<template>
  <app-page :title="pageTitle" right-icon="plus" @rightClick="handleAddUser">
    <van-pull-refresh
        v-model="loading"
        success-text="刷新成功"
        @refresh="loadData"
        :disabled="loading"
    >
      <user-list
          :users="users"
          style="min-height: 300px;"
          @editClick="handleEditUser"
      >
      </user-list>
    </van-pull-refresh>
    <user-editor ref="userEditor" :supplier-id="supplierId" @saved="handleUserSaved"></user-editor>
  </app-page>
</template>

<script>
import UserList from '../user/comp/user-list'
import UserEditor from '../user/comp/user-editor'
import { getSupplier, listSupplierUser } from '../../http/api/supplier'
import kit from '@/utils/kit'

export default {
  components: { UserList, UserEditor },
  data () {
    return {
      pageTitle: '供应商人员',
      users: [],
      loading: false
    }
  },
  computed: {
    supplierId () {
      return this.$route.params.id
    }
  },
  methods: {
    handleAddUser () {
      this.$refs.userEditor.open()
    },
    handleEditUser (user) {
      this.$refs.userEditor.open(user)
    },
    handleUserSaved (user) {
      kit.arr.pushOrReplace(this.users, user)
    },
    loadData () {
      this.loading = true
      listSupplierUser()
        .complete(() => (this.loading = false))
        .success(resp => {
          this.users = resp.data
        })
        .send(this.supplierId)
    }
  },
  mounted () {
    this.loadData()
    getSupplier()
      .success(resp => {
        this.pageTitle = resp.data.name
      })
      .send(this.supplierId)
  }
}
</script>

<style lang="less" scoped>

</style>
