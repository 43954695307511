<template>
  <app-page :title="pageTitle">
    <van-pull-refresh v-if="order" v-model="loading" @refresh="loadData">
      <div style="padding: 20px;">
        <div class="order-no font-bold font-large-x">{{order.orderNo}}</div>
        <div class="text-muted font-normal">{{order.createTime}}</div>
        <div class="status-bar">
          <div class="status status-default" :class="'status-' + order.status">{{statusMapping[order.status]}}</div>
          <div class="status status-pay" :class="'status-' + order.statusPay">{{statusPayMapping[order.statusPay]}}</div>
          <div class="status status-ship" style="background-color: #368e0c; color: #fff;" v-if="order.allShip">全部发完</div>
          <div class="status status-ship" :class="'status-' + order.statusShipping" v-else>{{statusShipMapping[order.statusShipping]}}</div>
          <div class="status status-customize" v-if="hasCustomizeProduct">定制</div>
        </div>
      </div>

      <div class="left-right-content">
        <div style="padding: 10px;">
          <van-button type="info" size="small" block @click="handleNavOrderProduceItems">向供应商下单</van-button>
        </div>
        <div style="padding: 10px;">
          <van-button type="info" size="small" block @click="handleNavOrderDelivery">为客户发货</van-button>
        </div>
      </div>

      <div class="section customer-section">
        <div class="section-title">备注</div>
        <div class="section-body">
          <div>
            <div class="text-normal">客户备注</div>
            <div class="font-normal text-danger" style="padding-left: 20px;" v-if="order.remark" @click="handleCopyRemark">{{order.remark}}</div>
            <div class="font-mini  text-muted" v-else>无</div>
          </div>
          <div style="margin-top: 10px;">
            <div class="text-normal" @click="handleOpenAdminRemarkEditor">
              管理员备注
              <van-icon name="edit" />
            </div>
            <div class="font-normal text-danger" style="padding-left: 20px;" v-if="order.adminRemark">{{order.adminRemark}}</div>
            <div class="font-mini  text-muted" v-else>无</div>
          </div>
        </div>
      </div>

      <div class="section customer-section">
        <div class="section-title">客户</div>
        <div class="section-body">
          <div style="padding: 6px 0;" class="left-right-content">
            <span class="font-large-x full">{{order.email}}</span>
            <span class="copy" @click="handleCopyEmail(order.email)">复制</span>
          </div>

          <div style="display: flex;">
            <span class="font-mini font-bold" style="flex: 1; margin-right: 10px;">收货地址</span>
            <span class="copy" @click="handleCopyAddress">复制</span>
          </div>
          <div style="margin-top: 10px;">
            <div class="info-block">
              <label>姓名</label>
              <span>{{order.receiverLastName}} {{order.receiverFirstName}}</span>
            </div>
            <div class="info-block">
              <label>电话</label>
              <span>{{order.receiverPhone}}</span>
            </div>
            <div class="info-block">
              <label>国家</label>
              <span>{{order.receiverCountry}}</span>
            </div>
            <div class="info-block">
              <label>省 / 州</label>
              <span>{{order.receiverState}}</span>
            </div>
            <div class="info-block">
              <label>城市</label>
              <span>{{order.receiverCity}}</span>
            </div>
            <div class="info-block">
              <label>地址</label>
              <span>{{order.receiverAddress1}} {{order.receiverAddress2}}</span>
            </div>
            <div class="info-block">
              <label>邮编</label>
              <span>{{order.receiverPostalCode}}</span>
            </div>
          </div>
        </div>
      </div>


      <div class="section products-section">
        <div class="section-title">产品</div>
        <div class="section-body">
          <ul class="product-list">
            <li class="product-item" v-for="(p,index) in order.products" :key="p.id">
              <div class="product-image" :style="{'background-image': `url(${p.imageUrl})`}" @click="handlePreviewImage(index)"></div>
              <div class="main-info" style="word-break: break-word;">
                <div class="product-title">
                  <span>{{p.title}}</span>
                  <a :href="p.productUrl + (p.variantId ? '?variant=' + p.variantId : '')" target="_blank" style="color: #3d71a7;">
                    <van-icon name="share" size="16px" style="margin-left: 10px;" />
                  </a>
                </div>
                <div class="variant">规格：{{p.variant}}</div>
                <div class="font-mini text-muted" @click="handleCopyContent(p.sku, 'SKU已经成功复制。')">SKU：{{p.sku}}</div>
                <div class="font-mini" @click="copyCustomizeInfo(p.customizeInfo)" v-if="p.customizeInfo">
                  定制：
                  <span v-for="(item, index) in splitCustomize(p.customizeInfo)" :key="index" class="customize-item">
                    {{item.name}}：{{item.value}} ({{item.unit}})
                  </span>
                </div>
                <div class="single">
                  <span v-if="p.discountPrice > 0">{{order.currency}} {{p.discountPrice}}</span>
                  <span v-else>免费</span>
                  x {{p.total}}（<del>{{p.salePrice}}</del>）
                </div>
                <div style="font-weight: bold; font-size: 13px; color: #e87608" v-if="p.produceItemTotal > 0">
                  供应商生产：{{p.produceItemTotal}} 个订单
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="section price-section">
        <div class="section-title">金额</div>
        <div class="section-body">
          <ul>
            <li>
              <div class="label">小计</div>
              <div class="info">{{order.products | orderItemTotal}} 件产品</div>
              <div class="price">{{order.currency}} {{order.priceProducts}}</div>
            </li>
            <li>
              <div class="label">物流</div>
              <div class="info">{{order.shippingMethod}}</div>
              <div class="price">{{order.currency}} {{order.priceShipping}}</div>
            </li>
            <li>
              <div class="label">优惠</div>
              <div class="info"></div>
              <div class="price">- {{order.currency}} {{order.priceDiscount}}</div>
            </li>
            <li>
              <div class="label">总计</div>
              <div class="info"></div>
              <div class="price">{{order.currency}} {{order.priceOrder}}</div>
            </li>
            <li style="font-weight: bold; font-size: 16px;">
              <div class="label">客户付款</div>
              <div class="info"></div>
              <div class="price">{{order.currency}} {{order.pricePay}}</div>
            </li>
          </ul>
        </div>
      </div>
    </van-pull-refresh>

    <editor :visible.sync="adminRemarkEditor.visible" title="管理员备注" :saving="adminRemarkEditor.saving" @save="handleSaveAdminRemark">
      <van-field
          v-model="adminRemarkEditor.remark"
          rows="4"
          autosize
          label="备注内容"
          type="textarea"
          maxlength="200"
          show-word-limit
      />
    </editor>
  </app-page>
</template>

<script>
import { getOrderDetail, updateAdminRemark } from '../../http/api/order'
import {
  ORDER_STATUS_MAPPING,
  ORDER_PAY_STATUS_MAPPING,
  ORDER_SHIP_STATUS_MAPPING
} from '../../constants/order-status'
import copyToClipboard from 'copy-to-clipboard'
import { ImagePreview } from 'vant'
import { ROUTE_ORDER_PRODUCE_ITEMS, ROUTE_ORDER_DELIVERY } from '../../router/router-constants'
import CustomizeMixin from '../../mixins/customize'

export default {
  data () {
    return {
      loading: false,
      order: null,
      statusMapping: ORDER_STATUS_MAPPING,
      statusPayMapping: ORDER_PAY_STATUS_MAPPING,
      statusShipMapping: ORDER_SHIP_STATUS_MAPPING,
      adminRemarkEditor: {
        visible: false,
        remark: null,
        saving: false
      }
    }
  },
  mixins: [CustomizeMixin],
  computed: {
    orderId () {
      return this.$route.params.id
    },
    pageTitle () {
      return this.order ? this.order.orderNo : '订单详情'
    },
    hasCustomizeProduct () {
      if (this.order) {
        return this.order.products.filter(item => item.customizeInfo).length > 0
      } else {
        return false
      }
    }
  },
  filters: {
    orderItemTotal (items) {
      if (items) {
        let total = 0
        for (const item of items) {
          total += item.total
        }
        return total
      }
      return 0
    }
  },
  methods: {
    handleCopyContent (str, successMessage) {
      copyToClipboard(str)
      this.$message.success(successMessage)
    },
    handleCopyEmail (email) {
      copyToClipboard(email)
      this.$message.success('邮箱已经复制到粘贴板。')
    },
    handleCopyAddress () {
      const str =
          this.order.receiverLastName +
          this.order.receiverFirstName + ',' +
          this.order.receiverAddress1 + ' ' +
          this.order.receiverAddress2 + ', ' +
          this.order.receiverCity + ' ' +
          this.order.receiverState + ' ' +
          this.order.receiverPostalCode + ', ' +
          this.order.receiverCountry + ', ' +
          this.order.receiverPhone
      copyToClipboard(str)
      this.$message.success('收货地址已经复制到粘贴板。')
    },
    handleCopyRemark () {
      copyToClipboard(this.order.remark)
      this.$message.success('备注已经复制到粘贴板。')
    },
    handlePreviewImage (startPosition) {
      ImagePreview({
        images: this.order.products.map(item => item.imageUrl),
        startPosition: startPosition,
        closeable: true,
        loop: false
      })
    },
    handleNavOrderProduceItems () {
      this.$router.push({ name: ROUTE_ORDER_PRODUCE_ITEMS })
    },
    handleNavOrderDelivery () {
      this.$router.push({ name: ROUTE_ORDER_DELIVERY })
    },
    loadData () {
      this.loading = true
      getOrderDetail()
        .complete(() => (this.loading = false))
        .success(resp => {
          this.order = resp.data
        })
        .send(this.orderId)
    },
    handleOpenAdminRemarkEditor () {
      this.adminRemarkEditor.visible = true
      this.adminRemarkEditor.remark = this.order.adminRemark
    },
    handleSaveAdminRemark () {
      this.adminRemarkEditor.saving = true
      updateAdminRemark()
        .complete(() => (this.adminRemarkEditor.saving = false))
        .success(resp => {
          this.order.adminRemark = this.adminRemarkEditor.remark
          this.adminRemarkEditor.visible = false
          this.adminRemarkEditor.remark = null
        })
        .send(this.order.id, this.adminRemarkEditor.remark)
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>

<style lang="less" scoped>
.status-bar {
  margin-top: 15px;
  .status {
    & + .status {
      margin-left: 15px;
    }
    display: inline-block;
    padding: 3px 10px;
    font-size: 13px;
    border-radius: 20px;
    background-color: #d2d2d2;
  }
  .status-produce {
    color: #fff;
    background-color: #e36f1a;
  }
  .status-complete {
    color: #fff;
    background-color: #368e0c;
  }
  .status-close, .status-cancel {
    color: #fff;
    background-color: #bb3e30;
  }
  .status-payPartially, .status-pay {
    color: #fff;
    background-color: #368e0c;
  }
  .status-refund, .status-refundPartially {
    color: #fff;
    background-color: #bb3e30;
  }

  .status-send {
    color: #fff;
    background-color: #0976bb;
  }
  .status-arrived {
    color: #fff;
    background-color: #368e0c;
  }

  .status-customize {
    color: #fff;
    background-color: #a738f6;
  }
}

.section + .section {
  margin-top: 20px;
}
.section {
  background-color: #fff;
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
  box-shadow: 0 0 3px rgba(0,0,0,.1);
  font-size: 13px;
  .section-title {
    font-size: 14px;
    font-weight: bold;
    padding: 10px;
    border-bottom: solid 1px #f2f2f2;
  }
  .section-body {
    padding: 10px;
  }
}

.customer-section {
  .copy {
    font-size: 12px;
    color: #0976bb;
  }
  .info-block {
    label {
      display: inline-block;
      text-align: right;
      width: 60px;
    }
    label::after {
      content: '：';
    }
    span {
      color: #606266;
    }
  }
}


.products-section {

  .product-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .product-item + .product-item {
    margin-top: 16px;
  }
  .product-item {
    display: flex;
    .product-image {
      width: 50px;
      height: 50px;
      border: solid 1px #ddd;
      border-radius: 5px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    .main-info {
      flex: 1;
      margin-left: 15px;
      color: #404040;
    }
    .product-title {
      font-size: 14px;
      color: #3d71a7;
    }
    .variant {
      color: #8b8b8b;
    }
    .customize-item + .customize-item {
      margin-left: 38px;
    }
    .customize-item {
      display: inline-block;
      color: #d4371a;
    }
  }
}

.price-section {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: flex;
      padding: 5px 0;
      .label {
        width: 100px;
      }
      .info {
        flex: 1;
        padding: 0 10px;
      }
      .price {
        text-align: right;
      }
    }
  }
}

</style>
