<template>
  <van-empty image-size="80" description="没有数据" />
</template>

<script>
export default {
  name: 'empty-data'
}
</script>

<style scoped>

</style>
