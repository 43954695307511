<template>
  <div class="page-404">
    <p>对不起，你访问的页面不存在。</p>
    <mt-button type="primary" style="width: 100%;" @click="goHome">返回主页</mt-button>
  </div>
</template>

<script>
import { ROUTE_HOME } from '@/router/router-constants'

export default {
  methods: {
    goHome () {
      this.$router.push({ name: ROUTE_HOME })
    }
  }
}
</script>

<style lang="less" scoped>
.page-404 {
  padding: 30px 10px;
  text-align: center;
}
</style>
