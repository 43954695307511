<template>
  <app-page :title="pageTitle" right-icon="plus" @rightClick="handleAdd">
    <empty-data v-if="dataList.length === 0" />
    <div class="data-list">
      <div class="group-item" v-for="group in dataList" :key="group.date">
        <div class="group-name">{{group.date}}</div>
        <div class="group-data-list">
          <supplier-produce-item
              v-for="item in group.list"
              :key="item.id"
              :item="item"
              @itemClick="handleNavItemDetail(item.id)"
              @imageClick="$productImagePreview.open(item)"
          >
            <div style="text-align: right;">
                <span
                    @click.prevent.stop=""
                >
                  <van-button size="small" type="info" v-show="!item._selected" @click="item._selected = true">选择</van-button>
                  <van-button size="small" type="primary" v-show="item._selected" @click="item._selected = false">已选</van-button>
                </span>
            </div>
          </supplier-produce-item>
        </div>
      </div>
    </div>
    <div slot="footer">
      <van-button type="info" block v-show="selectedItemIds.length > 0" @click="handleShip">为客户发货</van-button>
    </div>
    <order-produce-item-editor ref="editor" @saved="loadData"></order-produce-item-editor>
  </app-page>
</template>

<script>
import { getOrderDetail } from '../../http/api/order'
import OrderProduceItemEditor from './comp/order-produce-item-editor'
import { listItemsByOrderId } from '../../http/api/supplier-produce-item'
import SupplierProduceItem from '@/components/business/supplier-produce-item'
import SupplierProduceItemModel from '@/model/supplier-produce-item'
import { ROUTE_ORDER_PRODUCE_ITEM_DETAIL, ROUTE_ORDER_DELIVERY } from '../../router/router-constants'

export default {
  components: { OrderProduceItemEditor, SupplierProduceItem },
  data () {
    return {
      order: null,
      dataList: []
    }
  },
  computed: {
    orderId () {
      return this.$route.params.id
    },
    pageTitle () {
      return (this.order ? this.order.orderNo + ' - ' : '') + '供应商订单'
    },
    selectedItemIds () {
      const arr = []
      for (const g of this.dataList) {
        for (const data of g.list) {
          if (data._selected) {
            arr.push(data.id)
          }
        }
      }
      return arr
    }
  },
  methods: {
    handleAdd () {
      this.$refs.editor.open(this.order)
    },
    loadData () {
      this.$loading.open()
      listItemsByOrderId()
        .complete(() => (this.$loading.close()))
        .success(resp => {
          this.dataList = []
          const group = {
            date: null,
            list: []
          }
          for (const data of resp.data) {
            const date = data.createTime.split(' ')[0]
            if (group.date !== date && group.list.length > 0) {
              this.dataList.push({
                date: group.date,
                list: group.list
              })
              group.date = null
              group.list = []
            }
            group.date = date
            data._selected = false
            group.list.push(new SupplierProduceItemModel(data))
          }
          if (group.list.length > 0) {
            this.dataList.push(group)
          }
        })
        .send(this.orderId)
    },
    handleNavItemDetail (id) {
      this.$router.push({ name: ROUTE_ORDER_PRODUCE_ITEM_DETAIL, params: { itemId: id } })
    },
    handleShip () {
      this.$router.push({ name: ROUTE_ORDER_DELIVERY, query: { deliveryProduceItems: this.selectedItemIds.join(',') } })
    }
  },
  mounted () {
    this.loadData()
    getOrderDetail()
      .success(resp => {
        this.order = resp.data
      })
      .send(this.orderId)
  }
}
</script>

<style lang="less" scoped>
.group-name {
  padding: 10px 15px;
  font-weight: bold;
  font-size: 16px;
}
.group-data-list {
  margin: 0 15px;
  border-radius: 8px;
  overflow: hidden;
  border: solid 1px #dedede;
  box-shadow: 0 0 3px rgba(0,0,0,.1);
}
</style>
