<template>
  <van-tabs v-model="selectedTab" animated sticky type="card" style="margin-top: 10px;">

    <van-tab name="timeoutDelivery" title="发货">
      <supplier-produce-item-list status="timeoutDelivery" :current-tab="selectedTab"></supplier-produce-item-list>
    </van-tab>
    <van-tab name="timeoutAccept" title="接单">
      <supplier-produce-item-list status="timeoutAccept" :current-tab="selectedTab"></supplier-produce-item-list>
    </van-tab>

  </van-tabs>
</template>

<script>
import SupplierProduceItemList from './list'
export default {
  components: { SupplierProduceItemList },
  data () {
    return {
      selectedTab: 'timeoutDelivery'
    }
  }
}
</script>

<style scoped>

</style>
