<template>
  <div style="border-bottom: solid 1px #f2f2f2;">
    <van-field
        readonly
        clickable
        :value="dateText"
        :label="label"
        @click="showPicker = true"
        :error-message="errorMessage"
    >
      <template #button>
        <van-icon v-if="clearable"
                  v-show="dateText"
                  name="clear"
                  size="16"
                  color="#c9c9c9"
                  @click.stop.prevent="handleConfirm(null)"
        />
      </template>
    </van-field>

    <van-popup v-model="showPicker" position="bottom" get-container="body">
      <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择日期"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="handleConfirm"
          @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'date-picker',
  props: {
    label: { type: String },
    errorMessage: { type: String },
    format: { type: String, default: 'yyyy-MM-DD' },
    dateText: { type: String },
    clearable: { type: Boolean, default: true }
  },
  model: {
    prop: 'dateText',
    event: 'change'
  },
  data () {
    return {
      showPicker: false,
      currentDate: this.dateText ? moment(this.dateText).toDate() : moment().toDate(),
      minDate: moment('2010-01-01').toDate(),
      maxDate: moment().toDate()
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    handleConfirm (val) {
      if (val) {
        this.$emit('change', moment(val).format(this.format))
      } else {
        this.$emit('change', null)
      }
      this.showPicker = false
    }
  }
}
</script>

<style scoped>

</style>
