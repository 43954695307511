<template>
  <editor :visible.sync="visible" title="绑定店铺" size="mid" :saving="saving" @save="handleSave">
    <van-field v-model="form.storeUrl"
               label="店铺后台地址"
               placeholder="https://example.myshopify.com"
               clearable
               :error-message="formError.storeUrl"
    />
    <van-field v-model="form.apiKey"
               label="API密钥"
               clearable
               :error-message="formError.apiKey"
    />
    <van-field v-model="form.apiSecret"
               label="密码"
               type="password"
               clearable
               :error-message="formError.apiSecret"
    />
    <van-field v-model="form.apiToken"
               label="Token"
               type="password"
               clearable
               :error-message="formError.apiToken"
    />
  </editor>
</template>

<script>
import { addShopifyShop } from '../../../http/api/shop'

export default {
  data () {
    return {
      visible: false,
      saving: false,
      form: {
        storeUrl: null,
        apiKey: null,
        apiSecret: null,
        apiToken: null
      },
      formError: {
        storeUrl: null,
        apiKey: null,
        apiSecret: null,
        apiToken: null
      },
      shopType: null
    }
  },
  computed: {
  },
  watch: {
    visible (v) {
      if (!v) {
        this.close()
      }
    }
  },
  methods: {
    open (shopType) {
      this.visible = true
      this.shopType = shopType
    },
    close () {
      this.form.storeUrl = null
      this.form.apiKey = null
      this.form.apiSecret = null
      this.clearErrorMessage()
    },
    clearErrorMessage () {
      for (const key of Object.keys(this.formError)) {
        this.formError[key] = null
      }
    },
    handleSave () {
      this.saving = true
      this.clearErrorMessage()
      addShopifyShop()
        .complete(() => (this.saving = false))
        .validationFail(resp => {
          if (resp.details && resp.details.length > 0) {
            for (const d of resp.details) {
              if (Object.prototype.hasOwnProperty.call(this.formError, d.property)) {
                this.formError[d.property] = d.message
              }
            }
          }
        })
        .success(resp => {
          this.$emit('saved', resp.data)
          this.$message.success('绑定成功，已经开始同步订单。')
          this.visible = false
        })
        .send(this.form.storeUrl, this.form.apiKey, this.form.apiSecret)
    }
  }
}
</script>

<style scoped>

</style>
