import { ROUTE_LOGIN } from '@/router/router-constants'
import router from '../router'
import { userStore } from '@/store'
import { Dialog } from 'vant'

/*
  将未登录或登录过期的弹窗唯一化，避免多个请求发生权限错误时同时弹出多个提示框。
 */
let isShow = false
export default {
  showDialog () {
    if (isShow) return
    isShow = true
    userStore.removeUserInfo()
    Dialog.alert({
      title: '未认证',
      message: '您的登录认证已过期，请重新登录。',
      theme: 'round-button',
    }).then(() => {
      isShow = false
      router.push({ name: ROUTE_LOGIN })
    })
  }
}
