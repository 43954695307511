<template>
  <div class="produce-item-container">
    <fake-search class="top" placeholder="搜索订单" :search-text="searchText" @searchClick="paramsVisible = true"></fake-search>
    <div class="data-list">
      <van-pull-refresh v-model="loading" @refresh="handleReload">
        <empty-data v-if="dataList.length === 0" />
        <van-list
            class="order-list"
            v-model="loading"
            :finished="noMoreData"
            finished-text="没有更多数据了"
            @load="handleLoadData"
            :immediate-check="false"
        >
          <supplier-produce-item
              v-for="item in dataList"
              :key="item.id"
              :item="item"
              :timeout-format="status"
              @itemClick="handleNavItemDetail(item.id)"
              @imageClick="$productImagePreview.open(item)"
          >
            <div class="text-success" style="margin-top: 5px;" v-if="item.readyDelivery">已在待发货列表</div>
            <div
                v-if="itemSelectButtonVisible"
                style="text-align: right;"
            >
                  <span
                      @click.prevent.stop=""
                  >
                    <van-button size="small" type="info" v-show="!item._selected" @click="item._selected = true">选择</van-button>
                    <van-button size="small" type="primary" v-show="item._selected" @click="item._selected = false">已选</van-button>
                  </span>
            </div>
          </supplier-produce-item>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="bottom" v-transfer-dom :data-transfer="true" v-show="selectedItems.length > 0 && currentTab === status">

      <div class="selected-total">已选择（{{selectedItems.length}}）</div>

      <div class="button-bar">
        <div style="flex: 1; margin-right: 10px;">
          <!-- 操作 new -->
          <div v-if="status === statusMapping.new && isSupplier" class="left-right-content">
            <div>
              <van-button block type="info" @click="handleAccept" :loading="buttonLoading">接单</van-button>
            </div>
            <div>
              <van-button block type="danger" @click="handleReject" :loading="buttonLoading">拒单</van-button>
            </div>
          </div>

          <!-- 操作 accept -->
          <div v-if="status === statusMapping.accept && isSupplier" class="left-right-content">
            <div>
              <van-button block type="info" @click="handleMoveToDelivery" :loading="buttonLoading">移到待发货</van-button>
            </div>
            <div>
              <van-button block type="danger" @click="handleReject" :loading="buttonLoading">拒单</van-button>
            </div>
          </div>

          <!-- 操作 reject -->
          <div v-if="(status === statusMapping.reject || status === statusMapping.new) && !isSupplier" class="left-right-content">
            <div>
              <van-button block type="info" @click="transferEditor.visible = true" :loading="buttonLoading">更换供应商</van-button>
            </div>
            <div>
              <van-button block type="danger" @click="handleDelete" :loading="buttonLoading">作废</van-button>
            </div>
          </div>
        </div>

        <van-button @click="handleCancelSelect" :loading="buttonLoading">取消</van-button>
        <van-button @click="handleAllSelect" :loading="buttonLoading" style="margin-left: 5px;">全选</van-button>

      </div>
    </div>

    <editor v-if="(status === statusMapping.new || status === statusMapping.accept) && isSupplier"
            title="拒单原因"
            :visible.sync="rejectEditor.visible"
            :saving="buttonLoading"
            size="mid"
            @save="handleConfirmReject"
    >
      <van-field
          v-model="rejectEditor.message"
          rows="3"
          autosize
          label="拒单原因"
          type="textarea"
          maxlength="200"
          placeholder="请填写拒绝原因"
          show-word-limit
      />
    </editor>

    <editor title="请选择要移交的供应商"
            :visible.sync="transferEditor.visible"
            :saving="buttonLoading"
            size="mid"
            @save="handleTransferToSupplier"
            save-button-text="确定"
    >
      <div>
        <div class="transfer-supplier-item" v-for="s in supplierOptions" :key="s.id" @click="transferEditor.supplierId = s.id">
          <div class="name text-main">{{s.name}}</div>
          <van-icon name="success" size="20" color="#368e0c" v-show="transferEditor.supplierId === s.id"/>
        </div>
      </div>
    </editor>

    <editor title="查询"
            :visible.sync="paramsVisible"
            :saving="loading"
            size="large"
            @save="handleReload"
            save-button-text="查询"
    >
      <van-field v-model="params.number" label="订单编号" clearable />
      <van-field v-model="params.trackNo" label="快递单号" clearable />
      <van-cell center title="延迟生产">
        <template #right-icon>
          <van-switch v-model="params.delay" size="20" />
        </template>
      </van-cell>
      <van-cell center title="废弃订单">
        <template #right-icon>
          <van-switch v-model="params.onlyDelete" size="20" />
        </template>
      </van-cell>
      <date-picker label="下单起始日期" v-model="params.createTimeStart"></date-picker>
      <date-picker label="下单结束日期" v-model="params.createTimeEnd"></date-picker>
      <option-picker label="供应商" :options="supplierOptions" v-model="params.supplierId" v-if="!isSupplier" :clearable="true"></option-picker>
      <option-picker v-if="!orderCustom" label="排序" :options="orderByNameOptions" v-model="params.orderByName"></option-picker>
      <option-picker v-if="!orderCustom" label="排序方向" :options="[{id: 'desc', name: '倒序'}, {id: 'asc', name: '升序'}]" v-model="params.orderByDirection"></option-picker>
    </editor>
  </div>
</template>

<script>
import {
  listPage,
  acceptProduceItem,
  rejectProduceItem,
  transferProduceItem,
  deleteProduceItem,
  getSearchOptionsSuppliers
} from '../../../http/api/supplier-produce-item'
import { addSupplierProduceItemToDelivery } from '../../../http/api/delivery-produce-item'
import SupplierProduceItemModel from '@/model/supplier-produce-item'
import SupplierProduceItem from '@/components/business/supplier-produce-item'
import { ROUTE_SUPPLIER_PRODUCE_ITEM_DETAIL } from '../../../router/router-constants'
import TransferDom from '@/directives/transfer-dom'
import {
  SUPPLIER_PRODUCE_ITEM_STATUS_NEW,
  SUPPLIER_PRODUCE_ITEM_STATUS_ACCEPT,
  SUPPLIER_PRODUCE_ITEM_STATUS_REJECT
} from '../../../constants/supplier-produce-item-status'
import buildSearchParams from '../produce-item-search-params'

export default {
  components: { SupplierProduceItem },
  props: {
    tabId: { type: String },
    status: { type: String },
    orderCustom: { type: String },
    currentTab: { type: String } // 当前显示的列表页面，用来判断自己是不是当前显示
  },
  data () {
    return {
      loading: false,
      buttonLoading: false,
      noMoreData: false,
      dataList: [],
      limit: 15,
      start: 0,
      paramsVisible: false,
      params: buildSearchParams(this.status, this.orderCustom),
      orderByNameOptions: [
        { id: 'create_time', name: '下单时间' },
        { id: 'number', name: '订单编号' }
      ],
      supplierOptions: [],
      bottomVisible: false,
      statusMapping: {
        new: SUPPLIER_PRODUCE_ITEM_STATUS_NEW,
        accept: SUPPLIER_PRODUCE_ITEM_STATUS_ACCEPT,
        reject: SUPPLIER_PRODUCE_ITEM_STATUS_REJECT
      },
      rejectEditor: {
        visible: false,
        message: null
      },
      transferEditor: {
        visible: false,
        supplierId: null
      }
    }
  },
  directives: { TransferDom },
  watch: {
    selectedItemIds (ids) {
      this.$emit('itemSelected', this.tabId, ids)
    }
  },
  computed: {
    isSupplier () {
      return this.$userStore.get('isSupplier')
    },
    selectedItems () {
      const arr = []
      for (const item of this.dataList) {
        if (item._selected) {
          arr.push(item)
        }
      }
      return arr
    },
    selectedItemIds () {
      return this.selectedItems.map(item => item.id)
    },
    searchText () {
      let c = 0
      if (this.params.number) {
        c++
      }
      if (this.params.supplierId) {
        c++
      }
      if (this.params.delay) {
        c++
      }
      if (this.params.createTimeStart) {
        c++
      }
      if (this.params.createTimeEnd) {
        c++
      }
      if (this.params.onlyDelete) {
        c++
      }
      if (this.params.trackNo) {
        c++
      }
      if (c > 0) {
        return `已选择${c}个查询条件`
      } else {
        return null
      }
    },
    itemSelectButtonVisible () {
      if (this.status === SUPPLIER_PRODUCE_ITEM_STATUS_NEW) {
        return true
      }
      if (this.status === SUPPLIER_PRODUCE_ITEM_STATUS_ACCEPT && this.isSupplier) {
        return true
      }
      if (this.status === SUPPLIER_PRODUCE_ITEM_STATUS_REJECT && !this.isSupplier) {
        return true
      }
      return false
    }
  },
  methods: {
    handleReload () {
      this.start = 0
      this.handleLoadData()
      this.paramsVisible = false
    },
    handleLoadData () {
      const params = Object.assign({
        start: this.start,
        limit: this.limit
      }, this.params)

      if (params.createTimeStart) {
        params.createTimeStart += ' 00:00:00'
      }
      if (params.createTimeEnd) {
        params.createTimeEnd += ' 23:59:59'
      }

      this.loading = true
      listPage()
        .complete((success) => {
          this.loading = false
          if (!success) {
            this.noMoreData = true
          }
        })
        .success(resp => {
          this.$emit('totalChange', this.status, resp.data.total)
          // 处理分页信息
          if (this.start === 0) {
            this.dataList = []
          }
          this.start += this.limit
          this.noMoreData = this.start >= resp.data.total

          const arr = []
          for (const data of resp.data.records) {
            data._selected = false
            arr.push(new SupplierProduceItemModel(data))
          }
          this.dataList.push(...arr)
        })
        .send(params)
    },
    handleNavItemDetail (id) {
      this.$router.push({ name: ROUTE_SUPPLIER_PRODUCE_ITEM_DETAIL, params: { itemId: id } })
    },
    handleAccept () {
      const items = this.selectedItemIds
      this.$message.confirm('确认', `确定要承接选中的${items.length}个订单吗？`, () => {
        this.buttonLoading = true
        acceptProduceItem()
          .complete(() => (this.buttonLoading = false))
          .success(() => {
            this.$message.success('承接订单成功，正在刷新页面。')
            this.handleReload()
          })
          .send(items)
      })
    },
    handleReject () {
      this.rejectEditor.message = null
      this.rejectEditor.visible = true
    },
    handleConfirmReject () {
      const items = this.selectedItemIds
      this.buttonLoading = true
      rejectProduceItem()
        .complete(() => (this.buttonLoading = false))
        .success(() => {
          this.rejectEditor.visible = false
          this.$message.success('拒绝订单成功，正在刷新页面。')
          this.handleReload()
        })
        .send(items, this.rejectEditor.message)
    },
    handleMoveToDelivery () {
      this.buttonLoading = true
      addSupplierProduceItemToDelivery()
        .complete(() => (this.buttonLoading = false))
        .success(resp => {
          this.$message.success('已经移到待发货列表。')
          this.handleReload()
        })
        .send(this.selectedItemIds)
    },
    handleTransferToSupplier () {
      if (!this.transferEditor.supplierId) {
        return this.$message.error('请选择供应商。')
      }
      this.buttonLoading = true
      transferProduceItem()
        .complete(() => (this.buttonLoading = false))
        .success(resp => {
          this.transferEditor.visible = false
          this.transferEditor.supplierId = null
          this.$message.success('转交成功。')
          this.handleReload()
        })
        .send(this.selectedItemIds, this.transferEditor.supplierId)
    },
    handleDelete () {
      const items = this.selectedItemIds
      this.$message.confirm('确认', `确定要废弃选中的${items.length}个订单吗？`, () => {
        this.buttonLoading = true
        deleteProduceItem()
          .complete(() => (this.buttonLoading = false))
          .success(() => {
            this.$message.success('废弃订单成功，正在刷新页面。')
            this.handleReload()
          })
          .send(items)
      })
    },
    handleCancelSelect () {
      const map = {}
      const idList = []
      for (const item of this.selectedItems) {
        map[item.id] = item
        idList.push(item.id)
      }
      for (const id of idList) {
        map[id]._selected = false
      }
    },
    handleAllSelect () {
      for (const item of this.dataList) {
        item._selected = true
      }
    }
  },
  mounted () {
    if (!this.isSupplier) {
      getSearchOptionsSuppliers()
        .success(resp => {
          this.supplierOptions = resp.data
        })
        .send()
    }
    this.handleReload()
  }
}
</script>

<style lang="less" scoped>

.produce-item-container {
}

.data-list {
  .group-name {
    padding: 10px 15px;
    font-weight: bold;
    font-size: 16px;
  }
  .group-data-list {
    margin: 0 15px;
    border-radius: 8px;
    overflow: hidden;
    border: solid 1px #dedede;
    box-shadow: 0 0 3px rgba(0,0,0,.1);
  }
}
.bottom {
  padding: 5px 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 99;
  border-top: solid 1px #e2e2e2;

  .selected-total {
    padding: 5px 0;
    text-align: center;
    font-weight: bold;
    font-size: 13px;
  }

  .button-bar {
    display: flex;
    .left-right-content > div {
      padding: 0 5px;
    }
  }
}

.transfer-supplier-item {
  display: flex;;
  padding: 10px;
  border-bottom: solid 1px #eee;
  font-size: 13px;
  &:active {
    background-color: #f2f2f2;
  }
  .name {
    flex: 1;
  }
}

</style>
