import defineApi from '../define-api'
import http from '../application-api'

export const listShop = defineApi((config) => {
  http.get('/shop/list', config)
})

export const getShopById = defineApi((config, id) => {
  config.params = {
    id: id
  }
  http.get('/shop/by_id', config)
})

export const addShop = defineApi((config, shop) => {
  config.data = shop
  return http.post('/shop/add', config)
})

export const addShopifyShop = defineApi((config, storeUrl, apiKey, apiSecret) => {
  config.params = {
    storeUrl: storeUrl,
    apiKey: apiKey,
    apiSecret: apiSecret
  }
  return http.post('/shop/add/shopify', config)
})

export const updateShop = defineApi((config, shop) => {
  config.data = shop
  return http.post('/shop/update', config)
})

