<template>
  <transition name="fade">
    <div class="iframe-page" v-show="visible">
      <div class="header">
        {{title}}
        <van-icon name="cross" class="close" color="#ffffff" size="14px" @click="visible = false" />
      </div>
      <div class="body">
        <iframe :src="url" v-show="url"></iframe>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      url: null,
      title: null
    }
  },
  watch: {
    visible (v) {
      if (!v) {
        this.url = null
        this.title = null
      }
    }
  },
  methods: {
    open (title, url) {
      if (url === undefined) {
        url = title
        title = null
      }
      this.visible = true
      this.title = title
      this.url = url
    }
  }
}
</script>

<style lang="less" scoped>

.iframe-page {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: #fff;

  .header {
    position: relative;
    height: 46px;
    line-height: 46px;
    text-align: center;
    background-color: #4586d4;
    color: #fff;
    .close {
      float: right;
      margin-top: 16px;
      margin-right: 10px;
    }
  }

  .body {
    flex: 1;
    position: relative;
    iframe {
      border: none;
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      overflow: auto;
    }
  }
}

</style>
