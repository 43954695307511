<template>
  <van-image-preview
      v-model="visible"
      :images="images"
      :loop="false"
      :closeable="true"
      @change="handleImagePreviewChange"
      get-container="body"
  >
    <template v-slot:index>
      <div class="info-container">
        <div v-if="produceItem.number">编号： {{produceItem.number}}</div>
        <div v-if="produceItem.variant" class="text-danger">规格：{{produceItem.variant}}</div>
        <div v-if="produceItem.quantity" class="text-danger">数量：{{produceItem.quantity}}</div>
        <div v-if="produceItem.sku">SKU：<span class="font-mini font-lighter">{{produceItem.sku}}</span></div>
        <div v-if="produceItem.fromSupplierName">厂商：<span class="font-mini font-lighter">{{produceItem.fromSupplierName}}</span></div>
        <div v-if="produceItem.remark">备注：{{produceItem.remark}}</div>
        <div v-if="produceItem.skuRemark">备注2：{{produceItem.skuRemark}}</div>
        <div v-if="images.length > 1">页数：{{index + 1}} / {{images.length}}</div>
      </div>
    </template>
  </van-image-preview>
</template>

<script>
export default {
  name: 'product-image-preview',
  data () {
    return {
      visible: false,
      produceItem: null,
      images: [],
      index: 0
    }
  },
  methods: {
    open (produceItem) {
      this.visible = true
      this.produceItem = produceItem
      this.images = produceItem.images
    },
    handleImagePreviewChange (index) {
      this.index = index
    }
  }
}
</script>

<style lang="less" scoped>
.info-container {
  padding: 10px;
  background-color: rgba(0,0,0,.5);
  border-radius: 10px;
}
</style>
