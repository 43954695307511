<template>
  <app-page :title="pageTitle" :right-text="rightText" @rightClick="handleSelectAll">
    <div slot="top">
      <fake-search slot="top" placeholder="搜索" :search-text="searchText" @searchClick="paramsVisible = true"></fake-search>
      <div style="padding: 10px 0; background-color: #fff; text-align: center; border-bottom: solid 1px #eee;">
        <div style="font-weight: bold; color: #2066b4; font-size: 13px;">
          运费：{{deliveryFee.fee}}
        </div>
        <div class="font-mini left-right-content" style="margin-top: 10px; color: #606266;">
          <div @click="handleSearchExceptionData">异常物流：<span class="text-danger font-bold">{{exceptionCount}}</span></div>
          <div @click="handleSearchUnArriveData" style="text-align: center;">超过{{unArriveDays}}天未签收：<span class="text-danger font-bold">{{unArriveCount}}</span></div>
        </div>
      </div>
    </div>
    <van-pull-refresh v-model="loading" @refresh="reloadData" style="padding: 10px 0;">
      <empty-data v-if="dataList.length === 0" />
      <van-list
          v-model="loading"
          :finished="noMoreData"
          finished-text="加载完成"
          @load="loadData"
          :immediate-check="false"
      >
        <div class="group-item" v-for="group in dataList" :key="group.id">
          <div class="group-name left-right-content">
            <div>{{group.date}}</div>
            <div>
              <span class="text-primary font-normal" style="font-weight: normal; margin-right: 10px;" @click="handleSelectAllInGroup(group)">全选</span>
            </div>
          </div>
          <div class="group-data-list">
            <div class="item font-normal text-main"
                 :class="{'exception': item.status === statusMap.exception}"
                 v-for="item in group.list"
                 :key="item.id"
            >
              <div class="left-right-content" style="border-bottom: solid 1px #eee; padding: 5px 0; align-items: center;">
                <div class="full" style="font-weight: bold;">
                  {{item.trackNo}}
                </div>
                <div>
                  <div class="font-bold text-danger">{{item.originOrderNo}}</div>
                  <div>{{item.shopName}}</div>
                </div>
              </div>
              <div style="margin-top: 5px;">
                客户单号：{{item.orderNo}}
              </div>
              <div class="status" :class="'status-' + item.status" style="margin-top: 5px;">
                状态：{{statusMapping[item.status]}}
                <span v-if="item.feeStatus" class="font-mini text-danger">（运费 {{item.fee}}）</span>
              </div>
              <div style="margin-top: 5px;">
                物流：{{getLogisticsName(item.companyId)}}（{{item.shippingMethod}}）
              </div>
              <div style="margin-top: 5px;">
                出库仓库：{{item.deliveryProductItems | outWarehouseName}}
              </div>
              <div class="left-right-content" style="margin-top: 5px;">
                <div>发货：{{item.createTime}}</div>
                <div v-if="item.arrivedTime" class="text-success">签收：{{item.arrivedTime}}</div>
              </div>

              <div class="delivery-items">
                <div class="image-item"
                     v-for="op in item.deliveryProductItems"
                     :key="op.orderProductId"
                     :style="{'background-image': `url(${op.imageUrl})`}"
                     @click="$productImagePreview.open({
                      images: [op.imageUrl],
                      number: item.orderNo,
                      variant: op.variant,
                      sku: op.sku,
                      fromSupplierName: op.fromSupplierName,
                      quantity: op.total,
                      remark: op.remark
                     })"
                >
                  <div class="total">{{op.total}}</div>
                </div>
              </div>

              <div class="text-danger font-bold" v-if="item.remark">
                备注：<span>{{item.remark}}</span>
              </div>

              <div style="margin-top: 5px; padding-top: 5px; overflow: auto;" class="left-right-content">
                <div class="full">
                  <div>
                    <span class="button-text" @click="handleCopy(item.trackNo)">复制运单号</span>
                    <span class="button-text" @click="handleCopy(item.trackUrl)">复制跟踪地址</span>
                    <span class="button-text" @click="handleOpenTrackUrl(item.orderNo, item.trackUrl)">查看跟踪</span>
                  </div>
                  <div v-if="item.status === statusMap.shipped || item.status === statusMap.waitTrack" style="margin-top: 5px;">
                    <span class="button-text" style="color: #bb3e30;" @click="handleCancel(item)">取消发货</span>
                  </div>
                </div>
                <div>
                  <van-button size="small" type="info" v-show="!item._selected" @click="item._selected = true">选择</van-button>
                  <van-button size="small" type="primary" v-show="item._selected" @click="item._selected = false">已选</van-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>

    <div slot="footer">
      <van-button type="info" block :loading="buttonLoading" @click="handleDownloadLabel">下载标签</van-button>
    </div>

    <editor title="查询"
            :visible.sync="paramsVisible"
            :saving="loading"
            size="large"
            @save="reloadData"
            save-button-text="查询"
    >
      <van-field v-model="params.customId" label="自定义标识" clearable />
      <van-field v-model="params.trackNo" label="运单号" clearable />
      <van-field v-model="params.orderNo" label="订单号" clearable />
      <van-field v-model="params.shipDays" label="N天未签收" type="digit" clearable />
      <list-option-picker label="状态" :options="statusOptions" v-model="params.status" :clearable="true" :multi-select="true"></list-option-picker>
      <list-option-picker label="快递公司" :options="logisticsCompanyOptions" v-model="params.companyId" :clearable="true"></list-option-picker>
      <date-picker label="发货起始日期" v-model="params.createTimeStart"></date-picker>
      <date-picker label="发货结束日期" v-model="params.createTimeEnd"></date-picker>
    </editor>

    <editor title="快递标签地址"
            :visible.sync="deliveryTag.visible"
            :hide-bottom="true"
    >
      <van-field
          v-model="deliveryTag.url"
          rows="4"
          autosize
          label="标签地址"
          type="textarea"
      />
      <div style="padding: 10px;">
        <van-button type="info" block @click="handleCopyDeliveryTagUrl">复制</van-button>
      </div>
    </editor>
  </app-page>
</template>

<script>
import { cancelDelivery, list, printLabel, getFeeSummary, getCountWithExceptionAndUnArrive } from '../../http/api/order-delivery'
import {
  ORDER_DELIVERY_STATUS_MAPPING,
  ORDER_DELIVERY_STATUS_LIST,
  ORDER_DELIVERY_STATUS_EXCEPTION,
  ORDER_DELIVERY_STATUS_SHIPPED,
  ORDER_DELIVERY_STATUS_CANCEL,
  ORDER_DELIVERY_STATUS_WAIT_TRACK
} from '../../constants/order-delivery-status'
import copyToClipboard from 'copy-to-clipboard'
import kit from '@/utils/kit'
import { ROUTE_ALL_ORDER_DELIVERY_TRACKING } from '../../router/router-constants'
import { LOGISTICS_COMPANY_LIST } from '../../constants/logistics'

function buildSearchParams () {
  return {
    status: [ORDER_DELIVERY_STATUS_SHIPPED, ORDER_DELIVERY_STATUS_WAIT_TRACK],
    trackNo: null,
    orderNo: null,
    customId: null,
    createTimeStart: null,
    createTimeEnd: null,
    shipDays: null,
    companyId: null
  }
}

export default {
  data () {
    return {
      loading: false,
      dataTotal: 0,
      dataList: [],
      noMoreData: false,
      start: 0,
      limit: 50,
      paramsVisible: false,
      params: buildSearchParams(),
      statusOptions: ORDER_DELIVERY_STATUS_LIST,
      statusMapping: ORDER_DELIVERY_STATUS_MAPPING,
      statusMap: {
        exception: ORDER_DELIVERY_STATUS_EXCEPTION,
        shipped: ORDER_DELIVERY_STATUS_SHIPPED,
        waitTrack: ORDER_DELIVERY_STATUS_WAIT_TRACK
      },
      logisticsCompanyOptions: LOGISTICS_COMPANY_LIST,
      buttonLoading: false,
      deliveryTag: {
        visible: false,
        url: null
      },
      deliveryFee: {
        startDate: null,
        endDate: null,
        fee: 0
      },
      exceptionCount: 0,
      unArriveCount: 0,
      // 发货后超过10天未签收的记录查询条件参数
      unArriveDays: 10
    }
  },
  computed: {
    pageTitle () {
      return `已发货订单（${this.dataTotal}）`
    },
    allLoadedItemTotal () {
      let t = 0
      for (const g of this.dataList) {
        t += g.list.length
      }
      return t
    },
    rightText () {
      return `全选 (${this.selectedItems.length})`
    },
    selectedItems () {
      const arr = []
      for (const g of this.dataList) {
        for (const item of g.list) {
          if (item._selected) {
            arr.push(item)
          }
        }
      }
      return arr
    },
    selectedItemIds () {
      return this.selectedItems.map(item => item.id)
    },
    searchText () {
      let c = 0
      if (this.params.status.length > 0) {
        c++
      }
      if (this.params.trackNo) {
        c++
      }
      if (this.params.orderNo) {
        c++
      }
      if (this.params.createTimeStart) {
        c++
      }
      if (this.params.createTimeEnd) {
        c++
      }
      if (this.params.shipDays) {
        c++
      }
      if (c > 0) {
        return `已选择${c}个查询条件`
      } else {
        return null
      }
    }
  },
  filters: {
    outWarehouseName (items) {
      const found = items.find(a => !!a.warehouseName)
      return found ? found.warehouseName : null
    }
  },
  methods: {
    reloadData () {
      this.start = 0
      this.loadData()
      this.paramsVisible = false
    },
    loadData () {
      this.loading = true
      const params = Object.assign({
        start: this.start,
        limit: this.limit
      }, this.params)

      if (params.createTimeStart) {
        params.createTimeStart += ' 00:00:00'
      }
      if (params.createTimeEnd) {
        params.createTimeEnd += ' 23:59:59'
      }

      list()
        .complete((success) => {
          this.loading = false
          if (!success) {
            this.noMoreData = true
          }
        })
        .success(resp => {
          // 处理分页信息
          if (this.start === 0) {
            this.dataList = []
          }
          this.start += this.limit
          this.noMoreData = this.start >= resp.data.total
          this.dataTotal = resp.data.total


          let lastedGroup = null
          if (this.dataList.length > 0) {
            lastedGroup = this.dataList[this.dataList.length - 1]
          }

          const pushGroup = group => {
            if (group.list.length === 0) return
            if (lastedGroup && lastedGroup.date === group.date) {
              lastedGroup.list.push(...group.list)
              lastedGroup = null
            } else {
              this.dataList.push({
                id: kit.str.id(),
                date: group.date,
                list: [...group.list]
              })
            }
          }

          // 处理数据
          const group = {
            date: null,
            list: []
          }
          for (const data of resp.data.records) {
            const date = data.createTime.split(' ')[0]
            if (group.date !== date && group.list.length > 0) {
              pushGroup(group)
              group.date = null
              group.list = []
            }
            group.date = date
            data._selected = false
            group.list.push(data)
          }
          pushGroup(group)
        })
        .send(params)

      this.loadFee(params)
    },
    handleSearchExceptionData () {
      this.params = buildSearchParams()
      this.params.status = [ORDER_DELIVERY_STATUS_EXCEPTION]
      this.reloadData()
    },
    handleSearchUnArriveData () {
      this.params = buildSearchParams()
      this.params.shipDays = this.unArriveDays
      this.reloadData()
    },
    getLogisticsName (companyId) {
      switch (companyId) {
        case 'yt': return '云途'
        default:
          return companyId
      }
    },
    handleOpenTrackUrl (orderNo, url) {
      this.$router.push({ name: ROUTE_ALL_ORDER_DELIVERY_TRACKING, query: { title: orderNo, url: url } })
    },
    handleCopy (content) {
      copyToClipboard(content)
      this.$message.success('已经复制到粘贴板。')
    },
    getPrintLabel (success) {
      this.buttonLoading = true
      printLabel()
        .complete(() => (this.buttonLoading = false))
        .success(resp => {
          success(resp.data)
        })
        .send(this.selectedItemIds)
    },
    handlePrintLabel () {
      this.getPrintLabel(url => {
        this.$iframePage.open('打印标签', url)
      })
    },
    handleDownloadLabel () {
      this.getPrintLabel(url => {
        this.deliveryTag.url = url
        this.deliveryTag.visible = true
        // this.$message.success('标签下载地址已经复制到粘贴板。')
      })
    },
    handleCopyDeliveryTagUrl () {
      this.handleCopy(this.deliveryTag.url)
      this.deliveryTag.visible = false
    },
    handleSelectAll () {
      let unSelectAll = false
      for (const g of this.dataList) {
        for (const item of g.list) {
          if (item._selected && !unSelectAll) {
            unSelectAll = true
          }
          item._selected = !unSelectAll
        }
      }
    },
    handleSelectAllInGroup (group) {
      let unSelectAll = false
      for (const item of group.list) {
        if (item._selected && !unSelectAll) {
          unSelectAll = true
        }
        item._selected = !unSelectAll
      }
    },
    handleCancel (item) {
      this.$message.confirm('确认', `确定要取消【${item.orderNo || ''}】的发货吗？`, () => {
        this.$loading.open()
        cancelDelivery()
          .complete(() => (this.$loading.close()))
          .success(resp => {
            item.status = ORDER_DELIVERY_STATUS_CANCEL
          })
          .send(item.id)
      })
    },
    loadFee (params) {
      getFeeSummary()
        .success(resp => {
          this.deliveryFee.fee = resp.data
        })
        .send(params)
    },
    loadCountData () {
      getCountWithExceptionAndUnArrive()
        .success(resp => {
          this.exceptionCount = resp.data.exception
          this.unArriveCount = resp.data.unArrive
        })
        .send(this.unArriveDays)
    }
  },
  mounted () {
    this.reloadData()
    this.loadCountData()
  }
}
</script>

<style lang="less" scoped>
.group-name {
  padding: 10px 15px;
  font-weight: bold;
  font-size: 16px;
}
.group-data-list {
  margin: 0 15px;
  border-radius: 8px;
  overflow: hidden;
  border: solid 1px #dedede;
  box-shadow: 0 0 3px rgba(0,0,0,.1);
  background-color: #fff;
}

.item {
  padding: 10px;
  &:not(:first-of-type) {
    border-top: solid 1px #d5d5d5;
  }
  &.exception {
    background-color: #ffdfdb;
  }
}

.status {
  font-size: 13px;
}

.status-shipped {
  color: #3d71a7;
}
.status-arrived {
  color: #368e0c;
}
.status-cancel {
  color: #ef8629;
}
.status-ex, .status-exSolved {
  color: #bb3e30;
}

.button-text {
  color: #4586d4;
  font-size: 13px;
}
.button-text + .button-text {
  margin-left: 10px;
}

.delivery-items {
  margin-top: 10px;
  .image-item {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 5px;
    background-color: #eee;
    overflow: visible;
    .total {
      position: absolute;
      top: -10px;
      right: -10px;
      padding: 2px 5px;
      color: #fff;
      background-color: #3d71a7;
      font-size: 12px;
      border-radius: 5px;
    }
  }
}
</style>
