import defineApi from '../define-api'
import http from '../application-api'

export const addSupplierProduceItem = defineApi((config, items) => {
  config.data = items
  http.post('/supplier_produce_item/add', config)
})

export const acceptProduceItem = defineApi((config, idList) => {
  config.params = {
    idList: idList
  }
  http.post('/supplier_produce_item/accept', config)
})

export const delayProduceItem = defineApi((config, id, isDelay, delayMessage) => {
  config.params = {
    id: id,
    delay: isDelay,
    delayMessage: delayMessage
  }
  http.post('/supplier_produce_item/delay', config)
})

export const rejectProduceItem = defineApi((config, idList, reason) => {
  config.data = {
    idList: idList,
    reason: reason
  }
  http.post('/supplier_produce_item/reject', config)
})

export const transferProduceItem = defineApi((config, idList, supplierId) => {
  config.data = {
    idList: idList,
    supplierId
  }
  http.post('/supplier_produce_item/transfer', config)
})

export const deleteProduceItem = defineApi((config, idList) => {
  config.params = {
    idList: idList
  }
  http.post('/supplier_produce_item/delete', config)
})

export const listItemsByOrderId = defineApi((config, orderId) => {
  config.params = {
    orderId: orderId
  }
  http.get('/supplier_produce_item/list_by_order', config)
})

export const getItemById = defineApi((config, id) => {
  config.params = {
    id: id
  }
  http.get('/supplier_produce_item/by_id', config)
})

export const listPage = defineApi((config, params) => {
  config.params = params
  http.get('/supplier_produce_item/list', config)
})

export const getTotals = defineApi((config) => {
  http.get('/supplier_produce_item/totals', config)
})

export const getSearchOptionsSuppliers = defineApi((config) => {
  http.get('/supplier_produce_item/search_options/suppliers', config)
})

export const shareProduceItems = defineApi((config, itemIdList, sharePath) => {
  config.params = {
    itemIdList: itemIdList,
    sharePath: sharePath
  }
  http.post('/supplier_produce_item/share', config)
})

export const getShareProduceItems = defineApi((config, id) => {
  config.params = {
    id: id
  }
  http.get('/supplier_produce_item/share/list', config)
})

export const getProduceSkuTotal = defineApi((config, skuList) => {
  config.params = {
    sku: skuList.join(',')
  }
  http.get('/supplier_produce_item/sku/produce_total', config)
})

/**
 * @param dataList {Array} {id: String, quantity: Number, warehouseId: String}
 */
export const stockIn = defineApi((config, dataList) => {
  config.data = dataList
  http.post('/supplier_produce_item/stock_in', config)
})
