<template>
  <div>
    <fake-search class="top" placeholder="搜索订单" :search-text="searchText" @searchClick="paramsVisible = true"></fake-search>
    <div class="data-list">
      <van-pull-refresh v-model="loading" @refresh="handleReload">
        <empty-data v-if="dataList.length === 0" />
        <van-list
            class="order-list"
            v-model="loading"
            :finished="noMoreData"
            finished-text="加载完成"
            @load="handleLoadData"
            :immediate-check="false"
        >
          <div class="order-item list-item-inset" v-for="item in dataList" :key="item.id" @click="handleNavDetail(item)">
            <div class="top">
              <div class="order-no">{{item.orderNo}}</div>
              <div class="shop-name">{{item.shopName}}</div>
            </div>
            <div class="center text-normal">
              <div class="left-right-content">
                <div>{{item.receiverLastName}} {{item.receiverFirstName}}</div>
                <div>{{item.receiverCountry}}</div>
              </div>
              <div class="left-right-content">
                <div style="font-weight: bold; font-size: 13px; color: #2568d9">{{item.pricePay}} {{item.currency}}</div>
                <div class="full">
                  <span style="font-weight: bold; font-size: 13px; color: #2568d9">
                    总数：{{item.total}}
                  </span>
                  <span v-if="item.produceItemTotal > 0" style="margin: 0 5px;">/</span>
                  <span style="font-weight: bold; font-size: 13px; color: #e87608" v-if="item.produceItemTotal > 0">已向供应商下单：{{item.produceItemTotal}}</span>
                </div>
              </div>
            </div>
            <div class="images" v-if="item.productItems.length > 0">
              <div class="image-wrapper"
                   :class="{'stock-in': pItem.stockQuantity > 0}"
                   v-for="(pItem,index) in item.productItems"
                   :key="index" @click.prevent.stop="handlePreviewImage(item, index)"
              >
                <div class="customize" v-if="pItem.customizeInfo">C</div>
                <div class="image-total" v-if="pItem.total > 1">{{pItem.total}}</div>
                <div class="image-item" v-lazy:background-image="pItem.imageUrl"></div>
              </div>
            </div>
            <div v-if="item.produceItemTotal === 0" style="margin: 5px 0;">
              <span class="text-primary font-mini" @click.stop.prevent="handleQuickAddProduceItem(item)">快速向供应商下单</span>
            </div>
            <div class="bottom text-normal">
              <div class="left-right-content">
                <div class="font-normal text-main">

                </div>
                <div>
                  <span class="font-bold text-main font-normal" style="margin-right: 10px;">{{item.createTime | readableDate}}</span>
                  <time class="font-mini text-muted">{{item.createTime}}</time>
                </div>
              </div>
              <div class="status-bar">
                <div class="status status-risk" :class="'risk-' + item.risk" v-if="item.risk > 0">欺诈</div>
                <div v-if="item.productItems && item.productItems.filter(t => t.stockQuantity > 0).length > 0" class="status status-stock">库存</div>
                <div v-if="item.statusShipping === statusShipMapping.wait" class="status status-default" :class="'status-' + item.status">{{statusTextMapping[item.status]}}</div>
                <div class="status status-pay" :class="'status-' + item.statusPay">{{statusPayTextMapping[item.statusPay]}}</div>
                <div class="status status-ship" :class="'status-' + item.statusShipping + ' ' + (item.allShip ? 'all-ship' : '')">
                  {{item.allShip ? '已发完' : statusShipTextMapping[item.statusShipping]}}
                  <span v-if="item.statusShipping === statusShipMapping.wait">（已过{{item.dayNewToDelivery}}天）</span>
                  <span v-else>（发货用时{{item.dayNewToDelivery}}天）</span>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>


    <editor title="查询"
            :visible.sync="paramsVisible"
            :saving="loading"
            size="large"
            @save="handleReload"
            save-button-text="查询"
    >
      <van-field v-model="params.orderNo" label="订单号" clearable />
      <van-field v-model="params.email" label="客户邮箱" clearable />
      <van-field v-model="params.produceItemDeliveryTrackNo" label="供应商运单号" clearable />
      <date-picker label="下单起始日期" v-model="params.createTimeStart"></date-picker>
      <date-picker label="下单结束日期" v-model="params.createTimeEnd"></date-picker>
      <option-picker label="店铺" :options="shopOptions" v-model="params.shopId" :clearable="true"></option-picker>
      <option-picker label="排序" :options="orderByNameOptions" v-model="params.orderByName"></option-picker>
      <option-picker label="排序方向" :options="[{id: 'desc', name: '倒序'}, {id: 'asc', name: '升序'}]" v-model="params.orderByDirection"></option-picker>
    </editor>

    <order-produce-item-editor ref="produceItemEditor" @saved="handleQuickAddProduceItemSuccess"></order-produce-item-editor>

  </div>
</template>

<script>
import { listOrders, getSearchOptionsShop, getOrderDetail } from '../../../http/api/order'
import {
  ORDER_SHIP_STATUS_WAIT,
  ORDER_STATUS_MAPPING,
  ORDER_SHIP_STATUS_MAPPING,
  ORDER_PAY_STATUS_MAPPING
} from '../../../constants/order-status'
import { ROUTE_ORDER_DETAIL } from '../../../router/router-constants'
import moment from 'moment'
import buildSearchParams from '../order-status-search-params'
import OrderProduceItemEditor from './order-produce-item-editor'

export default {
  props: {
    // paid, shipped, timeout, all
    type: { type: String, required: true }
  },
  components: { OrderProduceItemEditor },
  data () {
    return {
      loading: false,
      noMoreData: false,
      dataList: [],
      start: 0,
      limit: 15,
      params: buildSearchParams(this.type),
      paramsVisible: false,
      orderByNameOptions: [
        { id: 'create_time', name: '创建时间' },
        { id: 'order_no', name: '订单号' }
      ],
      statusShipMapping: {
        wait: ORDER_SHIP_STATUS_WAIT
      },
      statusTextMapping: ORDER_STATUS_MAPPING,
      statusShipTextMapping: ORDER_SHIP_STATUS_MAPPING,
      statusPayTextMapping: ORDER_PAY_STATUS_MAPPING,
      shopOptions: []
    }
  },
  filters: {
    readableDate (date) {
      const d = Math.abs(
        parseInt(
          moment.duration(
            moment().diff(moment(date))
          ).as('days')
        )
      )
      if (d === 0) {
        return '今天'
      } else if (d === 1) {
        return '昨天'
      } else if (d === 2) {
        return '前天'
      } else {
        return ''
      }
    }
  },
  computed: {
    searchText () {
      let c = 0
      if (this.params.orderNo) {
        c++
      }
      if (this.params.shopId) {
        c++
      }
      if (this.params.email) {
        c++
      }
      if (this.params.createTimeStart) {
        c++
      }
      if (this.params.createTimeEnd) {
        c++
      }
      if (this.params.produceItemDeliveryTrackNo) {
        c++
      }
      if (c > 0) {
        return `已选择${c}个查询条件`
      } else {
        return null
      }
    }
  },
  methods: {
    handleReload () {
      this.start = 0
      this.handleLoadData()
      this.$emit('dataReload')
      this.paramsVisible = false
    },
    handleLoadData () {
      const params = Object.assign({
        start: this.start,
        limit: this.limit
      }, this.params)
      this.loading = true
      listOrders()
        .complete((success) => {
          this.loading = false
          if (!success) {
            this.noMoreData = true
          }
        })
        .success(resp => {
          if (this.start === 0) {
            this.dataList = []
          }
          this.start += this.limit
          this.noMoreData = this.start >= resp.data.total
          this.dataList.push(...resp.data.records)
          this.$emit('totalChange', this.type, resp.data.total)
        })
        .send(params)
    },
    handlePreviewImage (order, startPosition) {
      this.$imagePreview.show(order.productItems.map(item => item.imageUrl), startPosition)
    },
    handleNavDetail (order) {
      this.$router.push({ name: ROUTE_ORDER_DETAIL, params: { id: order.id } })
    },
    handleQuickAddProduceItem (order) {
      this.$loading.open()
      getOrderDetail()
        .complete(() => (this.$loading.close()))
        .success(resp => {
          this.$refs.produceItemEditor.open(resp.data)
        })
        .send(order.id)
    },
    handleQuickAddProduceItemSuccess (order, produceItemTotal) {
      const o = this.dataList.find(item => item.id === order.id)
      if (o) {
        o.produceItemTotal = produceItemTotal
      }
    }
  },
  mounted () {
    getSearchOptionsShop()
      .success(resp => {
        this.shopOptions = resp.data
      })
      .send()
    this.handleReload()
  }
}
</script>

<style lang="less" scoped>
.order-list {
}

@border: solid 1px #eee;
.order-item + .order-item {
  margin-top: 16px;
}
.order-item {
  padding: 10px;
  background-color: #fff;
  .top {
    display: flex;
    align-items: center;
    padding: 2px 0;
    border-bottom: @border;
    font-weight: bold;
    .order-no {
      flex: 1;
      font-size: 18px;
      color: #bb3e30;
    }
    .shop-name {
      padding-left: 5px;
    }
  }

  .center {
    min-height: 10px;
    padding: 10px 0;
    font-size: 14px;
  }

  .images {
    display: flex;
    align-content: flex-start;
    @imgSize: 30px;
    @padding: 3px;
    .image-wrapper {
      position: relative;
      width: @imgSize + @padding * 4;
      height: @imgSize + @padding * 2;
    }
    .customize {
      position: absolute;
      top: -6px;
      left: -5px;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      color: #fff;
      background-color: #7f28e5;
      font-size: 12px;
      font-weight: bold;
      border-radius: 100%;
      z-index: 10;
    }
    .image-total {
      position: absolute;
      top: -6px;
      right: 0;
      z-index: 10;
      min-width: 14px;
      padding: 2px;
      border-radius: 8px;
      color: #fff;
      font-size: 12px;
      text-align: center;
      background-color: #4586d4;
    }
    .image-item {
      width: @imgSize;
      height: @imgSize;
      margin: @padding;
      border: solid 1px #aaaaaa;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .image-wrapper.stock-in .image-item {
      border-color: #b501ff;
      border-width: 2px;
    }
  }

  .bottom {
    margin-top: 8px;
    padding-top: 3px;
    border-top: @border;
  }
}

.status-bar {
  margin-top: 15px;
  .status {
    & + .status {
      margin-left: 15px;
    }
    display: inline-block;
    padding: 3px 10px;
    font-size: 13px;
    border-radius: 20px;
    background-color: #d2d2d2;
  }
  .status-stock {
    color: #fff;
    background-color: #b501ff;
  }
  .status-produce {
    color: #fff;
    background-color: #e36f1a;
  }
  .status-complete {
    color: #fff;
    background-color: #368e0c;
  }
  .status-close, .status-cancel {
    color: #fff;
    background-color: #bb3e30;
  }
  .status-payPartially, .status-pay {
    color: #fff;
    background-color: #368e0c;
  }
  .status-refund, .status-refundPartially {
    color: #fff;
    background-color: #bb3e30;
  }

  .status-send {
    color: #fff;
    background-color: #0976bb;
  }
  .status-arrived {
    color: #fff;
    background-color: #368e0c;
  }
  .all-ship {
    background-color: #368e0c !important;
    color: #fff;
  }
  .status-risk.risk-2 {
    color: #fff;
    background-color: #e7710a;
  }
  .status-risk.risk-5 {
    color: #fff;
    background-color: #bb3e30;
  }
}
</style>
