<template>
  <app-page :title="pageTitle" right-icon="plus" @rightClick="handleAdd">
    <div class="order-products" v-if="products.length > 0">
      <div class="head-title">订单产品项目</div>
      <div v-for="op in products" :key="op.orderProductId" class="product-item" :class="{'selected': selectedProductIds.includes(op.sku)}">
        <div class="auto-layout" @click="handleSelectProduct(op)">
          <div class="image-item small" :style="{'background-image': `url(${op.image})`}">
            <div class="total">{{op.total}}</div>
          </div>
          <div class="auto-layout-full" style="min-width: 0;">
            <div class="text-main font-normal text-overflow title">{{op.title}}</div>
            <div class="text-muted font-mini">{{op.variant}}</div>
            <div class="text-muted font-mini">{{op.sku}}</div>
            <div style="margin-top: 2px;" class="font-mini text-success font-bold" v-if="skuStocks[op.sku]">
              库存：
              <span v-for="stock in skuStocks[op.sku]" :key="stock.id" style="margin-right: 8px;">
              {{stock.warehouseName}} / <strong>{{stock.quantity}}</strong>
            </span>
            </div>
          </div>
        </div>
        <div class="delivery-info">
          <div class="info-row">
            <label>供应商下单</label>
            <span>
                <van-icon v-if="op.produce" name="success" size="18px" color="green" />
                <van-icon v-else name="cross" size="18px" color="red" />
              </span>
          </div>
          <div class="info-row">
            <label>供应商快递</label>
            <span>
              <span v-if="op.shipStatus === 0" class="text-danger font-bold">运输</span>
              <van-icon v-else-if="op.shipStatus === 1" name="success" size="18px" color="green" />
              <van-icon v-else name="cross" size="18px" color="red" />
              </span>
          </div>
          <div class="info-row">
            <label>已为客户发货</label>
            <van-icon v-if="op.deliveryTotal === 0" name="cross" size="18px" color="red" />
            <span v-else style="font-size: 16px; font-weight: bold; color: #3d71a7;">{{op.deliveryTotal}}</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="order" style="margin: 10px 0;">
      <van-button block :type="order.allShip ? 'primary' : 'default'" @click="updateAllShipStatus">
        <span v-if="order.allShip">标记为"未全部发完货"</span>
        <span v-else>标记为"全部发完货"</span>
      </van-button>
    </div>

    <div class="head-title" style="padding: 10px 10px 0 10px;">发货记录</div>
    <van-pull-refresh v-model="loading" @refresh="loadData">
      <empty-data v-if="dataList.length === 0" />
      <div class="data-list">
        <div class="item list-item-inset font-normal text-main" v-for="item in dataList" :key="item.id">
          <div style="border-bottom: solid 1px #eee; padding: 5px 0; font-weight: bold;">
            运单号：{{item.trackNo}}
          </div>
          <div style="margin-top: 5px;">
            客户单号：{{item.orderNo}}
          </div>
          <div class="status" :class="'status-' + item.status" style="margin-top: 5px;">
            状态：{{statusMapping[item.status]}}
            <span v-if="item.feeStatus" class="font-mini text-danger">（运费 {{item.fee}}）</span>
          </div>
          <div style="margin-top: 5px;">
            物流：{{getLogisticsName(item.companyId)}}（{{item.shippingMethod}}）
          </div>
          <div style="margin-top: 5px;">
            出库仓库：{{item.deliveryProductItems | outWarehouseName}}
          </div>
          <div class="left-right-content" style="margin-top: 5px;">
            <div>发货：{{item.createTime}}</div>
            <div v-if="item.arrivedTime" class="text-success">签收：{{item.arrivedTime}}</div>
          </div>
          <div class="delivery-items">
            <div class="image-item"
                 v-for="op in item.deliveryProductItems"
                 :key="op.orderProductId"
                 :style="{'background-image': `url(${op.imageUrl})`}"
                 @click="$productImagePreview.open({
                      images: [op.imageUrl],
                      number: item.orderNo,
                      variant: op.variant,
                      sku: op.sku,
                      fromSupplierName: op.fromSupplierName,
                      quantity: op.total,
                      remark: op.remark
                     })"
            >
              <div class="total">{{op.total}}</div>
            </div>
          </div>
          <div class="text-danger font-bold" v-if="item.remark">
            备注：<span>{{item.remark}}</span>
          </div>
          <div class="left-right-content" style="margin-top: 5px; padding-top: 5px; border-top: solid 1px #eee;">
            <div v-if="item.status === statusMap.shipped || item.status === statusMap.waitTrack">
              <span class="button-text" style="color: #bb3e30;" @click="handleCancel(item)">取消发货</span>
            </div>
            <div class="full" style="text-align: right;">
              <span class="button-text" @click="handleCopy(item.trackNo)">复制运单号</span>
              <span class="button-text" @click="handleCopy(item.trackUrl)">复制跟踪地址</span>
              <span class="button-text" @click="handleOpenTrackUrl(item.trackUrl)">查看跟踪</span>
            </div>
          </div>
        </div>
      </div>
    </van-pull-refresh>
    <default-delivery-editor ref="deliveryEditor" @saved="handleCreated"></default-delivery-editor>
  </app-page>
</template>

<script>
import { getOrderDetail, updateAllShipStatus } from '../../http/api/order'
import { listByOrder, cancelDelivery, getProducts } from '../../http/api/order-delivery'
import {
  ORDER_DELIVERY_STATUS_MAPPING,
  ORDER_DELIVERY_STATUS_SHIPPED,
  ORDER_DELIVERY_STATUS_WAIT_TRACK
} from '../../constants/order-delivery-status'
import DefaultDeliveryEditor from './comp/delivery/default-delivery-editor'
import { ROUTE_ORDER_DELIVERY_TRACKING } from '../../router/router-constants'
import copyToClipboard from 'copy-to-clipboard'
import { listItemsByOrderId } from '../../http/api/supplier-produce-item'
import SupplierProduceItemModel from '@/model/supplier-produce-item'
import { getStockBySku } from '../../http/api/stock-product'
import kit from '@/utils/kit'

export default {
  components: { DefaultDeliveryEditor },
  data () {
    return {
      loading: false,
      order: null,
      products: [],
      selectedProductIds: [],
      dataList: [],
      statusMapping: ORDER_DELIVERY_STATUS_MAPPING,
      statusMap: {
        shipped: ORDER_DELIVERY_STATUS_SHIPPED,
        waitTrack: ORDER_DELIVERY_STATUS_WAIT_TRACK
      },
      skuStocks: {}
    }
  },
  computed: {
    orderId () {
      return this.$route.params.id
    },
    pageTitle () {
      return (this.order ? this.order.orderNo + ' - ' : '') + '客户发货'
    }
  },
  filters: {
    outWarehouseName (items) {
      const found = items.find(a => !!a.warehouseName)
      return found ? found.warehouseName : null
    }
  },
  methods: {
    handleAdd () {
      const total = this.dataList.length
      if (this.order) {
        const order = Object.assign({}, this.order)
        if (this.selectedProductIds.length > 0) {
          order.products = order.products.filter(item => this.selectedProductIds.includes(item.sku))
        }
        this.$refs.deliveryEditor.open(order, total)
      }
    },
    loadData () {
      this.loading = true
      listByOrder()
        .complete(() => (this.loading = false))
        .success(resp => {
          this.dataList = resp.data
        })
        .send(this.orderId)
    },
    getLogisticsName (companyId) {
      switch (companyId) {
        case 'yt': return '云途'
        case 'ishipper': return '夏普'
        default:
          return companyId
      }
    },
    handleOpenTrackUrl (url) {
      this.$router.push({ name: ROUTE_ORDER_DELIVERY_TRACKING, query: { title: this.order.orderNo, url: url } })
    },
    handleCopy (content) {
      copyToClipboard(content)
      this.$message.success('已经复制到粘贴板。')
    },
    handleCreated () {
      this.$message.alert('发货成功，如果列表里看不到刚发货的信息，请等10秒后下拉刷新页面。')
        .then(() => {
          this.loadData()
        })
    },
    handleCancel (item) {
      this.$message.confirm('确认', `确定要取消【${item.orderNo || ''}】的发货吗？`, () => {
        this.$loading.open()
        cancelDelivery()
          .complete(() => (this.$loading.close()))
          .success(resp => {
            this.loadData()
          })
          .send(item.id)
      })
    },
    openDeliveryEditorForProduceItems (order) {
      if (this.$route.query.deliveryProduceItems) {
        const idList = this.$route.query.deliveryProduceItems.split(',')
        listItemsByOrderId()
          .success(resp => {
            const products = []
            for (const data of resp.data) {
              if (idList.includes(data.id)) {
                const item = new SupplierProduceItemModel(data)
                products.push({
                  orderProductId: data.orderProductId,
                  variant: data.variant,
                  sku: data.sku,
                  total: data.quantity,
                  imageUrl: item.getFirstImage()
                })
              }
            }
            const o = Object.assign({}, order)
            o.products = products
            this.$refs.deliveryEditor.open(o, this.dataList.length)
          })
          .send(this.orderId)
      }
    },
    loadSkuStocks (skuList) {
      if (skuList && skuList.length > 0) {
        getStockBySku()
          .success(resp => {
            this.skuStocks = resp.data || {}
          })
          .send(skuList.join(','))
      }
    },
    handleSelectProduct (product) {
      if (this.selectedProductIds.includes(product.sku)) {
        kit.arr.removeItem(this.selectedProductIds, product.sku)
      } else {
        this.selectedProductIds.push(product.sku)
      }
    },
    updateAllShipStatus () {
      this.$loading.open()
      updateAllShipStatus()
        .complete(() => (this.$loading.close()))
        .success(resp => {
          this.order.allShip = !this.order.allShip
          this.$message.success('更新成功。')
        })
        .send(this.order.id, !this.order.allShip)
    }
  },
  mounted () {
    getOrderDetail()
      .success(resp => {
        this.order = resp.data
        this.openDeliveryEditorForProduceItems(this.order)
      })
      .send(this.orderId)
    getProducts()
      .success(resp => {
        this.products = resp.data
        const skuList = []
        for (const p of this.products) {
          skuList.push(p.sku)
        }
        this.loadSkuStocks(skuList)
      })
      .send(this.orderId)
    this.loadData()
  }
}
</script>

<style lang="less" scoped>
.head-title {
  padding: 5px 0;
  font-weight: bold;
}
.order-products {
  padding: 10px;
  background-color: #fff;
  border-bottom: solid 1px #ddd;
  .product-item {
    &.selected .title {
      font-weight: bold;
      color: #368e0c;
    }
    .image-item {
      background-size: cover;
      margin-right: 20px;
      position: relative;
      overflow: visible;
      .total {
        position: absolute;
        top: -6px;
        right: -10px;
        color: #fff;
        background-color: #d52e2e;
        border-radius: 5px;
        padding: 3px 6px;
        font-size: 12px;
        text-align: center;
      }
    }
  }
  .product-item + .product-item {
    margin-top: 10px;
    border-top: solid 1px #eee;
    padding-top: 10px;
  }

  .delivery-info {
    display: flex;
    margin: 5px 0;
    padding-bottom: 5px;
    border-radius: 3px;
    border: solid 1px #eee;
    background-color: #f9f9f9;
  }

  .info-row {
    flex: .3333;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    font-size: 12px;
    label {
      margin-right: 5px;
      color: #606266;
    }
  }
}


.data-list {
  margin-bottom: 10px;
}
.item {
  padding: 10px;
}

.status {
  font-size: 13px;
}

.status-shipped {
  color: #3d71a7;
}
.status-arrived {
  color: #368e0c;
}
.status-cancel {
  color: #ef8629;
}
.status-ex, .status-exSolved {
  color: #bb3e30;
}

.button-text {
  color: #4586d4;
  font-size: 13px;
}
.button-text + .button-text {
  margin-left: 10px;
}

.delivery-items {
  margin-top: 10px;
  .image-item {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 5px;
    background-color: #eee;
    overflow: visible;
    .total {
      position: absolute;
      top: -10px;
      right: -10px;
      padding: 2px 5px;
      color: #fff;
      background-color: #3d71a7;
      font-size: 12px;
      border-radius: 5px;
    }
  }
}
</style>
