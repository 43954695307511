import Vue from 'vue'
import 'vant/lib/index.less'
import {
  Button,
  Cell,
  CellGroup,
  Form,
  Field,
  Tabbar,
  TabbarItem,
  NavBar,
  Tab,
  Tabs,
  Icon,
  Popup,
  Switch,
  PullRefresh,
  SwipeCell,
  Loading,
  Picker,
  Empty,
  Notify,
  ActionSheet,
  List,
  Lazyload,
  Badge,
  ImagePreview,
  DatetimePicker,
  NoticeBar,
  Stepper,
  DropdownMenu,
  DropdownItem
} from 'vant'

Vue.component(Button.name, Button)
Vue.component(Cell.name, Cell)
Vue.component(SwipeCell.name, SwipeCell)
Vue.component(CellGroup.name, CellGroup)
Vue.component(Form.name, Form)
Vue.component(Field.name, Field)
Vue.component(Tabbar.name, Tabbar)
Vue.component(TabbarItem.name, TabbarItem)
Vue.component(NavBar.name, NavBar)
Vue.component(Tab.name, Tab)
Vue.component(Tabs.name, Tabs)
Vue.component(Icon.name, Icon)
Vue.component(Popup.name, Popup)
Vue.component(Switch.name, Switch)
Vue.component(PullRefresh.name, PullRefresh)
Vue.component(Loading.name, Loading)
Vue.component(Picker.name, Picker)
Vue.component(Empty.name, Empty)
Vue.component(Notify.name, Notify)
Vue.component(ActionSheet.name, ActionSheet)
Vue.component(List.name, List)
Vue.component(Badge.name, Badge)
Vue.component(DatetimePicker.name, DatetimePicker)
Vue.component(NoticeBar.name, NoticeBar)
Vue.component(Stepper.name, Stepper)
Vue.component(DropdownMenu.name, DropdownMenu)
Vue.component(DropdownItem.name, DropdownItem)

Vue.use(Lazyload)
Vue.use(ImagePreview)
