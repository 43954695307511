<template>
  <editor :visible.sync="visible" title="用户编辑" size="mid" :saving="saving" @save="handleSave">
    <van-field v-model="form.username" label="手机号" :error-message="formError.username" clearable />
    <van-field v-model="form.name" label="姓名" :error-message="formError.name" clearable />
    <van-field placeholder="供应商" v-if="isSupplierUser" label="用户类型" readonly />
    <option-picker v-else v-model="form.type" label="用户类型" :options="userTypeOptions" :error-message="formError.type"></option-picker>
    <van-field label="是否可用">
      <template #input>
        <van-switch v-model="form.status" size="20" />
      </template>
    </van-field>
  </editor>
</template>

<script>
import kit from '@/utils/kit'
import { USER_TYPE_LIST, USER_TYPE_SUPPLIER } from '../../../constants/user-type'
import { addManager, addSupplierUser, updateManager, getRoleList } from '../../../http/api/admin'

export default {
  props: {
    supplierId: { type: String }
  },
  data () {
    return {
      visible: false,
      saving: false,
      form: {
        id: null,
        username: null,
        name: null,
        type: null,
        status: true,
        supplierId: null
      },
      formError: {
        username: null,
        name: null,
        type: null
      },
      model: null,
      userTypeOptions: []
    }
  },
  computed: {
    isUpdate () {
      return !!this.model
    },
    isSupplierUser () {
      return !!this.supplierId
    }
  },
  watch: {
    visible (v) {
      if (!v) {
        this.close()
      }
    }
  },
  methods: {
    open (model) {
      this.visible = true
      this.model = model
      if (model) {
        kit.obj.getProperties(this.form, model)
      }
      if (this.isSupplierUser) {
        this.form.type = USER_TYPE_SUPPLIER
        this.form.supplierId = this.supplierId
      }
      if (this.userTypeOptions.length === 0) {
        getRoleList()
          .success(resp => (this.userTypeOptions = resp.data))
          .send()
      }
    },
    close () {
      this.model = null
      this.form.id = null
      this.form.name = null
      this.form.username = null
      this.form.enabled = true
      this.form.type = null
      this.clearErrorMessage()
    },
    clearErrorMessage () {
      for (const key of Object.keys(this.formError)) {
        this.formError[key] = null
      }
    },
    handleSave () {
      this.saving = true
      const api = this.isUpdate ? updateManager : (this.isSupplierUser ? addSupplierUser : addManager)
      this.clearErrorMessage()
      api()
        .complete(() => (this.saving = false))
        .validationFail(resp => {
          if (resp.details && resp.details.length > 0) {
            for (const d of resp.details) {
              if (Object.prototype.hasOwnProperty.call(this.formError, d.property)) {
                this.formError[d.property] = d.message
              }
            }
          }
        })
        .success(resp => {
          this.$emit('saved', resp.data)
          this.$message.success('保存成功。')
          this.visible = false
        })
        .send(this.form)
    }
  }
}
</script>

<style scoped>

</style>
