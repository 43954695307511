<template>
  <app-page :title="trackNo">
    <div class="data-list">
      <supplier-produce-item
          v-for="item in dataList"
          :key="item.id"
          :item="item"
          :show-stock="true"
          @imageClick="$productImagePreview.open(item)"
      >
        <div class="text-success" style="margin-top: 5px;" v-if="item.warehouseStockRecordId">已入库</div>
        <div style="text-align: right;">
          <span @click.prevent.stop="">
            <van-button size="small" type="info" v-show="!item._selected" @click="item._selected = true">选择</van-button>
            <van-button size="small" type="primary" v-show="item._selected" @click="item._selected = false">已选</van-button>
          </span>
        </div>
      </supplier-produce-item>
    </div>
    <div slot="footer" style="padding: 10px;" v-show="selectedItems.length > 0">
      <div class="left-right-content">
        <div class="full">
          <van-button type="info" block @click="openStockInEditor">入库</van-button>
        </div>
        <div style="margin-left: 10px;">
          <van-button @click="handleCancelSelect">取消</van-button>
          <van-button @click="handleAllSelect" style="margin-left: 5px;">全选</van-button>
        </div>
      </div>
    </div>

    <produce-item-stock-in-editor ref="stockInEditor" @success="handleLoadData"></produce-item-stock-in-editor>
  </app-page>
</template>

<script>
import { listPage } from '../../../http/api/supplier-produce-item'
import SupplierProduceItemModel from '@/model/supplier-produce-item'
import SupplierProduceItem from '@/components/business/supplier-produce-item'
import ProduceItemStockInEditor from './produce-item-stock-in-editor'

export default {
  components: { SupplierProduceItem, ProduceItemStockInEditor },
  data () {
    return {
      loading: false,
      dataList: []
    }
  },
  watch: {
  },
  computed: {
    isSupplier () {
      return this.$userStore.get('isSupplier')
    },
    trackNo () {
      return this.$route.params.trackNo
    },
    selectedItems () {
      return this.dataList.filter(item => item._selected)
    }
  },
  methods: {
    handleLoadData () {
      const params = {
        start: 0,
        limit: 200,
        trackNo: this.trackNo
      }

      this.loading = true
      listPage()
        .complete((success) => {
          this.loading = false
        })
        .success(resp => {
          const arr = []
          for (const data of resp.data.records) {
            data._selected = false
            arr.push(new SupplierProduceItemModel(data))
          }
          this.dataList = arr
        })
        .send(params)
    },
    openStockInEditor () {
      this.$refs.stockInEditor.open(this.selectedItems)
    },
    handleAllSelect () {
      for (const data of this.dataList) {
        data._selected = true
      }
    },
    handleCancelSelect () {
      for (const data of this.dataList) {
        data._selected = false
      }
    }
  },
  mounted () {
    this.handleLoadData()
  }
}
</script>

<style lang="less" scoped>

.produce-item-container {
}

.data-list {
  .group-name {
    padding: 10px 15px;
    font-weight: bold;
    font-size: 16px;
  }
  .group-data-list {
    margin: 0 15px;
    border-radius: 8px;
    overflow: hidden;
    border: solid 1px #dedede;
    box-shadow: 0 0 3px rgba(0,0,0,.1);
  }
}
.bottom {
  padding: 5px 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 99;
  border-top: solid 1px #e2e2e2;

  .selected-total {
    padding: 5px 0;
    text-align: center;
    font-weight: bold;
    font-size: 13px;
  }

  .button-bar {
    display: flex;
    .left-right-content > div {
      padding: 0 5px;
    }
  }
}

.transfer-supplier-item {
  display: flex;;
  padding: 10px;
  border-bottom: solid 1px #eee;
  font-size: 13px;
  &:active {
    background-color: #f2f2f2;
  }
  .name {
    flex: 1;
  }
}

</style>
