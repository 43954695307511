<template>
  <app-page :title="pageTitle" :use-back-icon="false" right-text="已发货" @rightClick="handleNavAllOrderDelivery">

    <van-tabs v-model="selectedTab" animated :ellipsis="false" :lazy-render="true">

      <van-tab v-for="item in tabs" :key="item.id" :name="item.id">
        <template #title>
          <van-badge :content="totals[item.id]" v-if="totals[item.id] > 0">
            <span>{{item.title}}</span>
          </van-badge>
          <span v-else>{{item.title}}</span>
        </template>
        <order-list :ref="item.id" :type="item.id" @totalChange="handleTotalChange"></order-list>
      </van-tab>

    </van-tabs>
  </app-page>
</template>

<script>
import OrderList from './comp/order-list'
import buildSearchParams from './order-status-search-params'
import { listOrders } from '../../http/api/order'
import { ROUTE_ALL_ORDER_DELIVERY } from '../../router/router-constants'

export default {
  components: { OrderList },
  data () {
    const tabs = [
      { id: 'paid', title: '已付款' },
      { id: 'noProduce', title: '未下单' },
      { id: 'shippedPart', title: '部分发货' },
      { id: 'shipped', title: '已发货' },
      { id: 'timeout', title: '超时' },
      { id: 'all', title: '全部' }
    ]
    const tabTotals = {}
    for (const t of tabs) {
      tabTotals[t.id] = 0
    }
    return {
      selectedTab: tabs[0].id,
      tabs: tabs,
      totals: tabTotals
    }
  },
  computed: {
    pageTitle () {
      return '订单 - ' + this.tabs.find(item => item.id === this.selectedTab).title
    }
  },
  methods: {
    handleTotalChange (type, total) {
      this.totals[type] = total
    },
    handleNavAllOrderDelivery () {
      this.$router.push({ name: ROUTE_ALL_ORDER_DELIVERY })
    }
  },
  mounted () {
    for (const tab of this.tabs) {
      const p = buildSearchParams(tab.id)
      p.start = 0
      p.limit = 0
      listOrders()
        .success(resp => {
          this.totals[tab.id] = resp.data.total
        })
        .send(p)
    }
  }
}
</script>

<style lang="less" scoped>

</style>
