<template>
  <editor :visible.sync="visible" :title="title" size="large" :saving="saving" @save="handleSave">

    <div style="margin: 10px; border-bottom: solid 1px #e2e2e2;">
      <van-field v-model="number" type="text" label="订单编号" />
      <p class="font-mini text-danger" style="padding-left: 16px;">供应商会将这个订单编号和货物一起发货。</p>
      <option-picker v-model="supplierId" label="供应商" :options="suppliers"></option-picker>
    </div>

    <div>
      <div style="padding: 10px;" class="left-right-content">
        <div style="padding-right: 5px;">
          <van-button type="info" block @click="addNewProduceItem">添加新项</van-button>
        </div>
        <div style="padding-left: 5px;">
          <van-button type="info" block @click="$refs.skuSearch.open()">搜索SKU</van-button>
        </div>
      </div>
      <div class="produce-item" v-for="item in produceItems" :key="item.id">
        <div class="image-item" :style="{'background-image': `url(${item.getFirstImage()})`}" @click="$imagePreview.show(item.getFirstImage())">
          <div class="image-total" v-if="item.getImages().length > 1">{{item.getImages().length}}</div>
        </div>
        <div class="main-content">
          <div style="display: flex;">
            <div style="flex: 1; margin-right: 10px;">{{item.variant}}</div>
            <div>x <span class="font-bold font-large-x text-danger">{{item.quantity}}</span></div>
          </div>

          <div class="text-primary font-mini">
            {{item.title}}
          </div>

          <div class="text-muted font-mini">
            SKU：<span>{{item.sku}}</span>
          </div>

          <div style="margin-top: 6px;" class="font-mini text-success font-bold" v-if="skuStocks[item.sku]">
            库存：
            <span v-for="stock in skuStocks[item.sku]" :key="stock.id" style="margin-right: 8px;">
              {{stock.warehouseName}} / <strong>{{stock.quantity}}</strong>
            </span>
          </div>
          <div v-else style="margin-top: 6px;" class="font-mini text-muted">
            库存：无
          </div>

          <div class="text-muted font-mini" style="margin-top: 6px;">
            备注：<span>{{item.remark}}</span>
          </div>

          <div v-if="item.customizeInfo" class="text-muted font-mini" style="margin-top: 6px;">
            定制：
            <span v-for="(c, index) in splitCustomize(item.customizeInfo)" :key="index" class="customize-item">
              {{c.name}}：{{c.value}} ({{c.unit}})
            </span>
          </div>

          <div class="text-muted font-mini" style="margin-top: 6px;">
            供应商：{{suppliersNameMap[item.supplierId]}}
            <div style="margin-top: 6px;" v-if="skuProduceTotal[item.sku]">
              厂家推荐：
              <span v-for="skuTotal in skuProduceTotal[item.sku]" :key="skuTotal.supplierId" style="margin-right: 5px;">
                {{skuTotal.supplierName}} / <strong>{{skuTotal.total}}</strong>
              </span>
            </div>
          </div>

          <div style="text-align: right;">
            <span class="text-primary font-normal" @click="updateProduceItem(item)">修改</span>
            <span class="text-danger font-normal" style="margin-left: 20px;" @click="removeProduceItem(item)">移除</span>
          </div>
        </div>
      </div>
    </div>


    <editor :visible.sync="editingItem.visible" title="修改下单信息" @save="handleUpdateProduceItem">
      <div class="image-container">
        <label>图片</label>
        <div class="image-list">
          <div class="image-item small"
               :style="{'background-image': `url(${img})`}"
               v-for="img in editingItem.form.images"
               :key="img"
          >
            <van-icon name="clear" class="remove-image" color="red" size="20px" @click="handleRemoveUpdatingImageItem(img)" />
          </div>
          <div class="image-item small upload-image" @click="handleUploadUpdatingImage"><van-icon name="back-top" /></div>
          <div v-show="editingItem.item && editingItem.item.orderProductId"
               class="image-item small upload-image"
               @click="handleSelectImageFromShopify">
            <van-icon name="more-o" />
          </div>
        </div>
      </div>
      <van-field v-model="editingItem.form.variant" type="text" label="属性" />
      <div class="van-cell van-field">
        <div class="van-cell__title van-field__label"><span>数量</span></div>
        <div class="van-cell__value van-field__value">
          <div class="van-field__body">
            <van-stepper v-model="editingItem.form.quantity" integer input-width="40px" button-size="32px" />
          </div>
        </div>
      </div>
      <option-picker v-model="editingItem.form.supplierId" label="供应商" :options="suppliers"></option-picker>
      <van-field
          v-model="editingItem.form.remark"
          rows="2"
          autosize
          label="备注"
          type="textarea"
          maxlength="200"
          show-word-limit
      />

      <div style="background-color: #f9f9f9; text-align: center; font-size: 16px; padding: 10px 0;">
        定制信息（可选）
      </div>
      <van-field v-model="editingItem.form.customizeInfo.height" type="text" label="身高" />
      <van-field v-model="editingItem.form.customizeInfo.bust" type="text" label="胸围" />
      <van-field v-model="editingItem.form.customizeInfo.waist" type="text" label="腰围" />
      <van-field v-model="editingItem.form.customizeInfo.hips" type="text" label="臀围" />
      <van-field v-model="editingItem.form.customizeInfo.arm" type="text" label="臂长" />
      <van-field v-model="editingItem.form.customizeInfo.legLength" type="text" label="腿长" />
      <van-field v-model="editingItem.form.customizeInfo.inseamLength" type="text" label="裤腿内缝合" />
      <div class="unit-wrapper">
        <div class="unit-item" :class="{'selected': editingItem.form.customizeInfoUnit === 'cm'}" @click="editingItem.form.customizeInfoUnit = 'cm'">cm</div>
        <div class="unit-item" :class="{'selected': editingItem.form.customizeInfoUnit === 'inch'}" @click="editingItem.form.customizeInfoUnit = 'inch'">inch</div>
      </div>
      <van-field v-model="editingItem.form.customizeInfo.weight" type="text" label="体重" />
      <div class="unit-wrapper">
        <div class="unit-item" :class="{'selected': editingItem.form.customizeInfoWeightUnit === 'kg'}" @click="editingItem.form.customizeInfoWeightUnit = 'kg'">kg</div>
        <div class="unit-item" :class="{'selected': editingItem.form.customizeInfoWeightUnit === 'lbs'}" @click="editingItem.form.customizeInfoWeightUnit = 'lbs'">lbs</div>
      </div>
    </editor>

    <editor :visible.sync="shopifyImage.visible" title="选择细节图片" save-button-text="确定" @save="handleConfirmSelectShopifyImage">
      <div class="shopify-image-container">
        <div
            class="image-wrapper"
            v-for="img in shopifyImage.images"
            :key="img"
        >
          <div class="image-item" :style="{'background-image': `url(${img})`}" @click="$imagePreview.show(img)"></div>
          <van-button size="small"
                      type="primary"
                      v-if="shopifyImage.selected.includes(img)"
                      @click="handleRemoveSelectedShopifyImage(img)"
          >
            已选
          </van-button>
          <van-button size="small" v-else @click="shopifyImage.selected.push(img)">选择</van-button>
        </div>
      </div>
    </editor>

    <sku-product-search ref="skuSearch" @selected="handleSkuProductSearchSelected"></sku-product-search>

  </editor>
</template>

<script>
import { listSupplier } from '../../../http/api/supplier'
import kit from '@/utils/kit'
import { addSupplierProduceItem, getProduceSkuTotal } from '../../../http/api/supplier-produce-item'
import SupplierProduceItem from '../../../model/supplier-produce-item'
import WeChatUtil from '../../../utils/wechat'
import { uploadImageByAddItem } from '../../../http/api/delivery-produce-item'
import { getShopifyProductImages } from '../../../http/api/shopify-product-images'
import { getStockBySku } from '../../../http/api/stock-product'
import SkuProductSearch from '@/components/business/sku-product-search'
import CustomizeMixin from '../../../mixins/customize'

export default {
  components: { SkuProductSearch },
  mixins: [CustomizeMixin],
  data () {
    return {
      visible: false,
      saving: false,
      order: null,
      number: null,
      supplierId: null,
      produceItems: [],
      suppliers: [],
      editingItem: {
        visible: false,
        item: null,
        isUpdate: false,
        form: {
          images: [],
          variant: null,
          quantity: 0,
          supplierId: null,
          remark: null,
          // 三围尺寸单位
          customizeInfoUnit: null,
          // 体重单位
          customizeInfoWeightUnit: null,
          customizeInfo: {
            height: null,
            bust: null,
            waist: null,
            hips: null,
            arm: null,
            legLength: null,
            inseamLength: null,
            weight: null
          }
        }
      },
      shopifyImage: {
        visible: false,
        selected: [],
        images: []
      },
      skuProduceTotal: {
      },
      skuStocks: {}
    }
  },
  computed: {
    suppliersNameMap () {
      const map = {}
      for (const s of this.suppliers) {
        map[s.id] = s.name
      }
      return map
    },
    title () {
      let total = 0
      for (const item of this.produceItems) {
        total += item.quantity
      }
      return '供应商下单' + (total > 0 ? ' x ' + total : '')
    }
  },
  watch: {
    supplierId (newId, oldId) {
      for (const item of this.produceItems) {
        if (item.supplierId === oldId) {
          item.supplierId = this.supplierId
        }
      }
    },
    visible (v) {
      if (!v) {
        this.order = null
        this.number = null
        this.supplierId = null
        this.produceItems = []
      }
    },
    'editingItem.visible' (v) {
      if (v) {
        this.editingItem.form.images = this.editingItem.item.getImages()
        this.editingItem.form.variant = this.editingItem.item.variant
        this.editingItem.form.quantity = this.editingItem.item.quantity
        this.editingItem.form.supplierId = this.editingItem.item.supplierId
        this.editingItem.form.remark = this.editingItem.item.remark
        const customizes = this.splitCustomize(this.editingItem.item.customizeInfo, true)
        for (const c of customizes) {
          if (this.editingItem.form.customizeInfo[c.name] !== undefined) {
            this.editingItem.form.customizeInfo[c.name] = c.value
            if (c.name === 'weight') {
              if (this.editingItem.form.customizeInfoWeightUnit == null) {
                this.editingItem.form.customizeInfoWeightUnit = c.unit
              }
            } else {
              if (this.editingItem.form.customizeInfoUnit == null) {
                this.editingItem.form.customizeInfoUnit = c.unit
              }
            }
          }
        }
      } else {
        this.editingItem.item = null
        this.editingItem.form.images = []
        this.editingItem.form.variant = null
        this.editingItem.form.quantity = 0
        this.editingItem.form.supplierId = null
        this.editingItem.form.remark = null
        this.editingItem.form.customizeInfoUnit = null
        this.editingItem.form.customizeInfoWeightUnit = null
        for (const key of Object.keys(this.editingItem.form.customizeInfo)) {
          this.editingItem.form.customizeInfo[key] = null
        }
      }
    }
  },
  methods: {
    newProduceItem (orderProduct) {
      return new SupplierProduceItem({
        id: kit.str.id(),
        title: orderProduct ? orderProduct.title : null,
        sku: orderProduct ? orderProduct.sku : null,
        orderProductId: orderProduct ? orderProduct.id : null,
        imgs: orderProduct ? orderProduct.imageUrl : null,
        quantity: orderProduct ? orderProduct.total : 1,
        variant: orderProduct ? orderProduct.variant : null,
        remark: null,
        ordersId: this.order.id,
        supplierId: null,
        number: null,
        customizeInfo: orderProduct ? orderProduct.customizeInfo : null
      })
    },
    open (order) {
      this.visible = true
      this.order = order
      this.number = order.orderNo

      const skuProduceTotal = {}
      const skuList = []
      for (const orderProduct of order.products) {
        if (orderProduct.title.toLowerCase().includes('free gift')) {
          continue
        }
        this.produceItems.push(this.newProduceItem(orderProduct))
        if (orderProduct.sku) {
          skuList.push(orderProduct.sku)
          skuProduceTotal[orderProduct.sku] = []
        }
      }
      this.skuProduceTotal = skuProduceTotal
      this.loadSkuProduceTotal()

      this.loadSkuStocks(skuList)


      if (this.suppliers.length === 0) {
        listSupplier()
          .success(resp => {
            this.suppliers = resp.data
          })
          .send()
      }

      if (window._orderProduceItemSupplierId) {
        this.supplierId = window._orderProduceItemSupplierId
      }
    },
    handleSave () {
      if (!this.supplierId || !this.number) {
        return this.$message.error('供应商或订单编号不能为空。')
      }
      this.$message.confirm('确认', '信息无误，确认下单？', () => {
        for (const item of this.produceItems) {
          item.number = this.number
        }
        this.saving = true
        addSupplierProduceItem()
          .complete(() => (this.saving = false))
          .success(() => {
            this.visible = false
            this.$message.success('下单成功。')
            this.$emit('saved', this.order, this.produceItems.length)
            window._orderProduceItemSupplierId = this.supplierId
          })
          .send(this.produceItems)
      })
    },
    removeProduceItem (item) {
      kit.arr.removeItem(this.produceItems, item)
    },
    addNewProduceItem () {
      this.editingItem.isUpdate = false
      this.editingItem.item = this.newProduceItem(null)
      this.editingItem.visible = true
    },
    updateProduceItem (item) {
      this.editingItem.isUpdate = true
      this.editingItem.item = item
      this.editingItem.visible = true
    },
    handleUpdateProduceItem () {
      if (!this.editingItem.isUpdate) {
        this.produceItems.push(this.editingItem.item)
      }
      this.editingItem.item.setImages(this.editingItem.form.images)
      this.editingItem.item.variant = this.editingItem.form.variant
      this.editingItem.item.quantity = parseInt(this.editingItem.form.quantity)
      this.editingItem.item.remark = this.editingItem.form.remark
      this.editingItem.item.customizeInfo = this.stringifyCustomize(
        this.editingItem.form.customizeInfo,
        this.editingItem.form.customizeInfoUnit,
        this.editingItem.form.customizeInfoWeightUnit
      )
      this.editingItem.item.supplierId = this.editingItem.form.supplierId

      this.editingItem.visible = false
    },
    handleRemoveUpdatingImageItem (img) {
      kit.arr.removeItem(this.editingItem.form.images, img)
    },
    handleUploadUpdatingImage () {
      WeChatUtil.chooseImage(img => {
        this.$loading.open()
        uploadImageByAddItem()
          .complete(() => (this.$loading.close()))
          .success(resp => {
            this.editingItem.form.images.push(resp.data)
          })
          .send(img)
      })
    },
    handleSelectImageFromShopify () {
      this.$loading.open()
      getShopifyProductImages()
        .complete(() => (this.$loading.close()))
        .success(resp => {
          this.shopifyImage.images = resp.data
          this.shopifyImage.visible = true
        })
        .send(this.editingItem.item.orderProductId)
    },
    handleRemoveSelectedShopifyImage (img) {
      kit.arr.removeItem(this.shopifyImage.selected, img)
    },
    handleConfirmSelectShopifyImage () {
      for (const img of this.shopifyImage.selected) {
        if (!this.editingItem.form.images.includes(img)) {
          this.editingItem.form.images.push(img)
        }
      }
      this.shopifyImage.visible = false
      this.shopifyImage.images = []
      this.shopifyImage.selected = []
    },
    loadSkuProduceTotal () {
      const skuList = Object.keys(this.skuProduceTotal)
      getProduceSkuTotal()
        .success(resp => {
          for (const sku of skuList) {
            const totals = resp.data[sku]
            if (totals) {
              this.skuProduceTotal[sku] = totals
            }
          }
        })
        .send(skuList)
    },
    loadSkuStocks (skuList) {
      if (skuList && skuList.length > 0) {
        getStockBySku()
          .success(resp => {
            this.skuStocks = resp.data || {}
          })
          .send(skuList.join(','))
      }
    },
    handleSkuProductSearchSelected (item) {
      const produceItem = new SupplierProduceItem({
        id: kit.str.id(),
        sku: item.sku,
        orderProductId: item.pid,
        imgs: item.imageUrl,
        quantity: 1,
        variant: item.variant,
        remark: null,
        ordersId: this.order.id,
        supplierId: this.supplierId,
        number: null,
        customizeInfo: item ? item.customizeInfo : null
      })
      this.produceItems.push(produceItem)
    }
  }
}
</script>

<style lang="less" scoped>
.produce-item {
  display: flex;
  margin: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px #e2e2e2;
  .image-item {
    overflow: visible;
    .image-total {
      position: absolute;
      top: -10px;
      right: -10px;
      color: #fff;
      background-color: #3d71a7;
      border-radius: 10px;
      padding: 5px 10px;
      font-size: 13px;
    }
  }

  .main-content {
    margin-left: 20px;
    flex: 1;
  }

  .customize-item + .customize-item {
    margin-left: 38px;
  }
  .customize-item {
    display: inline-block;
    color: #d4371a;
  }
}

.image-container {
  display: flex;
  margin-top: 10px;
  label {
    width: 80px;
    margin-right: 10px;
    padding-top: 5px;
    padding-left: 15px;
    font-size: 14px;
    color: #606266;
  }
  .image-list {
    display: flex;
  }
  .image-item {
    position: relative;
    margin: 5px;
    overflow: visible !important;
    .remove-image {
      position: absolute;
      top: -8px;
      right: -8px;
    }
  }
  .upload-image {
    text-align: center;
    border: dashed 1px #b1b1b1;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.shopify-image-container {
  display: flex;
  flex-wrap: wrap;
  .image-wrapper {
    margin: 10px;
  }
  .image-item {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
  text-align: center;
}

.unit-wrapper {
  display: flex;
  padding: 10px 20px;
  background-color: #f2f2f2;
  .unit-item {
    flex: .5;
    padding: 10px 0;
    border: solid 1px #eee;
    font-size: 14px;
    text-align: center;
    &.selected {
      border-color: #bb3e30;
      color: #bb3e30;
    }
  }
}
</style>
