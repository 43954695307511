<template>
  <app-page :title="title">
    <van-tabs v-model="selectedTab" animated sticky>
      <van-tab title="店铺信息" name="info">
        <div>
          <van-field label="店铺名称" v-model="shop.name" :error-message="formError.name" clearable />
          <van-field label="类型" v-model="shopType" readonly />
          <van-field label="域名" v-model="shop.domain" readonly />
          <van-field label="店铺后台" v-model="shop.storeUrl" readonly />
          <van-field label="订单Id前缀" v-model="shop.orderPrefix" readonly />
          <van-field label="API密钥" v-model="shop.apiKey" clearable :error-message="formError.apiKey" />
          <van-field label="API密码" type="password" v-model="shop.apiSecret" clearable :error-message="formError.apiSecret" />
          <van-field label="API Token" type="password" v-model="shop.apiToken" clearable :error-message="formError.apiToken" />
        </div>
        <div style="padding: 10px;">
          <p class="text-danger font-mini">
            API密钥和密码是同步订单的凭据，如果你不知道自己在做什么，请不要更新密钥和密码。
          </p>
          <van-button type="info" block @click="handleSave" :loading="saving">保存</van-button>
        </div>
      </van-tab>


      <van-tab title="订单同步计划" name="order">
        <order-sync-schedule :shop-id="shopId"></order-sync-schedule>
      </van-tab>

      <van-tab title="邮箱客户端" name="email">
        <shop-email-client :shop-id="shopId" />
      </van-tab>
    </van-tabs>
  </app-page>
</template>

<script>
import { getShopById, updateShop } from '../../http/api/shop'
import { SHOP_TYPE_MAPPING } from '../../constants/shop-type'
import OrderSyncSchedule from './order-sync-schedule'
import ShopEmailClient from './email-client'

export default {
  components: { OrderSyncSchedule, ShopEmailClient },
  data () {
    return {
      selectedTab: 'info',
      shop: {
        id: null,
        name: null,
        type: null,
        domain: null,
        storeUrl: null,
        orderPrefix: null,
        apiKey: null,
        apiSecret: null,
        apiToken: null
      },
      formError: {
        name: null,
        apiKey: null,
        apiSecret: null,
        apiToken: null
      },
      saving: false
    }
  },
  computed: {
    title () {
      return this.shop ? this.shop.name : '店铺'
    },
    shopType () {
      return this.shop ? SHOP_TYPE_MAPPING[this.shop.type] : null
    },
    shopId () {
      return this.$route.params.id
    }
  },
  methods: {
    handleSave () {
      this.saving = true
      updateShop()
        .complete(() => (this.saving = false))
        .validationFail(resp => {
          if (resp.details && resp.details.length > 0) {
            for (const d of resp.details) {
              if (Object.prototype.hasOwnProperty.call(this.formError, d.property)) {
                this.formError[d.property] = d.message
              }
            }
          }
        })
        .success(resp => {
          this.$message.success('保存成功。')
        })
        .send(this.shop)
    }
  },
  mounted () {
    getShopById()
      .success(resp => {
        Object.assign(this.shop, resp.data)
      })
      .send(this.shopId)
  }
}
</script>

<style lang="less" scoped>

</style>
