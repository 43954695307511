<template>
  <app-page :use-back-icon="false" :title="appName">
    <div style="max-width: 800px; margin: 10px auto;">
      <div v-for="item in items" class="list-item-inset" :key="item.id">
        <div class="info">
          <div class="left-right-content">
            <div>
              单号：{{item.number}}
            </div>
            <div class="font-bold text-danger">
              数量：{{item.quantity}}
            </div>
          </div>
          <div style="font-weight: bold; color: #3d71a7;">
            规格：{{item.variant}}
          </div>
          <div style="font-weight: bold; color: #7f28e5;" v-if="item.customizeInfo">
            定制：
            <div v-for="(c, index) in splitCustomize(item.customizeInfo)" :key="index">
              {{c.name}}: {{c.value}} ({{c.unit}})
            </div>
          </div>
          <div v-if="item.remark" class="text-muted font-mini">
            备注：{{item.remark}}
          </div>
        </div>
        <div class="image-list">
          <div class="image-wrapper">
            <img :src="item.images[0]" />
            <van-button class="image-button" type="info" v-if="item.images.length > 1" @click="handleShowOtherImage(item)">查看细节图片</van-button>
          </div>
        </div>
      </div>
    </div>
  </app-page>
</template>

<script>
import { getShareProduceItems } from '../http/api/supplier-produce-item'
import CustomizeMixin from '../mixins/customize'

export default {
  mixins: [CustomizeMixin],
  data () {
    return {
      items: []
    }
  },
  computed: {
    appName () {
      return this.$appStore.state.name
    },
    queryId () {
      return this.$route.params.id
    }
  },
  methods: {
    handleShowOtherImage (item) {
      this.$imagePreview.show(item.images, 1)
    }
  },
  mounted () {
    this.$loading.open()
    getShareProduceItems()
      .complete(() => (this.$loading.close()))
      .success(resp => {
        this.items = resp.data
      })
      .send(this.queryId)
  }
}
</script>

<style lang="less" scoped>
.list-item-inset {
  padding: 10px;
  .image-list {
    .image-wrapper {
      position: relative;
      padding: 10px;
      text-align: center;
      .image-button {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}
</style>
