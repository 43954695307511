<template>
  <div class="order-sync-schedule">

    <div style="padding: 10px;">
      <van-button type="info" block @click="editor.visible = true">创建订单同步任务</van-button>
    </div>

    <div class="data-list">
      <div class="schedule-item list-item-inset" v-for="item in dataList" :key="item.id" @click="handleScheduleItemClick(item)">
        <div>
          订单日期范围：{{item.startDate}} ～ {{item.endDate}}
        </div>
        <div class="font-normal text-muted">
          同步订单总数：{{item.syncTotal}}
        </div>
        <div class="status text-muted">
          <div>状态：</div>
          <div style="flex: 1; margin-left: 10px;">
            <div class="status-sync" v-if="item.status === orderSyncStatus.sync">
              <van-icon name="orders-o" size="18" />
              <van-icon name="orders-o" class="ghost" size="18" />
            </div>
            <div v-else-if="item.status === orderSyncStatus.success" class="text-success">完成</div>
            <div v-else-if="item.status === orderSyncStatus.error" class="text-danger">发生错误</div>
            <div v-else-if="item.status === orderSyncStatus.stop" class="text-muted">停止</div>
            <div v-else-if="item.status === -1" class="text-muted">
              <van-loading size="18px">正在停止...</van-loading>
            </div>
          </div>
        </div>
      </div>
    </div>

    <editor
        :visible.sync="editor.visible"
        title="订单同步任务"
        :saving="editor.saving"
        @save="handleCreateSyncTask"
        save-button-text="开始同步订单"
    >
      <date-picker label="开始日期" v-model="editor.form.start"></date-picker>
      <date-picker label="结束日期" v-model="editor.form.end"></date-picker>
    </editor>

    <van-action-sheet
        v-model="actionSheet.visible"
        :actions="actionSheet.actions"
        cancel-text="取消"
        close-on-click-action
        @select="handleActionSheetSelected"
        get-container="body"
    />

    <van-popup v-model="errorMessageDialog.visible" position="bottom" :style="{ height: '50%' }" get-container="body">
      <div style="padding: 10px; font-size: 14px; color: #bb3e30;">{{errorMessageDialog.message}}</div>
    </van-popup>
  </div>
</template>

<script>
import moment from 'moment'
import {
  listScheduleByShop,
  createSchedule,
  restartSchedule,
  stopSchedule
} from '../../http/api/order-sync-schedule'
import {
  ORDER_SYNC_STATUS_SYNC,
  ORDER_SYNC_STATUS_SUCCESS,
  ORDER_SYNC_STATUS_ERROR,
  ORDER_SYNC_STATUS_STOP
} from '../../constants/order-sync-status'
import kit from '@/utils/kit'
import Stomp from '@/stomp/stomp'
import { BrokerOrderSync } from '../../stomp/constants'

export default {
  props: {
    shopId: { type: String, required: true }
  },
  data () {
    return {
      dataList: [],
      editor: {
        visible: false,
        saving: false,
        form: {
          start: moment().format('yyyy-MM-DD'),
          end: moment().format('yyyy-MM-DD')
        }
      },
      orderSyncStatus: {
        sync: ORDER_SYNC_STATUS_SYNC,
        success: ORDER_SYNC_STATUS_SUCCESS,
        error: ORDER_SYNC_STATUS_ERROR,
        stop: ORDER_SYNC_STATUS_STOP
      },
      actionSheet: {
        visible: false,
        schedule: null,
        actions: [],
        actionsOnRunning: [{ id: 'stop', name: '停止' }],
        actionsOnStop: [{ id: 'run', name: '继续同步' }],
        actionsOnError: [
          { id: 'run', name: '继续同步' },
          { id: 'error', name: '查看错误信息' }
        ]
      },
      errorMessageDialog: {
        visible: false,
        message: null
      }
    }
  },
  methods: {
    handleCreateSyncTask () {
      createSchedule()
        .success(resp => {
          kit.arr.insert(this.dataList, 0, resp.data)
          this.$message.success('同步任务已经开始。')
          this.editor.visible = false
        })
        .send(this.shopId, this.editor.form.start, this.editor.form.end)
    },
    handleRestartSchedule (item) {
      restartSchedule()
        .success(resp => {
          Object.assign(item, resp.data)
        })
        .send(item.id)
    },
    handleScheduleItemClick (item) {
      switch (item.status) {
        case ORDER_SYNC_STATUS_STOP:
          this.actionSheet.actions = this.actionSheet.actionsOnStop
          break
        case ORDER_SYNC_STATUS_SYNC:
          this.actionSheet.actions = this.actionSheet.actionsOnRunning
          break
        case ORDER_SYNC_STATUS_ERROR:
          this.actionSheet.actions = this.actionSheet.actionsOnError
          break
        default:
          this.actionSheet.actions = []
      }
      this.actionSheet.visible = this.actionSheet.actions.length > 0
      if (this.actionSheet.visible) {
        this.actionSheet.schedule = item
      }
    },
    handleActionSheetSelected (action) {
      const schedule = this.actionSheet.schedule
      switch (action.id) {
        case 'error':
          this.errorMessageDialog.visible = true
          this.errorMessageDialog.message = this.actionSheet.schedule.message
          break
        case 'run':
          this.handleRestartSchedule(schedule)
          break
        case 'stop':
          stopSchedule()
            .success(resp => {
              if (resp.data) {
                schedule.status = -1
              } else {
                this.$message.error('无法停止，同步任务没有在执行。')
              }
            })
            .send(schedule.id)
          break
      }
    }
  },
  mounted () {
    listScheduleByShop()
      .success(resp => {
        this.dataList = resp.data
      })
      .send(this.shopId)

    this.stomp = new Stomp()
    this.stomp.connect(success => {
      if (success) {
        this.stomp.subscribe(BrokerOrderSync, resp => {
          const item = this.dataList.find(item => item.id === resp.scheduleId)
          if (item) {
            item.status = resp.status
            item.syncTotal = resp.syncTotal
            item.message = resp.message
          }
        })
      } else {
        this.$message.error('Stomp没有连接成功，请刷新页面重新尝试。')
      }
    })
  },
  beforeDestroy () {
    if (this.stomp) {
      this.stomp.close()
    }
  }
}
</script>

<style lang="less" scoped>

.schedule-item {
  padding: 10px;
}

.status {
  display: flex;
  margin-top: 10px;
  font-size: 14px;
}
.status-sync {
  position: relative;
  .ghost {
    animation-name: order-sync;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    position: absolute;
    left: 0;
  }
}

@keyframes order-sync {
  from {left: 0; opacity: 100%;}
  to {left: calc(100% - 20px); opacity: 30%; }
}

</style>
