import defineApi from '../define-api'
import http from '../application-api'

export const listOrders = defineApi((config, params) => {
  config.params = params
  http.get('/order/list', config)
})

export const getOrderDetail = defineApi((config, id) => {
  config.params = { id: id }
  http.get('/order/detail', config)
})

export const getSearchOptionsShop = defineApi((config) => {
  http.get('/order/search_options/shop', config)
})

export const updateAdminRemark = defineApi((config, id, remark) => {
  config.params = {
    id: id,
    remark: remark
  }
  http.post('/order/update/admin_remark', config)
})

export const updateAllShipStatus = defineApi((config, id, allShip) => {
  config.params = {
    id: id,
    allShip: allShip
  }
  http.post('/order/update/all_ship', config)
})

