export default {
  obj: {
    isEmpty (obj) {
      return obj === undefined || obj == null || Object.keys(obj).length === 0
    },
    /**
     * 使用source的属性名去target找，并将target的值放到source中。
     * @param source
     * @param target
     * @param [ignoreProps] {Array} 忽略的属性名
     * @param ignoreIfNull {Array} 如果target对应key的值为空，则忽略，避免赋空值，key的值支持正则表达式写法。
     * @return source
     */
    getProperties (source, target, ignoreProps, ignoreIfNull) {
      ignoreIfNull = ignoreIfNull || []
      for (const key of Object.keys(source)) {
        if (ignoreProps !== undefined && ignoreProps.includes(key)) {
          continue
        }
        if (target[key] === undefined) {
          continue
        }
        if (target[key] == null) {
          let ignore = false
          for (const ig of ignoreIfNull) {
            const reg = new RegExp(ig, 'g')
            if (reg.test(key)) {
              ignore = true
              break
            }
          }
          if (ignore) {
            continue
          }
        }
        source[key] = target[key]
      }
      return source
    },
    /**
     * 将对象合并（不做深层复制）。
     * let obj = merge({}, obj1, obj2, ...)
     */
    merge () {
      let obj = arguments[0] || {}
      for (let i = 1; i < arguments.length; i++) {
        obj = Object.assign(obj, arguments[i])
      }
      return obj
    },
    isPlainObject (obj) {
      if (obj === null || typeof obj !== 'object') {
        return false
      }
      return Object.prototype.isPrototypeOf.call(obj) === Object.prototype
    },
    deepClone (target, copy) {
      if (target == null) {
        return target
      }
      if (target.clone) {
        return target.clone()
      }
      if (typeof target !== 'object') {
        return target
      }
      copy = copy || {}
      for (const key of Object.keys(target)) {
        const value = target[key]
        if (value == null) {
          copy[key] = null
        } else if (value.clone) {
          copy[key] = value.clone()
        } else if (Array.isArray(value)) {
          copy[key] = []
          for (const item of value) {
            copy[key].push(this.deepClone(item))
          }
        } else if (typeof value === 'object') {
          copy[key] = {}
          this.deepClone(value, copy[key])
        } else {
          copy[key] = value
        }
      }
      return copy
    },
    /**
     * 将一个简单对象转成一个对象数组，如：
     * {a: 1, b: 2} > [{id: a, name: 1}, {id: b, name: 2}]
     * @param map
     * @param [idName] 默认"id"
     * @param [valueName] 默认"name"
     * @param [intId] {Boolean}
     * @return {[]}
     */
    toArray (map, idName, valueName, intId) {
      idName = idName || 'id'
      valueName = valueName || 'name'
      const tmp = []
      for (const key of Object.keys(map)) {
        const obj = {}
        if (intId === true) {
          obj[idName] = parseInt(key)
        } else {
          obj[idName] = key
        }
        obj[valueName] = map[key]
        tmp.push(obj)
      }
      return tmp
    }
  },

  date: {
    format (date) {
      var d = date.getDate()
      var m = date.getMonth() + 1
      var y = date.getFullYear()
      return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d)
    },
    nextMonth (now) {
      if (now === undefined || now === null) {
        now = new Date()
      }
      let curr
      if (now.getMonth() === 11) {
        curr = new Date(now.getFullYear() + 1, 0, 1)
      } else {
        curr = new Date(now.getFullYear(), now.getMonth() + 1, 1)
      }
      return curr
    },
    prevMonth (now) {
      if (now === undefined || now === null) {
        now = new Date()
      }
      let curr
      if (now.getMonth() === 0) {
        curr = new Date(now.getFullYear() - 1, 11, 1)
      } else {
        curr = new Date(now.getFullYear(), now.getMonth() - 1, 1)
      }
      return curr
    },
    toLastDayInMonth (date) {
      return new Date(date.getFullYear(), date.getMonth() + 1, 0)
    }
  },

  func: {
    isFunction (functionToCheck) {
      return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
    },
    /**
     * 安全的执行函数，如果函数不是有效的，则无任何效果。
     * @param func {Function}
     * @param [context] {Object | Array] 如果是一个数组，表示params参数，如果是对象，表示函数的上下文。
     * @param [params] {Array} func的参数。
     * @param [catchError] {boolean}
     */
    call (func, context, params, catchError) {
      if (!this.isFunction(func)) return
      if (Array.isArray(context)) {
        params = context
        context = null
      }
      if (catchError === true) {
        try {
          return func.apply(context, params)
        } catch (e) {
          console && console.error(e)
        }
      } else {
        return func.apply(context, params)
      }
    }
  },

  arr: {
    pushAll (arr, arrItem) {
      if (!Array.isArray(arrItem)) {
        arrItem = [arrItem]
      }
      for (const item of arrItem) {
        arr.push(item)
      }
    },
    /**
     * 使用obj从数组中查找，如果存在则替换，否则将obj添加到数组。
     * @param arr {Array}
     * @param obj {Object}
     * @param [idName] {String} 默认'id'，用来从arr中查找和obj的id相同的对象。
     * @return Array 返回arr，如果arr不是一个有效的数组则创建一个新数组，处理完成后返回该数组。
     */
    pushOrReplace (arr, obj, idName) {
      arr = arr || []
      idName = idName || 'id'
      const old = arr.find(o => o[idName] === obj[idName])
      if (old) {
        Object.assign(old, obj)
      } else {
        arr.push(obj)
      }
      return arr
    },
    /**
     * 使用obj从数组中查找，如果存在则替换，否则将obj插入到数组指定位置。
     * @see pushOrReplace
     * @param arr {Array}
     * @param index {int}
     * @param obj {Object}
     * @param [idName] {String} 默认'id'，用来从arr中查找和obj的id相同的对象。
     * @return {*|Array}
     */
    insertOrReplace (arr, index, obj, idName) {
      arr = arr || []
      idName = idName || 'id'
      const old = arr.find(o => o[idName] === obj[idName])
      if (old) {
        Object.assign(old, obj)
      } else {
        this.insert(arr, index, obj)
      }
      return arr
    },
    /**
     * 根据对象的id从数组中查找位置并删除。
     * @param arr {Array}
     * @param obj {Object} 需要从数组中删除的对象。
     * @param [idName] {string} 默认为'id'。
     * @return arr
     */
    remove (arr, obj, idName) {
      if (arr == null || arr === undefined || arr.length === 0) return []
      idName = idName || 'id'
      const index = arr.findIndex(o => o[idName] === obj[idName])
      index >= 0 && arr.splice(index, 1)
      return arr
    },
    /**
     * 从数组中移除项目。
     * @param arr
     * @param item
     * @return arr
     */
    removeItem (arr, item) {
      if (arr == null || arr === undefined || arr.length === 0) return []
      const index = arr.findIndex(o => o === item)
      index >= 0 && arr.splice(index, 1)
      return arr
    },
    /**
     * 插入元素到数组的指定位置。
     * @param arr
     * @param index
     * @param item {Object | Array}
     * @return {Array}
     */
    insert (arr, index, item) {
      if (!Array.isArray(arr)) {
        arr = []
      }
      if (index >= arr.length) {
        index = arr.length - 1
      }
      if (Array.isArray(item)) {
        arr.splice(index, 0, ...item)
      } else {
        arr.splice(index, 0, item)
      }
      return arr
    },
    /**
     * 求两个数组的交集。
     * @param arr1
     * @param arr2
     */
    intersect (arr1, arr2) {
      if (arr1 && arr1.length > 0 && arr2 && arr2.length > 0) {
        return arr1.filter(v => arr2.indexOf(v) !== -1)
      } else {
        return []
      }
    },
    find (arr, condition) {
      if (arr == null || arr === undefined || arr.length === 0) return null
      const idx = arr.findIndex(condition)
      if (idx > -1) {
        return arr[idx]
      } else {
        return null
      }
    }
  },

  str: {
    isBlank: (text) => text == null || text === undefined || text.trim().length === 0,
    /**
     * @param str
     * @param search 被替换的字符串，属于正则表达式
     * @param replaceStr 替换后的字符串
     */
    replaceAll: function (str, search, replaceStr) {
      return str.replace(new RegExp(search, 'g'), replaceStr)
    },
    repeat: function (char, times) {
      let c = ''
      for (let i = 0; i < times; i++) {
        c += char
      }
      return c
    },
    /**
     * 将字节数转换成人类易读的字符串。
     * @param size {int} 字节。
     * @param [decimal] {int} 小数位数，默认1，0表示没有小数。
     * @returns {string}
     */
    fmtSize: function (size, decimal) {
      if (decimal === undefined) {
        decimal = 1
      }
      if (size < 1024) {
        return size.toFixed(decimal) + ' B'
      }
      size = size / 1024
      if (size < 1024) {
        return size.toFixed(decimal) + ' KB'
      }
      size = size / 1024
      if (size < 1024) {
        return size.toFixed(decimal) + ' MB'
      }
      size = size / 1024
      if (size < 1024) {
        return size.toFixed(decimal) + ' GB'
      }
      size = (size / 1024)
      if (size < 1024) {
        return size.toFixed(decimal) + ' TB'
      }
      return size + ''
    },
    /**
     * 将一个毫秒时间格式化为"xx时间前"的格式，如：4秒前，5分钟前，3小时前。
     * @param ms
     * @return {String | int} 如果返回0，表示此时间已经过了很久，不适合格式化这种格式了，你应该显示原本的日期时间。
     */
    fmtBeforeTime (ms) {
      const sec = parseInt((Date.now() - ms) / 1000)
      if (sec < 60) {
        return sec + '秒前'
      }
      if (sec <= 1500) { // 25分钟
        return parseInt(sec / 60) + '分钟前'
      }
      if (sec <= 1800) { // 半小时
        return '半小时前'
      }
      if (sec < 3600) { // 一小时前
        return parseInt(sec / 60) + '分钟前'
      }
      if (sec < 3600 * 24) {
        return parseInt(sec / 3600) + '小时前'
      }
      return 0
    },
    /**
     * 格式化数字为货币形式。
     * @param number 数值
     * @param [places] 保留多少位小数，默认2位
     * @param [symbol] 货币符号，默认人民币
     * @param [thousand] 整数区域的分隔符，默认","
     * @param [decimal] 小数点符号，默认"."
     * @returns {string}
     */
    fmtCurrency: function formatMoney (number, places, symbol, thousand, decimal) {
      number = number || 0
      places = !isNaN(places = Math.abs(places)) ? places : 2
      symbol = symbol || ''
      thousand = thousand || ', '
      decimal = decimal || '.'
      const negative = number < 0 ? '-' : ''
      const i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + ''
      const j = i.length > 3 ? i.length % 3 : 0
      const r = (symbol ? symbol + ' ' : '') + negative + (j ? i.substr(0, j) + thousand : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : '')
      const tmp = '.' + this.repeat('0', places)
      return r.replace(tmp, '')
    },
    split (str, sign) {
      if (str && str.length > 0) {
        return str.split(sign || ',')
      } else {
        return []
      }
    },
    ellipse (str, maxLength, suffix) {
      if (this.isBlank(str)) return ''
      if (str.length > maxLength) {
        return str.substring(0, maxLength) + (suffix || '...')
      } else {
        return str
      }
    },
    /**
     * 产生一个随机id
     * @param [len] id的长度，默认16位
     * @return {string}
     */
    id (len) {
      len = len || 16
      const chars = '123456789abcdefghijklmnopqrstuvwxyz'.split('')
      const uuid = []
      const radix = 16
      if (len) {
        for (let i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix]
      } else {
        let r
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
        uuid[14] = '4'
        for (let i = 0; i < 36; i++) {
          if (!uuid[i]) {
            r = 0 | Math.random() * 16
            uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r]
          }
        }
      }

      return uuid.join('')
    },
    /**
     * 获取文件名的后缀名（不包含"."符号）。
     * @param name
     * @return {String}
     */
    fileExt (name) {
      if (this.isBlank(name)) return ''
      const idx = name.lastIndexOf('.')
      if (idx === name.length - 1) return ''
      return name.substr(idx + 1, name.length)
    }
  },
  num: {
    /**
     * 将数字toFixed小数，并去除末尾的0，如：
     * 123.00 > 123
     * 123.01 > 123.01
     * 123.10 > 123.1
     * @param n 数字
     * @param point 小数位数
     * @return {number}
     */
    toFixedTrimZero (n, point) {
      let tmp = n.toFixed(point)
      tmp = tmp.replace(/0+$/, '')
      return parseFloat(tmp)
    },
    stepWithLimit (max, currentNum, stepNum) {
      currentNum += stepNum
      if (stepNum < 0) {
        if (currentNum <= max) {
          return 0
        }
      } else {
        if (currentNum >= max) {
          return 0
        }
      }
      return currentNum
    },
    parseFloat (n) {
      const r = parseFloat(n)
      return isNaN(r) ? 0 : r
    },
    parseInt (n) {
      const r = parseInt(n)
      return isNaN(r) ? 0 : r
    }
  },
  tree: {
    /**
     * 遍历树结构数据，数据结构至少有如下属性：
     * {
     *   id: string,
     *   children: []
     * }
     * @param treeData {Array}
     * @param callback {Function} 返回false会终止树的继续遍历，回调函数参数如下：
     *   node
     *   nodeIndexInArray
     *   array node所在的同级列表
     *   parentNode
     *   level 节点的深度，最顶层的为0
     * @param [parent]
     * @param [level]
     */
    walk (treeData, callback, parent, level) {
      if (level === undefined) {
        level = 0
      }
      for (let i = 0; i < treeData.length; i++) {
        const folder = treeData[i]
        const isContinue = callback(folder, i, treeData, parent, level)
        if (isContinue === false) {
          return
        }
        if (folder.children && folder.children.length > 0) {
          this.walk(folder.children, callback, folder, level + 1)
        }
      }
    },
    findNode (treeData, id) {
      let nodeData = null
      this.walk(treeData, (item, index, arr, parent, level) => {
        if (item.id === id) {
          nodeData = item
          return false
        }
      })
      return nodeData
    }
  },
  dom: {
    addEvent (el, event, handler) {
      if (!el) {
        return
      }
      if (el.attachEvent) {
        el.attachEvent('on' + event, handler)
      } else if (el.addEventListener) {
        el.addEventListener(event, handler, false)
      } else {
        el['on' + event] = handler
      }
    },
    removeEvent (el, event, handler) {
      if (!el) {
        return
      }
      if (el.detachEvent) {
        el.detachEvent('on' + event, handler)
      } else if (el.removeEventListener) {
        el.removeEventListener(event, handler, false)
      } else {
        el['on' + event] = null
      }
    },
    /**
     * 将一个容器里的坐标转换成另一个容器里的坐标。
     * @param sourceDom 包含了一个坐标的容器
     * @param sourceDomPos {{ left: number, top: number }}
     * @param targetDom
     * @return 返回一个在targetDom里的坐标：{{top: number, left: number}}
     */
    positionRelative (sourceDom, sourceDomPos, targetDom) {
      const sourceRect = sourceDom.getBoundingClientRect()
      const targetRect = targetDom.getBoundingClientRect()
      const top = sourceDomPos.top + sourceRect.top - targetRect.top
      const left = sourceDomPos.left + sourceRect.left - targetRect.left
      return { top: top, left: left }
    },
    /**
     * 将document上的一个坐标转成成某一个容器里的坐标。
     * @param posInDocument {{top: number, left: number}}
     * @param relativeDom
     * @return {{top: number, left: number}}
     */
    positionRelativeDocument (posInDocument, relativeDom) {
      const rect = relativeDom.getBoundingClientRect()
      const top = posInDocument.top - rect.top
      const left = posInDocument.left - rect.left
      return { top: top, left: left }
    }
  }
}
