<template>
  <div class="container">
    <van-field label="邮箱" v-model="form.email" :error-message="formError.email" clearable />
    <van-field label="密码" type="password" v-model="form.password" :error-message="formError.password" clearable />
    <van-field label="名称" v-model="form.name" :error-message="formError.name" clearable />
    <van-field label="服务器" v-model="form.host" :error-message="formError.host" clearable />
    <van-field label="端口" v-model="form.port" :error-message="formError.port" clearable />

    <div style="padding: 10px;">
      <p class="text-danger font-mini">
        配置邮件客户端，发货的同时也给客户发送一封包含物流跟踪信息地址的邮件。
      </p>
      <van-button type="info" block @click="handleSave" :loading="saving">保存</van-button>
    </div>
  </div>
</template>

<script>
import { updateEmailSetting, getEmailSetting } from '../../http/api/shop-email-client'

export default {
  props: {
    shopId: { type: String, required: true }
  },
  data () {
    return {
      saving: false,
      form: {
        email: null,
        host: null,
        port: null,
        password: null,
        name: null
      },
      formError: {
        email: null,
        host: null,
        password: null,
        name: null,
        port: null
      }
    }
  },
  methods: {
    handleSave () {
      this.saving = true
      const data = Object.assign({}, this.form)
      data.id = this.shopId
      updateEmailSetting()
        .complete(() => (this.saving = false))
        .validationFail(resp => {
          if (resp.details && resp.details.length > 0) {
            for (const d of resp.details) {
              if (Object.prototype.hasOwnProperty.call(this.formError, d.property)) {
                this.formError[d.property] = d.message
              }
            }
          }
        })
        .success(resp => {
          this.$message.success('保存成功。')
        })
        .send(data)
    }
  },
  mounted () {
    getEmailSetting()
      .success(resp => {
        Object.assign(this.form, resp.data)
      })
      .send(this.shopId)
  }
}
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
}
</style>
