<template>
  <div class="fake-search">
    <div class="input-wrapper" @click="$emit('searchClick')">
      <div class="input text-overflow">
        <span v-if="searchText" class="search-text">{{searchText}}</span>
        <span v-if="!searchText && placeholder" class="placeholder">{{placeholder}}</span>
      </div>
      <van-icon class="icon" name="search" size="20" color="#b6b6b6"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'fake-search',
  props: {
    searchText: { type: String },
    placeholder: { type: String }
  }
}
</script>

<style lang="less" scoped>
.fake-search {
  padding: 10px;
  background-color: #fff;
  border-bottom: solid 1px #eee;
}
.input-wrapper {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  @height: 36px;
  border-radius: @height;
  height: @height;
  line-height: @height;
  padding: 0 20px;
}
.input {
  flex: 1;
  .placeholder {
    font-size: 13px;
    color: #b6b6b6;
  }
  .search-text {
    font-size: 13px;
    color: #404040;
  }
}
.icon {
  margin-left: 10px;
}
</style>
