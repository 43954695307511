<template>
  <div class="home">
    <div class="body">
      <tab-page-order ref="order" v-show="'order' === selectedTab" v-if="userMenuMap.order"></tab-page-order>
      <tab-page-all-order-delivery ref="delivery" v-show="'delivery' === selectedTab" v-if="userMenuMap.delivery"></tab-page-all-order-delivery>
      <tab-page-produce-item ref="produceItem" v-show="'produceItem' === selectedTab" v-if="userMenuMap.produceItem"></tab-page-produce-item>
      <tab-page-supplier-delivery ref="supplierDelivery" v-show="'supplierDelivery' === selectedTab" v-if="userMenuMap.supplierDelivery"></tab-page-supplier-delivery>
      <tab-page-supplier-shipment ref="supplierShipment" v-show="'supplierShipment' === selectedTab" v-if="userMenuMap.supplierShipment"></tab-page-supplier-shipment>
      <tab-page-manage ref="manage" v-show="'manage' === selectedTab" v-if="userMenuMap.manage"></tab-page-manage>
      <tab-my ref="my" v-show="'my' === selectedTab"></tab-my>
    </div>

    <div class="bottom">
      <van-tabbar v-model="selectedTab">
        <van-tabbar-item v-for="m in menus" :key="m.id" :name="m.id">{{m.title}}</van-tabbar-item>
      </van-tabbar>
    </div>
    <router-view/>
  </div>
</template>

<script>
import TabPageManage from './tab-page-manage'
import TabPageOrder from './tab-page-order'
import TabPageAllOrderDelivery from './tab-page-all-delivery'
import TabPageProduceItem from './tab-page-produce-item'
import TabPageSupplierDelivery from './tab-page-supplier-delivery'
import TabPageSupplierShipment from './tab-page-supplier-shipment'
import TabMy from './my'
import WeChatUtil from '../utils/wechat'
import { userStore } from '../store'


export default {
  components: {
    TabPageManage,
    TabPageOrder,
    TabPageAllOrderDelivery,
    TabPageProduceItem,
    TabPageSupplierDelivery,
    TabPageSupplierShipment,
    TabMy
  },
  data () {
    const userMenus = {}
    for (const id of (userStore.state.uiMap.mbm || [])) {
      userMenus[id] = true
    }
    const menus = []
    if (userMenus.all || userMenus.order) {
      userMenus.order = true
      menus.push({ id: 'order', title: '店铺订单' })
    }
    if (userMenus.all || userMenus.delivery) {
      userMenus.delivery = true
      menus.push({ id: 'delivery', title: '发货' })
    }
    if (userMenus.all || userMenus.produceItem) {
      userMenus.produceItem = true
      menus.push({ id: 'produceItem', title: '生产订单' })
    }
    if (userMenus.all || userMenus.supplierDelivery) {
      userMenus.supplierDelivery = true
      menus.push({ id: 'supplierDelivery', title: '待发货' })
    }
    if (userMenus.all || userMenus.supplierShipment) {
      userMenus.supplierShipment = true
      menus.push({ id: 'supplierShipment', title: '快递' })
    }
    if (userMenus.all || userMenus.manage) {
      userMenus.manage = true
      menus.push({ id: 'manage', title: '管理' })
    }

    userMenus.my = true
    menus.push({ id: 'my', title: '我的' })

    const firstViewTab = {}
    for (const m of menus) {
      firstViewTab[m.id] = false
    }

    return {
      selectedTab: menus.length === 0 ? 'my' : menus[0].id,
      menus: menus,
      userMenuMap: userMenus, // 用户拥有的菜单ID
      firstViewTab: firstViewTab
    }
  },
  computed: {
    userType () {
      return this.$userStore.state.type
    }
  },
  watch: {
    selectedTab (newTab, oldTab) {
      this.tabViewChange(newTab, oldTab)
    }
  },
  methods: {
    tabViewChange (newTab, oldTab) {
      if (oldTab) {
        this.$refs[oldTab].onTabViewInvisible()
      }
      if (this.firstViewTab[newTab] === false) {
        this.firstViewTab[newTab] = true
        this.$refs[newTab].onTabFirstView()
      }
      this.$refs[newTab].onTabViewVisible()
    }
  },
  mounted () {
    this.tabViewChange(this.selectedTab)
    WeChatUtil.init()
  }
}
</script>

<style lang="less" scoped>
@deep: ~'>>>';
@tabHeight: 50px;

.home {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.body {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: @tabHeight + 1;
}

.bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: @tabHeight;
  border-top: solid 1px #eee;
}

//.body @{deep} .mint-tab-container {
//  position: absolute;
//  left: 0;
//  top: 0;
//  right: 0;
//  bottom: 0;
//}
//.body @{deep} .mint-tab-container .mint-tab-container-wrap {
//  position: absolute;
//  left: 0;
//  top: 0;
//  right: 0;
//  bottom: 0;
//}
//
//.body @{deep} .mint-tab-container .mint-tab-container-item {
//  position: absolute;
//  left: 0;
//  top: 0;
//  right: 0;
//  bottom: 0;
//  overflow: auto;
//}
//
//.tab-bar {
//  height: @tabHeight;
//  .mint-tab-item {
//    display: flex;
//    justify-content: center;
//    align-items: center;
//  }
//}
//.tab-bar @{deep} .mint-tab-item-label {
//  font-size: 14px;
//}
//.tab-bar .mint-tab-item.is-selected {
//  background-color: #26a2ff;
//  & @{deep} .mint-tab-item-label {
//    color: #fff;
//    text-decoration: none;
//  }
//}
</style>
