<template>
  <div class="user-list">
    <empty-data v-if="users.length === 0" />
    <van-swipe-cell v-for="item in users" :key="item.id">
      <van-cell>
        {{userTypeMapping[item.type]}}
        <div slot="label">
          <span>{{item.username}}</span>
          <span style="margin-left: 20px;">{{item.createDate}}</span>
        </div>
        <div slot="title" class="user-name" :class="{'removed': !item.status}">{{item.name}}</div>
      </van-cell>
      <template #right>
        <van-button square type="default" text="编辑" @click="$emit('editClick', item)" />
      </template>
    </van-swipe-cell>
  </div>
</template>

<script>
import { USER_TYPE_MAPPING } from '../../../constants/user-type'

export default {
  props: {
    users: { type: Array }
  },
  data () {
    return {
      userTypeMapping: USER_TYPE_MAPPING
    }
  }
}
</script>

<style lang="less" scoped>
.user-list {

}
.user-name.removed {
  color: #bb3e30;
  text-decoration: line-through;
}
</style>
