<template>
  <app-page :use-back-icon="false" :title="pageTitle" :right-text="shareButtonText" @rightClick="handleShare">
    <van-tabs v-model="selectedTab" animated :ellipsis="false" :lazy-render="true">

      <van-tab v-for="item in tabs" :key="item.id" :name="item.id">
        <template #title>
          <van-badge :content="totals[item.id]" v-if="totals[item.id] > 0">
            <span>{{item.title}}</span>
          </van-badge>
          <span v-else>{{item.title}}</span>
        </template>
        <timeout-list v-if="item.id === 'timeout'"></timeout-list>
        <supplier-produce-item-list v-else
                                    :tab-id="item.id"
                                    :status="item.status"
                                    :order-custom="item.order"
                                    :current-tab="selectedTab"
                                    @totalChange="handleTotalChanged"
                                    @itemSelected="handleItemSelected"
        >
        </supplier-produce-item-list>
      </van-tab>

    </van-tabs>


    <editor title="分享地址"
            :visible.sync="sharePanel.visible"
            :hide-bottom="true"
    >
      <van-field
          v-model="sharePanel.url"
          rows="4"
          autosize
          label="分享地址"
          type="textarea"
      />
      <div style="padding: 10px;">
        <van-button type="info" block @click="handleCopyShareUrl(sharePanel.url)">复制</van-button>
      </div>
      <van-field
          v-model="sharePanel.excelUrl"
          rows="4"
          autosize
          label="Excel下载地址"
          type="textarea"
      />
      <div style="padding: 10px;">
        <van-button type="info" block @click="handleCopyShareUrl(sharePanel.excelUrl)">复制</van-button>
      </div>
    </editor>
  </app-page>
</template>

<script>
import { listPage, shareProduceItems } from '../http/api/supplier-produce-item'
import SupplierProduceItemList from './supplier/produce-item/list'
import TimeoutList from './supplier/produce-item/timeout'
import { ROUTE_MY } from '../router/router-constants'
import buildSearchParams from './supplier/produce-item-search-params'
import copy from 'copy-to-clipboard'

export default {
  components: { SupplierProduceItemList, TimeoutList },
  data () {
    const tabs = [
      { id: 'new', status: 'new', title: '新单', order: 'create_time, number' },
      { id: 'accept', status: 'accept', title: '已接单', order: 'create_time, number' },
      { id: 'delivery', status: 'delivery', title: '已发货', order: 'delivery_time desc, number' },
      { id: 'timeout', status: ['timeoutAccept', 'timeoutDelivery'], title: '超时' },
      { id: 'reject', status: 'reject', title: '拒单' },
      { id: 'return', status: 'return', title: '退货' },
      { id: 'all', status: null, title: '全部' }
    ]
    const totals = {}
    const tabSelectedItems = {}
    for (const tab of tabs) {
      totals[tab.id] = 0
      tabSelectedItems[tab.id] = []
    }
    return {
      selectedTab: 'new',
      tabs: tabs,
      totals: totals,
      tabSelectedItems: tabSelectedItems,
      sharePanel: {
        visible: false,
        url: null,
        excelUrl: null
      }
    }
  },
  computed: {
    pageTitle () {
      return '供应商订单 - ' + this.tabs.find(item => item.id === this.selectedTab).title
    },
    shareButtonText () {
      const ids = this.tabSelectedItems[this.selectedTab]
      if (ids && ids.length > 0) {
        return '分享'
      } else {
        return null
      }
    }
  },
  methods: {
    onTabFirstView () {
    },
    onTabViewVisible () {
    },
    onTabViewInvisible () {
    },
    handleNavSetting () {
      this.$router.push({ name: ROUTE_MY })
    },
    handleTotalChanged (status, total) {
      this.totals[status] = total
    },
    handleItemSelected (tabId, ids) {
      this.tabSelectedItems[tabId] = ids || []
    },
    handleShare () {
      const ids = this.tabSelectedItems[this.selectedTab]
      if (ids && ids.length > 0) {
        this.$loading.open()
        shareProduceItems()
          .complete(() => (this.$loading.close()))
          .success(resp => {
            this.sharePanel.url = resp.data.page
            this.sharePanel.excelUrl = resp.data.excel
            this.sharePanel.visible = true
          })
          .send(ids, '/#/share/produce_item')
      } else {
        this.$message.error('请先选择要分享的项目。')
      }
    },
    handleCopyShareUrl (url) {
      copy(url)
      this.sharePanel.visible = false
      this.$message.alert('分享地址已经复制，请粘贴发给其他人。')
    }
  },
  mounted () {
    for (const tab of this.tabs) {
      if (Array.isArray(tab.status)) {
        for (const s of tab.status) {
          const p = buildSearchParams(s)
          listPage()
            .success(resp => {
              this.totals[tab.id] += resp.data.total
            })
            .send(p)
        }
      } else {
        const p = buildSearchParams(tab.status)
        listPage()
          .success(resp => {
            this.totals[tab.id] = resp.data.total
          })
          .send(p)
      }
    }
  }
}
</script>

<style scoped>

</style>
