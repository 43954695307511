export default function (status, orderCustom) {
  return {
    status: status,
    number: null,
    supplierId: null,
    delay: false,
    onlyDelete: false,
    trackNo: null,
    createTimeStart: null,
    createTimeEnd: null,
    orderByName: 'create_time',
    orderByDirection: status && status.startsWith('timeout') ? 'asc' : 'desc',
    orderCustom: orderCustom
  }
}
