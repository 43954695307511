import kit from '@/utils/kit'

export const ORDER_SYNC_STATUS_SYNC = 1
export const ORDER_SYNC_STATUS_SUCCESS = 2
export const ORDER_SYNC_STATUS_ERROR = 3
export const ORDER_SYNC_STATUS_STOP = 4

export const ORDER_SYNC_STATUS_MAPPING = {
  [ORDER_SYNC_STATUS_SYNC]: '正在同步',
  [ORDER_SYNC_STATUS_SUCCESS]: '已完成',
  [ORDER_SYNC_STATUS_ERROR]: '发生错误',
  [ORDER_SYNC_STATUS_STOP]: '停止'
}

export const ORDER_SYNC_STATUS_LIST = kit.obj.toArray(ORDER_SYNC_STATUS_MAPPING)
