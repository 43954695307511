<template>
  <app-page :use-back-icon="false" title="设置">
    <div>
      <van-cell :title="user.name" value="姓名"/>
      <van-cell :title="user.username" value="手机号码"/>
      <van-cell v-if="supplierName" :title="supplierName" value="供应商"/>
      <van-cell v-else :title="user.type" value="用户类型"/>
      <van-cell :title="user.createDate" value="创建日期"/>
    </div>
    <div style="padding: 10px;">
      <van-button v-if="canSwitchToSupplier" type="info" block @click="handleSwitchIdentity" style="margin-bottom: 10px;">
        <span v-if="isSupplier">切换回管理员身份</span>
        <span v-else>切换为供应商身份</span>
      </van-button>
      <van-button type="danger" block @click="handleLogout" :loading="loading">退出</van-button>
    </div>

    <van-action-sheet
        v-model="supplierVisible"
        :actions="suppliers"
        cancel-text="取消"
        close-on-click-action
        @select="handleSelectSupplier"
    />
  </app-page>
</template>

<script>
import { logout, getMyProfiles, switchSupplierIdentity } from '../http/api/admin'
import { ROUTE_LOGIN } from '../router/router-constants'
import { USER_TYPE_ADMIN, USER_TYPE_BM } from '../constants/user-type'
import { listSupplier } from '../http/api/supplier'

export default {
  data () {
    return {
      loading: false,
      user: {
        name: null,
        username: null,
        type: null,
        supplierName: null,
        createDate: null
      },
      supplierVisible: false,
      suppliers: []
    }
  },
  computed: {
    canSwitchToSupplier () {
      return this.user.type === USER_TYPE_ADMIN || this.user.type === USER_TYPE_BM
    },
    isSupplier () {
      return this.$userStore.get('isSupplier')
    },
    supplierName () {
      return this.$userStore.state.supplierName || this.user.supplierName
    }
  },
  methods: {
    onTabFirstView () {
      this.loadProfiles()
    },
    onTabViewVisible () {
    },
    onTabViewInvisible () {
    },
    loadProfiles () {
      getMyProfiles()
        .success(resp => {
          Object.assign(this.user, resp.data)
          if (this.canSwitchToSupplier) {
            listSupplier()
              .success(resp => (this.suppliers = resp.data))
              .send()
          }
        })
        .send()
    },
    handleLogout () {
      this.loading = true
      logout()
        .complete(() => (this.loading = false))
        .success(() => {
          this.$router.push({ name: ROUTE_LOGIN })
        })
        .send()
    },
    handleSwitchIdentity () {
      if (this.isSupplier) {
        this.doSwitchSupplierIdentity(null, null)
      } else {
        this.supplierVisible = true
      }
    },
    handleSelectSupplier (item) {
      this.doSwitchSupplierIdentity(item.id, item.name)
    },
    doSwitchSupplierIdentity (supplierId, supplierName) {
      this.$loading.open()
      switchSupplierIdentity()
        .complete(() => (this.$loading.close()))
        .success(() => {
          this.$userStore.setUserInfo({
            supplier: !!supplierId,
            supplierName: supplierName
          })
          this.$message.success('身份切换成功。')
          setTimeout(() => {
            window.location.reload()
          }, 500)
        })
        .send(supplierId)
    }
  }
}
</script>

<style scoped>

</style>
