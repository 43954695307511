<template>
  <transition
      @before-enter="onPageBeforeEnter"
      @enter="onPageEnter"
      @leave="onPageClosed"
      appear
      @before-appear="onPageBeforeEnter"
      @appear="onPageEnter"
  >
    <div class="app-page">
      <div class="header">
        <van-nav-bar
            :title="title"
            :left-arrow="useBackIcon"
            :right-text="rightText"
            @click-left="handleGoBack"
            @click-right="$emit('rightClick')"
        >
          <template #right v-if="rightIcon">
            <van-icon :name="rightIcon" slot="right-icon" @click.stop.prevent="$emit('rightClick')" />
          </template>
        </van-nav-bar>
      </div>

      <div v-if="$slots.top">
        <slot name="top"></slot>
      </div>

      <div class="main-content">
        <slot></slot>
      </div>

      <div class="footer" v-if="$slots.footer">
        <slot name="footer"></slot>
      </div>

      <router-view v-if="useBackIcon"/>
    </div>
  </transition>
</template>

<script>
import Velocity from 'velocity-animate'
import RouterInfo from '../router/router-info'

const DURATION = 200

export default {
  name: 'app-page',
  props: {
    title: { type: String, default: '' },
    useBackIcon: { type: Boolean, default: true },
    rightIcon: { type: String },
    rightText: { type: String }
  },
  data () {
    return {}
  },
  methods: {
    handleGoBack () {
      const parent = new RouterInfo(this.$route).getParent()
      if (parent) {
        this.$router.push({ name: parent.name })
      }
    },
    onPageBeforeEnter (el) {
      let clientWidth = 0
      const container = document.body
      if (container) {
        clientWidth = container.clientWidth
      }
      el.style.left = -clientWidth + 'px'
      el.style.width = clientWidth + 'px'
    },
    onPageEnter (el, done) {
      this.pageReadyIsEmit = true
      const _this = this
      Velocity(el, { left: 0 }, {
        duration: DURATION,
        complete: () => {
          _this.$emit('pageReady')
          el.style.width = 'auto'
          done()
        }
      })
    },
    onPageClosed (el, done) {
      const clientWidth = document.body.clientWidth
      const _this = this
      el.style.width = clientWidth + 'px'
      Velocity(el, { left: clientWidth }, {
        duration: DURATION,
        complete: () => {
          _this.$emit('pageDestroy')
          el.style.width = 'auto'
          done()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.app-page {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #f6f6f6;
  overflow: hidden;
  z-index: 2; // vant tabbar的zIndex为1，需要盖住它
  display: flex;
  flex-direction: column;
}
.header {
  position: relative;
  overflow: visible;
}
.main-content {
  position: relative;
  flex: 1;
  overflow-y: auto;
}
.footer {
  background-color: #fff;
  border-top: solid 1px #e8e8e8;
}
</style>
