<template>
  <van-popup
      v-model="isVisible"
      :close-on-click-overlay="!saving"
      position="bottom"
      :style="{ height: height }"
      class="editor"
      get-container="body"
  >
    <div class="editor-top" v-show="!!title">
      {{title}}
    </div>
    <div class="editor-center">
      <slot></slot>
    </div>
    <div class="editor-bottom padding-button" v-if="!hideBottom">
      <van-button type="default" style="min-width: 80px;" :loading="saving" @click="handleCancel">{{cancelButtonText}}</van-button>
      <van-button type="info" style="min-width: 80px;" :loading="saving" @click="$emit('save')">{{saveButtonText}}</van-button>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: 'editor',
  props: {
    title: { type: String, default: '' },
    visible: { type: Boolean, required: true },
    size: { type: String, default: 'small' },
    saveButtonText: { type: String, default: '保存' },
    cancelButtonText: { type: String, default: '取消' },
    hideBottom: { type: Boolean, default: false },
    saving: { type: Boolean, default: false }
  },
  computed: {
    height () {
      switch (this.size) {
        // size全部固定在90%，因为在手机端软键盘弹出后，会使整个布局的高度变小，这导致中间部分看不见
        case 'small':
          return '90%'
        case 'mid':
          return '90%'
        case 'large':
          return '90%'
        default:
          return this.size
      }
    },
    isVisible: {
      get () {
        return this.visible
      },
      set (v) {
        this.$emit('update:visible', v)
      }
    }
  },
  methods: {
    handleCancel () {
      this.isVisible = false
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="less" scoped>
.editor {
  display: flex;
  flex-direction: column;
}
.editor-top {
  padding: 10px;
  border-bottom: solid 1px #eee;
}
.editor-center {
  flex: 1;
  overflow-y: auto;
  position: relative;
}
.editor-bottom {
  padding: 10px 0;
  text-align: center;
}
</style>
