<template>
  <editor :visible.sync="visible" title="延迟生产" :saving="saving" @save="handleSave" size="mid">
    <van-field label="是否延迟生产">
      <template #input>
        <van-switch v-model="form.delay" size="20" />
      </template>
    </van-field>
    <van-field
        v-show="form.delay"
        v-model="form.delayReason"
        rows="3"
        autosize
        label="延迟原因"
        type="textarea"
        maxlength="200"
        placeholder="请填写延迟原因"
        show-word-limit
    />
  </editor>
</template>

<script>
import kit from '@/utils/kit'
import { delayProduceItem } from '../../../http/api/supplier-produce-item'

export default {
  data () {
    return {
      visible: false,
      saving: false,
      form: {
        id: null,
        delay: false,
        delayReason: null
      }
    }
  },
  computed: {
  },
  watch: {
    visible (v) {
      if (!v) {
        this.close()
      }
    }
  },
  methods: {
    open (model) {
      this.visible = true
      if (model) {
        kit.obj.getProperties(this.form, model)
      }
    },
    close () {
      this.form.id = null
      this.form.delay = null
      this.form.message = true
    },
    handleSave () {
      this.saving = true
      delayProduceItem()
        .complete(() => (this.saving = false))
        .success(resp => {
          this.$emit('saved', resp.data)
          this.$message.success('保存成功。')
          this.visible = false
        })
        .send(this.form.id, this.form.delay, this.form.delayReason)
    }
  }
}
</script>

<style scoped>

</style>
