<template>
  <order v-if="initPage"></order>
</template>

<script>
import Order from './order'
export default {
  components: { Order },
  data () {
    return {
      initPage: false
    }
  },
  methods: {
    onTabFirstView () {
      this.initPage = true
    },
    onTabViewVisible () {
    },
    onTabViewInvisible () {
    }
  }
}
</script>

<style scoped>

</style>
