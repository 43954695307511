<template>
  <editor :visible.sync="visible" title="店铺编辑" size="large" :saving="saving" @save="handleSave">
    <van-field v-model="form.name" label="名称" placeholder="店铺名称" :error-message="formError.name" clearable />
    <van-field v-model="form.domain" label="域名" placeholder="店铺后台地址，https://example.com" :error-message="formError.domain" clearable />
    <option-picker v-model="form.type" label="类型" :options="shopOptions" :error-message="formError.type"></option-picker>
    <van-field v-model="form.orderPrefix" label="订单前缀" placeholder="ABC_" :error-message="formError.orderPrefix" clearable />
    <p class="text-danger font-mini" style="padding: 5px 20px;">
      使用2～5个字母作为订单的前缀，同步订单的时候这个前缀作为订单ID的前缀，如果你解绑店铺，重新绑定后设置的前缀不一样，是无法找到以前的订单的。
    </p>
  </editor>
</template>

<script>
import kit from '@/utils/kit'
import { addShop, updateShop } from '../../../http/api/shop'
import { SHOP_TYPE_LIST } from '../../../constants/shop-type'

const DEFAULT_FORM = {
  id: null,
  name: null,
  type: null,
  domain: null,
  orderPrefix: null
}

export default {
  data () {
    return {
      visible: false,
      saving: false,
      form: Object.assign({}, DEFAULT_FORM),
      formError: {
        name: null,
        type: null,
        domain: null,
        orderPrefix: null
      },
      model: null,
      shopOptions: SHOP_TYPE_LIST
    }
  },
  computed: {
    isUpdate () {
      return !!this.model
    }
  },
  watch: {
    visible (v) {
      if (!v) {
        this.close()
      }
    }
  },
  methods: {
    open (model) {
      this.visible = true
      this.model = model
      if (model) {
        kit.obj.getProperties(this.form, model)
      }
    },
    close () {
      this.model = null
      this.form = Object.assign({}, DEFAULT_FORM)
      this.clearErrorMessage()
    },
    clearErrorMessage () {
      for (const key of Object.keys(this.formError)) {
        this.formError[key] = null
      }
    },
    handleSave () {
      this.saving = true
      const api = this.isUpdate ? updateShop : addShop
      this.clearErrorMessage()
      api()
        .complete(() => (this.saving = false))
        .validationFail(resp => {
          if (resp.details && resp.details.length > 0) {
            for (const d of resp.details) {
              if (Object.prototype.hasOwnProperty.call(this.formError, d.property)) {
                this.formError[d.property] = d.message
              }
            }
          }
        })
        .success(resp => {
          this.$emit('saved', resp.data)
          this.$message.success('保存成功。')
          this.visible = false
        })
        .send(this.form)
    }
  }
}
</script>

<style scoped>

</style>
