import defineApi from '../define-api'
import http from '../application-api'
import { userStore } from '@/store'

export const loginValidateCode = defineApi((config) => {
  http.load('/login/validate_code', config)
})

export const getMyProfiles = defineApi((config) => {
  http.get('/admin/my', config)
})

/**
 * 发送登录请求，将服务端返回的用户信息保存到本地。
 * @type {Function}
 */
export const login = defineApi((config, phone, sms) => {
  config = Object.assign({
    params: {
      phone: phone,
      sms: sms
    }
  }, config)
  const successCallback = config.success
  config.success = function (resp) {
    userStore.setUserInfo(resp.data)
    successCallback && successCallback.apply(config.context, arguments)
  }
  http.post('/login', config)
})

export const wechatBind = defineApi((config, openId, phone) => {
  config = Object.assign({
    params: {
      openId: openId,
      phone: phone
    }
  }, config)
  const successCallback = config.success
  config.success = function (resp) {
    userStore.setUserInfo(resp.data)
    successCallback && successCallback.apply(config.context, arguments)
  }
  http.post('/wechat/account_bind', config)
})

export const wechatLogin = defineApi((config, openId) => {
  config = Object.assign({
    params: {
      openId: openId
    }
  }, config)
  const successCallback = config.success
  config.success = function (resp) {
    userStore.setUserInfo(resp.data)
    successCallback && successCallback.apply(config.context, arguments)
  }
  http.post('/wechat/login', config)
})

/**
 * 发送登出请求，请求成功后，移除本地登录用户信息，并新获取sessionId后再将回调函数交给外部。
 * @type {Function}
 */
export const logout = defineApi((config) => {
  const successCallback = config.success
  config.success = function (resp) {
    userStore.removeUserInfo()
    successCallback && successCallback.apply(config.context, arguments)
  }
  http.post('/logout', config)
})

export const getLoginCode = defineApi((config, phone) => {
  config.params = {
    phone: phone
  }
  http.post('/login/code', config)
})

export const getSessionsByUsername = defineApi((config, username) => {
  config.params = { username: username }
  return http.get('/admin/sessions', config)
})

export const killUserSession = defineApi((config, sessionId) => {
  config.params = { sessionId: sessionId }
  http.post('/admin/session/kill', config)
})

export const updatePassword = defineApi((config, oldPassword, newPassword) => {
  config.params = {
    oldPassword: oldPassword,
    newPassword: newPassword,
    logoutOnSuccess: false
  }
  http.post('/admin/update_password', config)
})

export const getValidateCodeForForgotPassword = defineApi((config, username) => {
  config.params = {
    username: username
  }
  http.get('/password/forgot/code', config)
})

export const updateForgotPassword = defineApi((config, username, password, code) => {
  config.params = {
    username: username,
    password: password,
    code: code
  }
  http.post('/password/forgot/reset', config)
})

export const managerList = defineApi((config, params) => {
  config.params = params
  return http.load('/admin/list', config)
})

/**
 * @param manager
 *   username {string}
 *   password {string}
 *   name {string}
 *   enabled {boolean}
 *   sex {int} 1或0
 *   [title] {string}
 *   [shopId] {string}
 * @type {Function}
 */
export const addManager = defineApi((config, manager) => {
  config.data = manager
  http.post('/admin/add', config)
})


export const addSupplierUser = defineApi((config, supplierUser) => {
  config.data = supplierUser
  http.post('/admin/supplier/user/add', config)
})

/**
 * @param manager
 *   id {string}
 *   password {string} optional
 *   name {string}
 *   enabled {boolean}
 *   sex {int} 1或0
 *   [title] {string}
 *   [shopId] {string}
 * @type {Function}
 */
export const updateManager = defineApi((config, manager) => {
  config.data = manager
  http.post('/admin/update', config)
})

export const deleteManager = defineApi((config, id) => {
  config.params = {
    id: id
  }
  http.post('/admin/delete', config)
})

/**
 * 获取系统中可用的角色列表，如果角色的enabled属性为true，表示该角色已分配给managerId的用户。
 * @param managerId 用于获取用户的已分配角色ID
 * @type {Function}
 */
export const getAvailableRoles = defineApi((config, managerId) => {
  config.params = {
    managerId: managerId
  }
  return http.get('/admin/roles_for_admin', config)
})

export const applyRoleToManager = defineApi((config, managerId, roleId) => {
  config.params = {
    managerId: managerId,
    roleId: roleId
  }
  http.post('/admin/apply_role', config)
})

export const removeRoleFromManager = defineApi((config, managerId, roleId) => {
  config.params = {
    managerId: managerId,
    roleId: roleId
  }
  http.post('/admin/remove_role', config)
})

export const getRolesForMultiUsersEdit = defineApi((config, userIds) => {
  config.params = { userIds: userIds.join(',') }
  http.get('/admin/multi_user/roles', config)
})

/**
 * 一次性为多个用户分配角色。
 * @param userIds {Array<String>}
 * @param roles {Array<{id, enabled}>}
 * @type {Function}
 */
export const applyRoleToMultiUsers = defineApi((config, userIds, roles) => {
  config.data = {
    userIds: userIds,
    roles: roles
  }
  http.post('/admin/apply_role/multi', config)
})

export const switchSupplierIdentity = defineApi((config, supplierId) => {
  config.params = {
    supplierId: supplierId
  }
  http.post('/admin/supplier/switch', config)
})

export const getRoleList = defineApi((config) => {
  http.get('/admin/roles', config)
})
