<template>
  <editor :visible.sync="visible" title="库存产品搜索" size="large" :hide-bottom="true">
    <div class="wrapper">
      <div class="search-wrapper">
        <div class="search-input-wrapper">
          <van-field
              v-model="keyword"
              placeholder="产品标题或SKU关键字"
          >
            <template #button>
              <van-icon v-show="keyword"
                        name="clear"
                        size="16"
                        color="#c9c9c9"
                        @click.stop.prevent="keyword = null"
              />
            </template>
          </van-field>
        </div>
        <van-button @click="handleSearch">搜索</van-button>
      </div>

      <div class="content-center text-primary font-mini" style="margin: 10px 0;">
        <span v-show="resultTotal > 0">共找到 {{resultTotal}} 个产品，最多只显示 200 个。</span>
        <span v-show="resultTotal <= 0">没有找到任何产品</span>
      </div>

      <div class="data-results">
        <ul class="list-group" style="margin: 10px; background-color: #f9f9f9;">
          <li v-for="item in results" :key="item.id" class="list-group-item">
            <div class="image-item small" v-lazy:background-image="item.imageUrl" @click="$productImagePreview.open({
                      images: [item.imageUrl],
                      variant: item.variant,
                      sku: item.sku,
                     })"></div>
            <div class="main-content">
              <div class="sku font-bold text-normal">{{item.sku}}</div>
              <div class="variant text-muted font-mini">{{item.variant}}</div>
              <div>
                <span v-if="item.quantity > 0" class="text-muted">
                  {{item.warehouseTotal}} 个仓库共有库存 <span class="font-bold text-success">{{item.quantity}}</span> 个。
                </span>
                <span v-else class="text-danger font-mini">无库存</span>
              </div>
            </div>
            <van-button type="info" size="small" @click="handleSelectItem(item)">添加</van-button>
          </li>
        </ul>
      </div>
    </div>
  </editor>
</template>

<script>
import { listStockProducts } from '../../http/api/stock-product'

export default {
  data () {
    return {
      visible: false,
      warehouseId: null,
      keyword: null,
      // 查询结果
      results: [],
      resultTotal: 0
    }
  },
  methods: {
    open (warehouseId) {
      this.visible = true
      this.warehouseId = warehouseId
    },
    handleSearch () {
      listStockProducts()
        .success(resp => {
          this.resultTotal = resp.data.total
          this.results = resp.data.records
        })
        .send({
          start: 0,
          limit: 200,
          keyword: this.keyword,
          warehouseId: this.warehouseId
        })
    },
    handleSelectItem (item) {
      this.$emit('selected', item)
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.search-wrapper {
  display: flex;
  margin: 10px;
}
.search-input-wrapper {
  flex: 1;
  border: solid 1px #eee;
  border-radius: 5px;
  margin-right: 5px;
}

.data-results {
  flex: 1;
  overflow-y: auto;
  .list-group-item {
    display: flex;
    align-items: center;
    .main-content {
      flex: 1;
      margin-left: 10px;
    }
  }
}
</style>
