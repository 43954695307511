<template>
  <editor :visible.sync="visible" title="云途 - 发货" size="large" :saving="saving" @save="handleSave">

    <div class="section-group">
      <div class="section-group-head">
        <div class="title">基本信息</div>
      </div>
      <van-field v-model="form.customId" label="自定义标识" placeholder="非必填，方便查找" clearable />
      <van-field v-model="form.tags" label="标签" placeholder="非必填" clearable />
      <div style="padding: 10px 10px 10px 110px;">
        <div class="option-item" v-for="(name,index) in tagOptions" :key="index" @click="form.tags = name">{{name}}</div>
      </div>
      <van-field
          v-model="form.remark"
          rows="1"
          label="备注"
          type="textarea"
          maxlength="80"
          show-word-limit
      />
    </div>

    <div class="section-group">
      <div class="section-group-head">
        <div class="title">出库仓库</div>
      </div>
      <div style="margin: 10px;">
        <div class="warehouse-wrapper"
             :class="{'warehouse-selected': w._selected}"
             v-for="w in warehouseOptions"
             :key="w.id"
             @click="handleSelectWarehouse(w)"
        >
          <div class="warehouse">{{w.name}}</div>
        </div>
      </div>
    </div>

    <div class="section-group">
      <div class="section-group-head">
        <div class="title">发货产品</div>

        <span class="icon-button" @click="handleUpdateDeliveryProduct(null)">新增</span>
        <span class="icon-button" @click="$refs.skuSearch.open(selectedWarehouse.id)" v-show="selectedWarehouse">从库存新增</span>
      </div>
      <div class="order-products">
        <div v-for="(p, index) in form.orderProductList" :key="p.id" class="product-item">
          <div class="image-item" :style="{'background-image': `url(${p.imageUrl})`}" @click="$imagePreview.show(form.orderProductList.map(item => item.imageUrl), index)"></div>
          <div class="total">
            <van-stepper v-model="p.total" integer input-width="40px" button-size="32px" />
            <div class="text-muted font-mini" style="padding: 5px 0;" @click="handleCopySku(p.sku)">SKU: {{p.sku}}</div>
            <div class="sku-stock-info font-mini text-normal" v-show="selectedWarehouse">
              <div v-if="p.sku && skuStocks[p.sku]">
                库存剩余：<span class="font-bold text-success font-normal">{{skuStocks[p.sku].quantity}}</span>
              </div>
              <div v-else>无库存</div>
            </div>
          </div>
          <div class="buttons">
            <van-icon name="edit" size="22px" style="margin-right: 15px;" @click="handleUpdateDeliveryProduct(p)" />
            <van-icon name="delete-o" size="22px" color="#bb3e30" @click="handleRemoveDeliveryProductItem(p)" />
            <div>
              <van-dropdown-menu>
                <van-dropdown-item v-model="p.fromSupplierId" :options="supplierDropdownMenuOptions" />
              </van-dropdown-menu>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section-group">
      <div class="section-group-head">
        <div class="title">物流</div>
      </div>
      <option-picker v-model="form.companyId" label="物流公司" :options="companyOptions"></option-picker>
    </div>

    <div class="section-group" v-if="form.companyId">
      <div class="section-group-head">
        <div class="title">收货地址</div>
      </div>
      <list-option-picker v-model="form.shipMethod" label="运输方式" placeholder="请选择运输方式" :options="shippingMethodOptions"></list-option-picker>
      <van-field v-model="form.weight" type="number" label="包裹重量" clearable>
        <template #button>
          <span class="text-muted font-mini">千克</span>
        </template>
      </van-field>
      <div class="van-cell van-field">
        <div class="van-cell__title van-field__label"><span>外包装数量</span></div>
        <div class="van-cell__value van-field__value">
          <div class="van-field__body">
            <van-stepper v-model="form.packageTotal" integer input-width="40px" button-size="32px" />
          </div>
        </div>
      </div>
      <van-field v-model="form.orderNo" label="客户单号" clearable />
    </div>

    <div class="section-group" v-if="form.companyId">
      <div class="section-group-head">
        <div class="title">海关申报</div>
      </div>
      <div class="van-cell van-field">
        <div class="van-cell__title van-field__label"><span>数量</span></div>
        <div class="van-cell__value van-field__value">
          <div class="van-field__body">
            <van-stepper v-model="form.parcel.total" integer input-width="40px" button-size="32px" />
          </div>
        </div>
      </div>
      <van-field v-model="form.parcel.name" label="申报英文名" clearable />
      <van-field v-model="form.parcel.cname" label="申报中文名" clearable />
      <div style="padding: 10px 10px 10px 110px;">
        <div class="option-item" v-for="(name,index) in parcelNamesForCn" :key="index" @click="handleSelectParcelNameOption(index)">{{name}}</div>
      </div>
      <van-field v-model="form.parcel.materialEn" label="材料英文" clearable />
      <van-field v-model="form.parcel.materialCn" label="材料中文" clearable />
      <van-field v-model="form.parcel.usageEn" label="用途英文" clearable />
      <van-field v-model="form.parcel.usageCn" label="用途中文" clearable />
      <van-field v-model="form.parcel.weight" type="number" label="申报单重" clearable>
        <template #button>
          <span class="text-muted font-mini">千克</span>
        </template>
      </van-field>
      <van-field v-model="form.parcel.price" type="number" label="申报单价" clearable />
      <van-field v-model="form.parcel.currency" label="货币" clearable />
    </div>

    <div class="section-group" v-if="form.companyId">
      <div class="section-group-head">
        <div class="title">收货地址</div>
      </div>
      <list-option-picker v-model="form.receiveAddress.countryCode"
                          label="国家"
                          :options="countryOptions"
                          id-name="code"
                          :clearable="true"
      >
      </list-option-picker>
      <van-field v-model="form.receiveAddress.state" label="省份 / 州" clearable />
      <van-field v-model="form.receiveAddress.city" label="城市" clearable />
      <van-field v-model="form.receiveAddress.address1" label="地址1" clearable />
      <van-field v-model="form.receiveAddress.address2" label="地址2" clearable />
      <van-field v-model="form.receiveAddress.postalCode" label="邮政编号" clearable />
      <van-field v-model="form.receiveAddress.name" label="姓名" clearable />
      <van-field v-model="form.receiveAddress.phone" label="联系电话" clearable />
      <van-field v-model="form.personalTaxNo" label="个人税号" clearable />
    </div>


    <!-- 发货的产品项目编辑器 -->
    <editor :visible.sync="deliveryProductEditor.visible" class="delivery-product-editor" title="修改发货产品信息" @save="handleSaveDeliveryProduct">
      <div class="image-container">
        <div class="image-item" :style="{'background-image': `url(${deliveryProductEditor.form.imageUrl})`}" @click="handleUploadDeliveryProductImage">
          <span class="font-mini text-muted" v-if="!deliveryProductEditor.form.imageUrl">选择图片</span>
          <van-icon name="clear" class="remove-image" color="red" size="20px" @click.stop.prevent="deliveryProductEditor.form.imageUrl = null" />
        </div>
      </div>
      <van-field v-model="deliveryProductEditor.form.variant" type="text" label="规格" />
      <van-field v-model="deliveryProductEditor.form.sku" type="text" label="SKU" />
      <div class="van-cell van-field">
        <div class="van-cell__title van-field__label"><span>数量</span></div>
        <div class="van-cell__value van-field__value">
          <div class="van-field__body">
            <van-stepper v-model="deliveryProductEditor.form.total" integer input-width="40px" button-size="32px" />
          </div>
        </div>
      </div>
      <van-field
          v-model="deliveryProductEditor.form.remark"
          rows="2"
          autosize
          label="备注"
          type="textarea"
          maxlength="200"
          show-word-limit
      />
    </editor>

    <sku-product-search-panel ref="skuSearch" @selected="handleAddDeliveryItemFromSkuStock"></sku-product-search-panel>

  </editor>
</template>

<script>
import { getShippingMethods, getCountries, delivery, uploadImageByDelivery } from '../../../../http/api/order-delivery'
import { listWarehouse } from '../../../../http/api/warehouse'
import { listSupplier } from '../../../../http/api/supplier'
import { getStockBySku } from '../../../../http/api/stock-product'
import kit from '@/utils/kit'
import moment from 'moment'
import WeChatUtil from '../../../../utils/wechat'
import SkuProductSearchPanel from '../../../../components/business/sku-product-search'
import copyToClipboard from 'copy-to-clipboard'
import { LOGISTICS_COMPANY_MAPPING, LOGISTICS_COMPANY_LIST } from '../../../../constants/logistics'

function buildDefaultForm () {
  return {
    orderProductList: [],
    customId: moment().format('yyyyMMDD'),
    tags: null,
    remark: null,
    personalTaxNo: null,
    companyId: null,
    warehouseId: null,
    shipMethod: null,
    shipMethodName: null,
    weight: 0.4, // 单位千克
    orderNo: null,
    packageTotal: 1,
    receiveAddress: {
      countryCode: null,
      country: null,
      stateCode: null,
      state: null,
      city: null,
      address1: null,
      address2: null,
      postalCode: null,
      name: null,
      phone: null
    },
    parcel: {
      price: 100,
      currency: 'USD',
      weight: 0.4,
      name: null,
      cname: null,
      materialCn: '丝绸',
      materialEn: 'Silk',
      usageCn: '穿戴',
      usageEn: 'Wear',
      // https://www.hsbianma.com/Code/6208291010.html
      hsCode: '6208291010',
      total: 1
    }
  }
}
export default {
  components: { SkuProductSearchPanel },
  data () {
    const shippingMethodsGroup = {}
    for (const item of LOGISTICS_COMPANY_LIST) {
      shippingMethodsGroup[item.id] = []
    }

    return {
      visible: false,
      saving: false,
      form: buildDefaultForm(),
      // 海关申报产品名称可选项
      parcelNamesForEn: ['Pajamas', 'Eye mask', 'Bed 4PCS'],
      parcelNamesForCn: ['睡衣', '眼罩', '床上用品'],
      tagOptions: ['换尺码', '漏补'],
      companyOptions: LOGISTICS_COMPANY_LIST,
      countryOptions: [],
      shippingMethodsGroup: shippingMethodsGroup,
      deliveryProductEditor: {
        visible: false,
        isUpdate: false,
        item: null,
        form: {
          imageUrl: null,
          orderProductId: null,
          total: 0,
          variant: null,
          sku: null,
          remark: null
        }
      },
      warehouseOptions: [],
      // key > sku, value > 库存数量
      skuStocks: {},
      supplierOptions: []
    }
  },
  computed: {
    shippingMethodOptions () {
      if (this.form.companyId) {
        return this.shippingMethodsGroup[this.form.companyId]
      } else {
        return []
      }
    },
    selectedWarehouse () {
      return this.warehouseOptions.find(item => item._selected)
    },
    supplierDropdownMenuOptions () {
      const arr = []
      arr.push({
        text: '供应商', value: 'none'
      })
      for (const item of this.supplierOptions) {
        arr.push({
          text: item.name,
          value: item.id
        })
      }
      return arr
    }
  },
  watch: {
    'form.companyId' (id) {
      if (id) {
        if (this.shippingMethodsGroup[id].length === 0) {
          this.loadShippingMethods(id)
        }
      }
    },
    visible (v) {
      if (!v) {
        this.form = buildDefaultForm()
        if (this.selectedWarehouse) {
          this.selectedWarehouse._selected = false
        }
      }
    },
    'form.orderProductList': {
      deep: true,
      handler () {
        let total = 0
        for (const p of this.form.orderProductList) {
          total += p.total
        }
        this.form.parcel.total = total
      }
    },
    'deliveryProductEditor.visible' (v) {
      if (v) {
        const item = this.deliveryProductEditor.item
        this.deliveryProductEditor.form.imageUrl = item.imageUrl
        this.deliveryProductEditor.form.orderProductId = item.orderProductId
        this.deliveryProductEditor.form.variant = item.variant
        this.deliveryProductEditor.form.total = item.total
        this.deliveryProductEditor.form.remark = item.remark
        this.deliveryProductEditor.form.sku = item.sku
      } else {
        this.deliveryProductEditor.item = null
        this.deliveryProductEditor.form.imageUrl = null
        this.deliveryProductEditor.form.orderProductId = null
        this.deliveryProductEditor.form.variant = null
        this.deliveryProductEditor.form.total = 0
        this.deliveryProductEditor.form.remark = null
        this.deliveryProductEditor.form.sku = null
      }
    },
    selectedWarehouse () {
      this.loadSkuStocks()
    }
  },
  methods: {
    /**
     *
     * @param order
     * @param total 该订单下已经发货的次数
     */
    open (order, total) {
      if (total === undefined) {
        total = 0
      }

      if (!this.form.companyId) {
        this.form.companyId = this.companyOptions[0].id
      }

      this.visible = true
      this.form.orderNo = 'XINSIKJ-' + order.orderNo + '-' + (total + 1)
      this.form.orderId = order.id
      this.form.remark = order.adminRemark

      this.form.receiveAddress.countryCode = order.receiverCountryCode
      this.form.receiveAddress.country = order.receiverCountry
      this.form.receiveAddress.stateCode = order.receiverStateCode
      this.form.receiveAddress.state = order.receiverState
      this.form.receiveAddress.city = order.receiverCity
      this.form.receiveAddress.address1 = order.receiverAddress1
      this.form.receiveAddress.address2 = order.receiverAddress2
      this.form.receiveAddress.postalCode = order.receiverPostalCode
      this.form.receiveAddress.name = order.receiverLastName + ' ' + order.receiverFirstName
      this.form.receiveAddress.phone = order.receiverPhone

      for (const p of order.products) {
        this.form.orderProductList.push({
          imageUrl: p.imageUrl,
          orderProductId: p.id,
          fromSupplierId: 'none',
          total: p.total,
          variant: p.variant,
          sku: p.sku,
          remark: null
        })
      }

      this.handleSelectParcelNameOption(0)

      if (this.warehouseOptions.length === 0) {
        listWarehouse()
          .success(resp => {
            for (const tmp of resp.data) {
              tmp._selected = false
            }
            this.warehouseOptions = resp.data
          })
          .send()
      }

      if (this.countryOptions.length === 0) {
        getCountries().success(resp => (this.countryOptions = resp.data)).send()
      }

      if (this.supplierOptions.length === 0) {
        listSupplier().success(resp => (this.supplierOptions = resp.data)).send()
      }

      if (window._orderDeliveryCompanyId) {
        this.form.companyId = window._orderDeliveryCompanyId
      }
      if (window._orderDeliveryShipMethod) {
        this.form.shipMethod = window._orderDeliveryShipMethod
      }
      if (window._orderDeliveryCustomId) {
        this.form.customId = window._orderDeliveryCustomId
      }
    },
    loadShippingMethods (companyId) {
      getShippingMethods()
        .success(resp => {
          this.shippingMethodsGroup[companyId] = resp.data
        })
        .send(companyId)
    },
    handleSave () {
      this.$message.confirm('确认', '确认信息无法，开始下单？', () => {
        if (this.form.shipMethod) {
          this.form.shipMethodName = this.shippingMethodsGroup[this.form.companyId].find(item => item.id === this.form.shipMethod).name
        }
        for (const item of this.form.orderProductList) {
          if (item.fromSupplierId === 'none') {
            item.fromSupplierId = null
          }
        }
        this.saving = true
        delivery()
          .complete(() => (this.saving = false))
          .success(resp => {
            this.visible = false
            this.$emit('saved', resp.data)
            window._orderDeliveryCompanyId = this.form.companyId
            window._orderDeliveryShipMethod = this.form.shipMethod
            window._orderDeliveryCustomId = this.form.customId
          })
          .validationFail(resp => {
            if (resp.details && resp.details.length > 0) {
              const messages = []
              for (const d of resp.details) {
                messages.push(d.message)
              }
              this.$message.error(messages.join(''))
            }
          })
          .send(this.form)
      })
    },
    handleSelectParcelNameOption (index) {
      this.form.parcel.name = this.parcelNamesForEn[index]
      this.form.parcel.cname = this.parcelNamesForCn[index]
    },
    handleRemoveDeliveryProductItem (item) {
      kit.arr.removeItem(this.form.orderProductList, item)
    },
    handleUpdateDeliveryProduct (item) {
      if (!item) {
        this.deliveryProductEditor.isUpdate = false
        item = {
          imageUrl: null, orderProductId: null, variant: null, remark: null, total: 1, sku: null
        }
      } else {
        this.deliveryProductEditor.isUpdate = true
      }
      this.deliveryProductEditor.item = item
      this.deliveryProductEditor.visible = true
    },
    handleSaveDeliveryProduct () {
      if (!this.deliveryProductEditor.isUpdate) {
        this.form.orderProductList.push(this.deliveryProductEditor.item)
      }
      this.deliveryProductEditor.item.imageUrl = this.deliveryProductEditor.form.imageUrl
      this.deliveryProductEditor.item.orderProductId = this.deliveryProductEditor.form.orderProductId
      this.deliveryProductEditor.item.variant = this.deliveryProductEditor.form.variant
      this.deliveryProductEditor.item.remark = this.deliveryProductEditor.form.remark
      this.deliveryProductEditor.item.total = this.deliveryProductEditor.form.total
      this.deliveryProductEditor.item.sku = this.deliveryProductEditor.form.sku

      this.deliveryProductEditor.visible = false
    },
    handleUploadDeliveryProductImage () {
      WeChatUtil.chooseImage(img => {
        this.$loading.open()
        uploadImageByDelivery()
          .complete(() => (this.$loading.close()))
          .success(resp => {
            this.deliveryProductEditor.form.imageUrl = resp.data
          })
          .send(img)
      })
    },
    handleSelectWarehouse (w) {
      if (this.selectedWarehouse === w) {
        w._selected = false
      } else {
        if (this.selectedWarehouse) {
          this.selectedWarehouse._selected = false
        }
        w._selected = true
      }
      if (this.selectedWarehouse) {
        this.form.warehouseId = this.selectedWarehouse.id
      }
    },
    loadSkuStocks () {
      if (this.selectedWarehouse) {
        this.$loading.open()
        const sku = this.form.orderProductList.map(item => item.sku).join(',')
        getStockBySku()
          .complete(() => (this.$loading.close()))
          .success(resp => {
            this.skuStocks = {}
            for (const key of Object.keys(resp.data)) {
              this.skuStocks[key] = resp.data[key][0]
            }
          })
          .send(sku, this.selectedWarehouse.id)
      }
    },
    handleAddDeliveryItemFromSkuStock (item) {
      this.form.orderProductList.push({
        imageUrl: item.imageUrl,
        orderProductId: null,
        variant: item.variant,
        remark: null,
        total: 1,
        sku: item.sku
      })
      this.loadSkuStocks()
    },
    handleCopySku (sku) {
      copyToClipboard(sku)
      this.$message.success('复制成功：' + sku)
    }
  }
}
</script>

<style lang="less" scoped>
@deep: ~'>>>';

.section-group + .section-group {
  margin-top: 20px;
}
.section-group-head {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #f5f5f5;
  font-size: 14px;
  color: #343434;
  font-weight: bold;
  .title {
    flex: 1;
    margin-right: 10px;
  }
  .icon-button + .icon-button {
    margin-left: 15px;
  }
  .icon-button {
    font-size: 12px;
    font-weight: normal;
    color: #bb3e30;
  }
}

.option-item {
  display: inline-block;
  margin: 5px;
  padding: 5px 10px;
  color: #fff;
  background-color: #317dcb;
  font-size: 13px;
  border-radius: 5px;
}

.order-products {
  margin: 10px;
  .product-item + .product-item {
    margin-top: 5px;
    padding-top: 5px;
    border-top: solid 1px #eee;
  }
  .product-item {
    display: flex;
    align-items: center;
    .image-item {
      margin-right: 10px;
    }
    .total {
      flex: 1;
      text-align: center;
    }
    .buttons {
      width: 80px;
      text-align: right;
    }
    & @{deep} .van-dropdown-menu {
      max-width: 80px;
    }
  }
}

.delivery-product-editor {
  .image-container {
    .image-item {
      margin: 10px auto;
      position: relative;
      overflow: visible !important;
      text-align: center;
      line-height: 70px;
      .remove-image {
        position: absolute;
        top: -8px;
        right: -8px;
      }
    }
  }
}

.warehouse-wrapper {
  display: inline-block;
  .warehouse {
    margin: 5px;
    padding: 6px 12px;
    border-radius: 5px;
    border: solid 1px #eee;
  }
  &.warehouse-selected {
    .warehouse {
      background-color: #2066b4;
      color: #fff;
    }
  }
}
</style>
