<template>
  <div style="border-bottom: solid 1px #f2f2f2;">
    <van-field
        readonly
        clickable
        :value="selectedOptionText"
        :label="label"
        :placeholder="placeholder"
        @click="showPicker = true"
        :error-message="errorMessage"
    >
      <template #button>
        <van-icon v-if="clearable"
                  v-show="selectedKey"
                  name="clear"
                  size="16"
                  color="#c9c9c9"
                  @click.stop.prevent="$emit('change', null)"
        />
      </template>
    </van-field>

    <van-popup v-model="showPicker" position="bottom" get-container="body">
      <van-picker
          show-toolbar
          :columns="optionTextList"
          @confirm="handleConfirm"
          @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'option-picker',
  props: {
    options: { type: Array, default: function () { return [] } },
    idName: { type: String, default: 'id' },
    textName: { type: String, default: 'name' },
    label: { type: String, required: true },
    placeholder: { type: String, default: '' },
    errorMessage: { type: String },
    selectedKey: { type: String },
    clearable: { type: Boolean, default: false }
  },
  model: {
    prop: 'selectedKey',
    event: 'change'
  },
  data () {
    return {
      showPicker: false,
      selectedIndex: null
    }
  },
  computed: {
    optionTextList () {
      return this.options.map(item => item[this.textName])
    },
    selectedOptionText () {
      if (this.selectedKey) {
        const item = this.options.find(item => item[this.idName] === this.selectedKey)
        if (item) {
          return item[this.textName]
        }
      }
      return null
    }
  },
  watch: {
  },
  methods: {
    handleConfirm (val, index) {
      if (val) {
        this.$emit('change', this.options[index][this.idName])
      } else {
        this.$emit('change', null)
      }
      this.showPicker = false
    }
  }
}
</script>

<style scoped>

</style>
