<template>
  <editor :visible.sync="visible" title="供应商编辑" :saving="saving" @save="handleSave">
    <van-field v-model="form.name" label="名称" placeholder="供应商名称" :error-message="formError.name" clearable />
    <van-field label="是否可用">
      <template #input>
        <van-switch v-model="form.enabled" size="20" />
      </template>
    </van-field>
  </editor>
</template>

<script>
import kit from '@/utils/kit'
import { addSupplier, updateSupplier } from '../../../http/api/supplier'

export default {
  data () {
    return {
      visible: false,
      saving: false,
      form: {
        id: null,
        name: null,
        enabled: true
      },
      formError: {
        name: null
      },
      model: null
    }
  },
  computed: {
    isUpdate () {
      return !!this.model
    }
  },
  watch: {
    visible (v) {
      if (!v) {
        this.close()
      }
    }
  },
  methods: {
    open (model) {
      this.visible = true
      this.model = model
      if (model) {
        kit.obj.getProperties(this.form, model)
      }
    },
    close () {
      this.model = null
      this.form.id = null
      this.form.name = null
      this.form.enabled = true
      this.clearErrorMessage()
    },
    clearErrorMessage () {
      for (const key of Object.keys(this.formError)) {
        this.formError[key] = null
      }
    },
    handleSave () {
      this.saving = true
      const api = this.isUpdate ? updateSupplier : addSupplier
      this.clearErrorMessage()
      api()
        .complete(() => (this.saving = false))
        .validationFail(resp => {
          if (resp.details && resp.details.length > 0) {
            for (const d of resp.details) {
              if (Object.prototype.hasOwnProperty.call(this.formError, d.property)) {
                this.formError[d.property] = d.message
              }
            }
          }
        })
        .success(resp => {
          this.$emit('saved', resp.data)
          this.$message.success('保存成功。')
          this.visible = false
        })
        .send(this.form)
    }
  }
}
</script>

<style scoped>

</style>
