import wx from 'weixin-js-sdk'
import Http from '../http/http'
import { Message } from '../components/message'

export default {
  init () {
    const ua = window.navigator.userAgent.toLowerCase()
    const isIphone = ua.indexOf('iphone') !== -1
    // ios下只要config一次即可，而安卓要每次使用（url变化时）都要config一次
    // alert(location.href.split('#')[0])
    if (isIphone && this.ready) return
    new Http().get('/api/wechat/jsapi/sign', {
      params: {
        url: encodeURIComponent(location.href.split('#')[0])
      },
      success: resp => {
        const config = {
          // beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          // debug: process.env.NODE_ENV !== 'production',
          debug: false,
          appId: resp.data.appId,
          timestamp: resp.data.timestamp,
          nonceStr: resp.data.nonceStr,
          signature: resp.data.signature,
          jsApiList: ['scanQRCode', 'previewImage', 'chooseImage', 'uploadImage', 'getLocalImgData', 'downloadImage']
        }
        wx.config(config)
        wx.error((res) => {
          alert('微信sdk错误：' + res.errMsg)
        })
        wx.ready(() => {
          this.ready = true
        })
      }
    })
  },
  startQRCode (callback) {
    if (this.ready === true) {
      wx.scanQRCode({
        needResult: 1,
        scanType: ['qrCode'],
        success: function (res) {
          callback(res)
        },
        error: function () {
          Message.error('打开微信扫一扫出错。')
        }
      })
    } else {
      Message.error('等待微信SDK初始化。')
    }
  },
  previewImage (url) {
    if (this.ready === true) {
      wx.previewImage({
        current: url,
        urls: [url]
      })
    } else {
      Message.error('等待微信SDK初始化。')
    }
  },
  chooseImage (callback) {
    if (this.ready === true) {
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['compressed'], // 可以指定是原图还是压缩图，默认二者都有（original）
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        defaultCameraMode: 'normal', // 表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。（注：用户进入拍照界面仍然可自由切换两种模式）
        isSaveToAlbum: 0, // 整型值，0表示拍照时不保存到系统相册，1表示自动保存，默认值是1
        success: function (back) {
          wx.getLocalImgData({
            localId: back.localIds[0], // 图片的localID
            success: function (res) {
              let localData = res.localData;
              if (localData.indexOf('data:image') !== 0) {
                localData = 'data:image/jpeg;base64,' + localData
              }
              callback(localData) // localData是图片的base64数据，可以用img标签显示
            }
          })
        }
      })
    }
  },
  scan (callback) {
    if (this.ready) {
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          const result = res.resultStr // 当needResult 为 1 时，扫码返回的结果
          callback(result)
        }
      })
    }
  }
}
