export default class SupplierProduceItem {
  constructor (data) {
    Object.assign(this, data)
  }

  getFirstImage () {
    const images = this.getImages()
    if (images.length > 0) {
      return images[0]
    } else {
      return null
    }
  }

  getImages () {
    return this.imgs ? this.imgs.split(',') : []
  }

  setImages (images) {
    if (images && images.length > 0) {
      this.imgs = images.join(',')
    } else {
      this.imgs = null
    }
  }
}
