/**
 * UI权限指令，决定用户是否能看到自己的UI。
 * 用法：
 * <div v-authority="$authority.headSummary"></div>
 */

import { userStore } from '@/store'

/**
 * 预定义的UI权限编号
 */
export const authorityMapping = {
}

export const hasAuthority = function (aid) {
  return (userStore.state.uiMap.pcu || []).includes(aid)
}

/**
 * 将权限指令以及预定义的权限编号注册到全局
 */
export const initAuthorityConstants = function (Vue) {

  // 注册权限指令
  Vue.directive('authority', {
    inserted (el, { value }) {
      const auth = userStore.state.uiMap.pcu || []
      if (!auth.includes(value)) {
        el.remove()
      }
    }
  })

  // 将预定义UI权限编号注册到全局
  Object.defineProperty(Vue.prototype, '$authority', {
    get () { return authorityMapping }
  })

  /**
   * 提供一个检查UI权限的工具函数。
   * if (this.$authorityCheck.check(this.$authority.headSummary)) {
   *   // do something
   * }
   */
  Object.defineProperty(Vue.prototype, '$authorityCheck', {
    get () {
      return {
        check: function (a) {
          return hasAuthority(a)
        }
      }
    }
  })
}
