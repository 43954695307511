import kit from '@/utils/kit'

export const LOGISTICS_COMPANY_YT = 'yt'
export const LOGISTICS_COMPANY_XP = 'ishipper'
export const LOGISTICS_COMPANY_FC = 'fucang'
export const LOGISTICS_COMPANY_YW = 'yanwen'
export const LOGISTICS_COMPANY_YHT = 'yihaitong'

export const LOGISTICS_COMPANY_MAPPING = {
  [LOGISTICS_COMPANY_YT]: '云途',
  [LOGISTICS_COMPANY_XP]: '夏普',
  [LOGISTICS_COMPANY_YW]: '燕文',
  [LOGISTICS_COMPANY_FC]: '美国福仓',
  [LOGISTICS_COMPANY_YHT]: '一海通'
}

export const LOGISTICS_COMPANY_LIST = kit.obj.toArray(LOGISTICS_COMPANY_MAPPING)
