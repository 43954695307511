<template>
  <app-page :title="title">
    <iframe :src="trackUrl"></iframe>
  </app-page>
</template>

<script>
export default {
  computed: {
    trackUrl () {
      return this.$route.query.url
    },
    title () {
      return this.$route.query.title
    }
  }
}
</script>

<style lang="less" scoped>
iframe {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: none;
  overflow: auto;
  width: 100%;
  height: 100%;
}
</style>
