<template>
  <div class="supplier-produce-item text-normal" @click="$emit('itemClick')">
    <div>
      <div class="image-item" :style="{'background-image': `url(${item.images[0]})`}" @click.prevent.stop="$emit('imageClick', item)"></div>
      <div class="status" :class="'status-' + item.status">{{statusMapping[item.status]}}</div>
      <div class="status status-customize" v-if="item.customizeInfo">定制</div>
    </div>
    <div class="main-content">
      <div class="left-right-content">
        <div class="full font-bold text-primary">规格：{{item.variant}}</div>
        <div>x <span class="font-large-x font-bold text-danger">{{item.quantity}}</span></div>
      </div>
      <div>
        <label>编号：</label>
        <span style="letter-spacing: 2px; color: #2b2b2b; font-size: 14px; font-weight: bold;">{{item.number}}</span>
      </div>
      <div>
        <label>SKU：</label>
        <span>{{item.sku}}</span>
      </div>
      <div>
        <label>日期：</label>
        <span>{{item.createTime}}</span>
      </div>
      <div v-if="!isSupplier">
        <label>供应商：</label>
        <span>{{item.supplierName}}</span>
      </div>
      <div class="font-bold font-large text-danger" v-if="timeoutFormat === 'timeoutAccept'">
        已过了{{item.dayNewToAccept}}天
      </div>
      <div class="font-bold font-large text-danger" v-else-if="timeoutFormat === 'timeoutDelivery'">
        已过了{{item.dayAcceptToDelivery}}天
      </div>
      <div class="left-right-content" v-else>
        <div v-if="item.dayNewToAccept > 0">
          <span v-if="item.confirmTime">接单用时：{{item.dayNewToAccept}}天</span>
          <span v-else class="text-danger">未接单（已过{{item.dayNewToAccept}}天）</span>
        </div>
        <div v-if="item.dayAcceptToDelivery > 0">
          <span v-if="item.deliveryTime">接单到发货用时：{{item.dayAcceptToDelivery}}天</span>
          <span v-else class="text-danger">未发货（已过{{item.dayAcceptToDelivery}}天）</span>
        </div>
      </div>
      <div class="left-right-content">
        <div>
          <span v-if="item.delay" class="text-danger">延迟处理</span>
        </div>
        <div>
          <span v-if="item.remark" class="font-bold font-large text-danger">订单备注</span>
        </div>
      </div>
      <div v-if="showStock" style="border-top: dashed 1px #d2d2d2; margin-top: 3px;">
        <div v-if="item.warehouseStockRecordId">
          {{item.stockQuantity}} 个入库到 {{item.warehouseName}}
        </div>
        <div v-else>没有入库信息</div>
      </div>
      <div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SUPPLIER_PRODUCE_ITEM_STATUS_MAPPING
} from '../../constants/supplier-produce-item-status'
import SupplierProduceItem from '../../model/supplier-produce-item'

export default {
  props: {
    item: { type: SupplierProduceItem, required: true },
    // 如何展示超时信息，timeoutAccept：展示接单超时信息，timeoutDelivery：展示发货超时信息，默认自动根据状态展示
    timeoutFormat: { type: String },
    showStock: { type: Boolean, default: false }
  },
  data () {
    return {
      statusMapping: SUPPLIER_PRODUCE_ITEM_STATUS_MAPPING
    }
  },
  computed: {
    isSupplier () {
      return this.$userStore.state.supplier
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.supplier-produce-item {
  padding: 10px;
  display: flex;
  font-size: 13px;
  background-color: #fff;
  &:active {
    background-color: #f2f2f2;
  }
  .main-content {
    flex: 1;
    margin-left: 15px;
  }
}
.supplier-produce-item + .supplier-produce-item {
  border-top: solid 1px #eee;
}

.status {
  margin-top: 6px;
  text-align: center;
  color: #fff;
  padding: 4px 6px;
  border-radius: 10px;
  font-size: 12px;
}
.status-new {
  background-color: #606266;
}
.status-accept {
  background-color: #3d71a7;
}
.status-reject {
  background-color: #bb3e30;
}
.status-delivery {
  background-color: #368e0c;
}
.status-return {
  background-color: #de8418;
}
.status-delete {
  background-color: #000000;
}

.status-customize {
  border: solid 1px #a738f6;
  color: #a738f6;
  background-color: #fff;
}
</style>
