<template>
  <div id="app">
    <router-view/>
    <product-image-preview ref="imgPreview"></product-image-preview>
    <iframe-page ref="iframePage"></iframe-page>
  </div>
</template>

<script>
import ProductImagePreview from '@/components/business/product-image-preview'
import IframePage from '@/components/iframe-page'
import { userStore } from '@/store'
import { configConnectHeaders } from '@/stomp/stomp'
import Vue from 'vue'

export default {
  components: {
    ProductImagePreview,
    IframePage
  },
  data () {
    return {
    }
  },
  computed: {
    isLogin () {
      return userStore.get('isLogin')
    }
  },
  watch: {
    isLogin: {
      handler: function (flag) {
        configConnectHeaders({
          'X-User-Id': flag ? userStore.state.token : null
        })
      },
      immediate: true
    }
  },
  mounted () {
    const productImagePreview = this.$refs.imgPreview
    Vue.use({
      install (Vue) {
        Object.defineProperties(Vue.prototype, {
          $productImagePreview: {
            get () {
              return productImagePreview
            }
          }
        })
      }
    })

    const iframePage = this.$refs.iframePage
    Vue.use({
      install (Vue) {
        Object.defineProperties(Vue.prototype, {
          $iframePage: {
            get () {
              return iframePage
            }
          }
        })
      }
    })
  }
}
</script>

<style lang="less">
@import "assets/less/hack";
@import "assets/less/vant-hack";
@import "assets/less/commons";
@import "assets/less/list-group";
html, body {
  margin: 0;
  padding: 0;
  background-color: #f6f6f6;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
</style>
