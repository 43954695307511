import defineApi from '../define-api'
import http from '../application-api'


export const saveShippingInfo = defineApi((config, data) => {
  config.data = data
  http.post('/setting/save/shipping_info', config)
})

export const getShippingInfo = defineApi((config) => {
  http.get('/setting/get/shipping_info', config)
})
