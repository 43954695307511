<template>
  <div style="border-bottom: solid 1px #f2f2f2;">
    <van-field
        readonly
        clickable
        :value="selectedOptionText"
        :label="label"
        :placeholder="placeholder"
        @click="showPicker = true"
        :error-message="errorMessage"
    >
      <template #button>
        <van-icon v-if="clearable"
                  v-show="hasSelected"
                  name="clear"
                  size="16"
                  color="#c9c9c9"
                  @click.stop.prevent="clear"
        />
      </template>
    </van-field>

    <editor :visible.sync="showPicker" :label="label" size="large" save-button-text="确定" @save="handleSave" @cancel="handleCancel">
      <van-field
          v-model="filterText"
          label="过滤"
          placeholder="请输入搜索关键词"
          clearable
      >
        <template #button>
          <van-button size="small" type="primary" @click="handleFilter">搜索</van-button>
        </template>
      </van-field>
      <div class="list-options" style="margin-top: 15px;">
        <div class="option-item"
             v-for="item in filterOptions"
             :key="item[idName]"
             :class="{'selected': selectedListKeys.includes(item[idName])}"
             @click="handleItemClick(item)"
        >
          {{item[textName]}}<span v-if="item.top === true" style="font-size: 12px; color: #bb3e30; margin-left: 10px;">{{topLabel}}</span>
        </div>
      </div>
    </editor>

  </div>
</template>

<script>
import kit from '@/utils/kit'
export default {
  name: 'list-option-picker',
  props: {
    options: { type: Array, default: function () { return [] } },
    idName: { type: String, default: 'id' },
    textName: { type: String, default: 'name' },
    topLabel: { type: String, default: '常用' },
    label: { type: String, required: true },
    placeholder: { type: String, default: '' },
    errorMessage: { type: String },
    selectedKey: { type: [String, Array] },
    clearable: { type: Boolean, default: false },
    multiSelect: { type: Boolean, default: false }
  },
  model: {
    prop: 'selectedKey',
    event: 'change'
  },
  data () {
    return {
      showPicker: false,
      clickKey: null,
      selectedListKeys: [],
      filterText: null,
      filterOptions: []
    }
  },
  computed: {
    optionTextList () {
      return this.options.map(item => item[this.textName])
    },
    selectedOptionText () {
      if (this.multiSelect) {
        return this.options
          .filter(item => this.selectedKey.includes(item[this.idName]))
          .map(item => item[this.textName])
          .join('，')
      } else {
        const opt = this.options.find(item => item[this.idName] === this.selectedKey)
        return opt ? opt[this.textName] : null
      }
    },
    hasSelected () {
      if (this.multiSelect) {
        return this.selectedKey.length > 0
      } else {
        return !!this.selectedKey
      }
    }
  },
  watch: {
    selectedKey: {
      immediate: true,
      handler (key) {
        if (this.multiSelect) {
          this.selectedListKeys = [...this.selectedKey]
        } else {
          if (this.selectedKey) {
            this.selectedListKeys = [this.selectedKey]
          }
        }
      }
    },
    showPicker (v) {
      if (v) {
        this.filterOptions = this.options
      }
    }
  },
  methods: {
    handleSave () {
      if (this.multiSelect) {
        this.$emit('change', this.selectedListKeys)
      } else {
        this.$emit('change', this.selectedListKeys[0])
      }
      this.showPicker = false
    },
    handleCancel () {
      if (this.multiSelect) {
        this.selectedListKeys = [...this.selectedKey]
      } else {
        if (this.selectedKey) {
          this.selectedListKeys = [this.selectedKey]
        }
      }
      this.filterText = null
    },
    clear () {
      this.$emit('change', this.multiSelect ? [] : null)
    },
    handleFilter () {
      if (this.filterText && this.filterText.length > 0) {
        this.filterOptions = this.options.filter(item => item[this.textName].includes(this.filterText))
      } else {
        this.filterOptions = this.options
      }
    },
    handleItemClick (item) {
      if (this.selectedListKeys.includes(item[this.idName])) {
        kit.arr.removeItem(this.selectedListKeys, item[this.idName])
      } else {
        if (!this.multiSelect) {
          this.selectedListKeys = []
        }
        this.selectedListKeys.push(item[this.idName])
      }
    }
  }
}
</script>

<style lang="less" scoped>
.option-item {
  padding: 10px 10px;
  border-bottom: solid 1px #f2f2f2;
  font-size: 14px;
  &:nth-child(odd) {
    background-color: #f6f6f6;
  }
  &:active {
    background-color: #f9f9f9;
  }
  &.selected {
    background-color: #368e0c;
    color: #fff;
  }
}
</style>
