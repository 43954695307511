<template>
  <app-page title="发货地址">
    <van-notice-bar wrapable :scrollable="false">
      发货地址信息用于在填写发货信息时，自动填充到"发货人"表单区域中。
    </van-notice-bar>

    <div>
      <van-field v-model="form.country" label="国家" readonly clearable />
      <van-field v-model="form.state" label="省份 / 州" clearable />
      <van-field v-model="form.city" label="城市" clearable />
      <van-field v-model="form.address" label="地址" clearable />
      <van-field v-model="form.postalCode" label="邮政编号" clearable />
      <van-field v-model="form.name" label="姓名" clearable />
      <van-field v-model="form.phone" label="联系电话" clearable />
      <van-field v-model="form.company" label="公司" clearable />
    </div>
    <div style="padding: 10px;">
      <van-button type="info" block @click="handleSave" :loading="saving">保存</van-button>
    </div>
  </app-page>
</template>

<script>
import { saveShippingInfo, getShippingInfo } from '../../http/api/setting'

export default {
  data () {
    return {
      saving: false,
      form: {
        countryCode: 'CN',
        country: '中国',
        stateCode: null,
        state: '广东',
        city: null,
        address: null,
        postalCode: null,
        name: null,
        phone: null,
        company: null
      }
    }
  },
  methods: {
    handleSave () {
      this.saving = true
      saveShippingInfo()
        .complete(() => (this.saving = false))
        .success(() => {
          this.$message.success('保存成功。')
        })
        .send(this.form)
    }
  },
  mounted () {
    this.$loading.open()
    getShippingInfo()
      .complete(() => (this.$loading.close()))
      .success(resp => {
        const cc = this.form.countryCode
        const cn = this.form.country
        Object.assign(this.form, resp.data)
        this.form.countryCode = cc
        this.form.country = cn
      })
      .send()
  }
}
</script>

<style scoped>

</style>
