<template>
  <app-page :title="title">
    <van-pull-refresh v-model="loading" @refresh="handleReload">
      <div class="info-detail" style="margin: 10px;" v-if="produceItem">
        <div class="image-list">
          <div class="image-item small"
               v-for="(img, index) in produceItem.images"
               :key="index"
               :style="{'background-image': `url(${img})`}"
               @click="$productImagePreview.open(produceItem)"
          >
          </div>
        </div>
        <div class="detail-item">
          <label>规格</label>
          <span>{{produceItem.variant}}</span>
        </div>
        <div class="detail-item">
          <label>数量</label>
          <span class="font-bold" style="color: #bb3e30;">x {{produceItem.quantity}}</span>
        </div>
        <div class="detail-item">
          <label>备注</label>
          <span style="color: #bb3e30">{{produceItem.remark}}</span>
        </div>
        <div class="detail-item" v-if="produceItem.skuRemark">
          <label>备注2</label>
          <span style="color: #bb3e30">{{produceItem.skuRemark}}</span>
        </div>
        <div class="detail-item" v-if="produceItem.customizeInfo">
          <label>定制</label>
          <span @click="copyCustomizeInfo(produceItem.customizeInfo)">
            <div v-for="(item, index) in splitCustomize(produceItem.customizeInfo)" :key="index" class="customize-item">
              {{item.name}}：{{item.value}} ({{item.unit}})
            </div>
          </span>
        </div>
        <div class="detail-item">
          <label>供应商</label>
          <span>{{produceItem.supplierName}}</span>
        </div>
        <div class="detail-item">
          <label>下单人</label>
          <span>{{produceItem.adminName}}</span>
        </div>
        <div class="detail-item">
          <label>创建时间</label>
          <span>{{produceItem.createTime}}</span>
        </div>
        <div class="detail-item">
          <label>接单时间</label>
          <span>
            {{produceItem.confirmTime}}
            <span v-if="produceItem.dayNewToAccept >= 0">
              <span v-if="produceItem.dayNewToAccept === 0" class="text-success">（当天）</span>
              <span v-else-if="produceItem.confirmTime" class="text-danger">（用时{{produceItem.dayNewToAccept}}天）</span>
              <span v-else class="text-danger">（已过{{produceItem.dayNewToAccept}}天）</span>
            </span>
          </span>
        </div>
        <div class="detail-item">
          <label>发货时间</label>
          <span>
            {{produceItem.deliveryTime}}
            <span v-if="produceItem.dayAcceptToDelivery >= 0">
              <span v-if="produceItem.dayAcceptToDelivery === 0" class="text-success">（当天）</span>
              <span v-else-if="produceItem.deliveryTime" class="text-danger">（用时{{produceItem.dayAcceptToDelivery}}天）</span>
              <span v-else class="text-danger">（已过{{produceItem.dayAcceptToDelivery}}天）</span>
            </span>
          </span>
        </div>
        <div class="detail-item">
          <label>签收时间</label>
          <span>{{produceItem.arrivedTime}}</span>
        </div>
        <div class="detail-item">
          <label>状态</label>
          <span>
            <div class="status" :class="'status-' + produceItem.status">{{statusMapping[produceItem.status]}}</div>
          </span>
        </div>
        <div class="detail-item">
          <label>延迟生产</label>
          <span>
            <span v-if="produceItem.delay" class="font-bold text-danger">延迟</span>
            <span v-else class="text-success">正常生产</span>
          </span>
          <span style="text-align: right;" v-if="produceItem.status === statusKeys.accept && isSupplier">
            <span style="font-size: 13px; color: #3d71a7;" @click="$refs.delayEditor.open(produceItem)">设置延迟</span>
          </span>
        </div>
        <div class="detail-item" v-if="produceItem.delay">
          <label>延迟原因</label>
          <span>{{produceItem.delayReason}}</span>
        </div>
      </div>
    </van-pull-refresh>


    <van-tabs v-model="tabName" animated style="margin-top: 30px;background-color: #fff;">
      <van-tab title="物流信息" name="logistics">
        <shipment :item-id="itemId"></shipment>
      </van-tab>
      <van-tab title="历史状态" name="status">
        <status-history :item-id="itemId"></status-history>
      </van-tab>
    </van-tabs>

    <delay-editor ref="delayEditor" @saved="handleDelayUpdate"></delay-editor>
  </app-page>
</template>

<script>
import { getItemById } from '../../../http/api/supplier-produce-item'
import SupplierProduceItemModel from '../../../model/supplier-produce-item'
import {
  SUPPLIER_PRODUCE_ITEM_STATUS_MAPPING,
  SUPPLIER_PRODUCE_ITEM_STATUS_ACCEPT
} from '../../../constants/supplier-produce-item-status'
import StatusHistory from './status-history'
import Shipment from './shipment'
import DelayEditor from './delay-editor'
import CustomizeMixin from '../../../mixins/customize'

export default {
  components: { StatusHistory, DelayEditor, Shipment },
  mixins: [CustomizeMixin],
  data () {
    return {
      loading: false,
      produceItem: null,
      statusMapping: SUPPLIER_PRODUCE_ITEM_STATUS_MAPPING,
      statusKeys: {
        accept: SUPPLIER_PRODUCE_ITEM_STATUS_ACCEPT
      },
      tabName: 'logistics'
    }
  },
  computed: {
    title () {
      let t = '供应商订单详情'
      if (this.produceItem) {
        t += ' - ' + this.produceItem.number
      }
      return t
    },
    itemId () {
      return this.$route.params.itemId
    },
    images () {
      return this.produceItem ? this.produceItem.images : []
    },
    isSupplier () {
      return this.$userStore.state.supplier
    }
  },
  watch: {
  },
  methods: {
    handleDelayUpdate (data) {
      Object.assign(this.produceItem, data)
    },
    handleReload () {
      this.loading = true
      getItemById()
        .complete(() => (this.loading = false))
        .success(resp => {
          this.produceItem = new SupplierProduceItemModel(resp.data)
        })
        .send(this.itemId)
    }
  },
  mounted () {
    this.handleReload()
  }
}
</script>

<style lang="less" scoped>
.detail-item {
  font-size: 16px !important;
}

.customize-item {
  color: #a738f6;
}

.image-list {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  .image-item {
    margin-bottom: 10px;
  }
}

.status {
  display: inline-block;
  text-align: center;
  color: #fff;
  padding: 4px 10px;
  border-radius: 10px;
  font-size: 12px;
}
.status-new {
  background-color: #606266;
}
.status-accept {
  background-color: #3d71a7;
}
.status-reject {
  background-color: #bb3e30;
}
.status-delivery {
  background-color: #368e0c;
}
.status-return {
  background-color: #de8418;
}
.status-delete {
  background-color: #000000;
}
</style>
