import {
  ORDER_PAY_STATUS_PARTIALLY,
  ORDER_PAY_STATUS_PAY,
  ORDER_PAY_STATUS_REFUND_PARTIALLY,
  ORDER_PAY_STATUS_REFUND,
  ORDER_SHIP_STATUS_ARRIVED,
  ORDER_SHIP_STATUS_SEND,
  ORDER_SHIP_STATUS_WAIT,
  ORDER_STATUS_COMPLETE,
  ORDER_STATUS_NEW
} from '../../constants/order-status'

export default function (type) {
  let status = []; let statusPay = []; let statusShipping = []
  let dayNewToDelivery = null
  let allShip = null
  if (type === 'paid' || type === 'noProduce') {
    status = [ORDER_STATUS_NEW]
    statusPay = [ORDER_PAY_STATUS_PAY, ORDER_PAY_STATUS_PARTIALLY, ORDER_PAY_STATUS_REFUND_PARTIALLY]
    statusShipping = [ORDER_SHIP_STATUS_WAIT]
  } else if (type === 'shipped') {
    status = [ORDER_STATUS_NEW, ORDER_STATUS_COMPLETE]
    statusPay = [ORDER_PAY_STATUS_PAY, ORDER_PAY_STATUS_PARTIALLY, ORDER_PAY_STATUS_REFUND_PARTIALLY, ORDER_PAY_STATUS_REFUND]
    statusShipping = [ORDER_SHIP_STATUS_SEND, ORDER_SHIP_STATUS_ARRIVED]
  } else if (type === 'shippedPart') {
    status = [ORDER_STATUS_NEW, ORDER_STATUS_COMPLETE]
    statusPay = [ORDER_PAY_STATUS_PAY, ORDER_PAY_STATUS_PARTIALLY, ORDER_PAY_STATUS_REFUND_PARTIALLY, ORDER_PAY_STATUS_REFUND]
    statusShipping = [ORDER_SHIP_STATUS_SEND, ORDER_SHIP_STATUS_ARRIVED]
    allShip = false
  } else if (type === 'timeout') {
    status = [ORDER_STATUS_NEW]
    statusPay = [ORDER_PAY_STATUS_PAY, ORDER_PAY_STATUS_PARTIALLY, ORDER_PAY_STATUS_REFUND_PARTIALLY]
    statusShipping = [ORDER_SHIP_STATUS_WAIT]
    dayNewToDelivery = 3
  }
  const p = {
    status: status,
    statusPay: statusPay,
    statusShipping: statusShipping,
    dayNewToDelivery: dayNewToDelivery,
    allShip: allShip,
    orderNo: null,
    email: null,
    createTimeStart: null,
    createTimeEnd: null,
    shopId: null,
    produceItemDeliveryTrackNo: null,
    orderByName: 'create_time',
    orderByDirection: 'desc'
  }

  if (type === 'noProduce') {
    p.produceItemTotalStatus = 'none'
  }
  return p
}
