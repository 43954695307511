import { Toast } from 'vant'


let loadingInstance = null

export const loading = {
  open (text) {
    loadingInstance = Toast.loading({
      duration: 0, // continuous display toast
      forbidClick: true,
      message: text
    })
  },
  close () {
    loadingInstance.clear()
  }
}

export default {
  install (Vue) {
    Object.defineProperties(Vue.prototype, {
      $loading: {
        get () {
          return loading
        }
      }
    })
  }
}
