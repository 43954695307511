import kit from '@/utils/kit'

export const USER_TYPE_ADMIN = 'admin'
export const USER_TYPE_BM = 'bm'
export const USER_TYPE_3C = '3c'
export const USER_TYPE_SUPPLIER = 'supplier'

export const USER_TYPE_MAPPING = {
  [USER_TYPE_ADMIN]: '管理员',
  [USER_TYPE_BM]: '店铺运营',
  [USER_TYPE_3C]: '3C运营',
  [USER_TYPE_SUPPLIER]: '供应商'
}

export const USER_TYPE_LIST = kit.obj.toArray(USER_TYPE_MAPPING)
