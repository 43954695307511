export const ROUTE_HOME = 'home'
export const ROUTE_LOGIN = 'login'
export const ROUTE_404 = 'not-found'

export const ROUTE_SUPPLIER = 'supplier'
export const ROUTE_SUPPLIER_USER = 'supplier-user'
export const ROUTE_SHOP = 'shop'
export const ROUTE_ORDER_DETAIL = 'order-detail'
export const ROUTE_ORDER_PRODUCE_ITEMS = 'order-produce-items'
export const ROUTE_ORDER_DELIVERY = 'order-delivery'
export const ROUTE_ALL_ORDER_DELIVERY = 'all-order-delivery'
export const ROUTE_ORDER_PRODUCE_ITEM_DETAIL = 'order-produce-item-detail'
export const ROUTE_SUPPLIER_PRODUCE_ITEM_DETAIL = 'supplier-produce-item-detail'

export const ROUTE_MY = 'my'

export const ROUTE_ORDER_DELIVERY_TRACKING = 'order-delivery-tracking'
export const ROUTE_ALL_ORDER_DELIVERY_TRACKING = 'all-order-delivery-tracking'
export const ROUTE_PRODUCE_ITEM_TRACKING = 'tracking-info'

export const ROUTE_SETTING_SHIPPING_INFO = 'setting-shipping-info'

export const ROUTE_SHARE_PRODUCE_ITEMS = 'share-produce-items'

export const ROUTE_SUPPLIER_SHIPMENT_ITEM = 'supplier-shipment-item'
export const ROUTE_SUPPLIER_SHIPMENT_ITEM_TRACKING = 'supplier-shipment-item-tracking'
