import { ImagePreview } from 'vant'

const _instance = {
  show (images, startPosition) {
    if (!Array.isArray(images)) {
      images = [images]
    }
    ImagePreview({
      images: images,
      closeable: true,
      startPosition: startPosition || 0,
      loop: false
    })
  }
}

export default {
  install (Vue) {
    Object.defineProperties(Vue.prototype, {
      $imagePreview: {
        get () {
          return _instance
        }
      }
    })
  }
}
