/*
    对系统各页面进行路由配置，路由必须都提供唯一的名称，路由跳转建议使用name而不是path。
    每个相关模块的路由都新建一个配置文件在./config目录下，然后在本文件集中配置。
    /////////////////////////////////////
    /////////  路由的特殊设置  ////////////
    /////////////////////////////////////
    安全验证：
    ------------------------
    默认所有路由都是需要验证用户是否登录，如果你需要将某个路由跳过安全验证，请做如下配置：
    meta: {auth: false}

    路由访问：
    ------------------------
    有时候用户第一次打开系统时就直接访问深度路由，如：#/a/b/c，该路由前面还有两级父路由#/a和#/a/b，如果
    你不希望某个路由可以直接被用户打开，而是必须从它的父路由过来的，你可以做如下设置：
    meta: {fromParent: true}
    比如，用户必须在#/a/b跳转到#/a/b/c，如果从其他路由如：#/1/2 -> #/a/b/c 或者在浏览器直接打开#/a/b/c
    的话，页面就会被跳转到#/a/b/c的上一级路由，也就是#/a/b
 */

import Vue from 'vue'
import Router from 'vue-router'
import Security from './router-security'
import RouterBefore from './router-before'
import {
  ROUTE_HOME,
  ROUTE_404,
  ROUTE_LOGIN,
  ROUTE_SUPPLIER_USER,
  ROUTE_SHOP, ROUTE_ORDER_DETAIL,
  ROUTE_ORDER_PRODUCE_ITEMS,
  ROUTE_ORDER_DELIVERY,
  ROUTE_ALL_ORDER_DELIVERY,
  ROUTE_ORDER_PRODUCE_ITEM_DETAIL,
  ROUTE_SUPPLIER_PRODUCE_ITEM_DETAIL,
  ROUTE_MY,
  ROUTE_ORDER_DELIVERY_TRACKING,
  ROUTE_ALL_ORDER_DELIVERY_TRACKING,
  ROUTE_PRODUCE_ITEM_TRACKING,
  ROUTE_SETTING_SHIPPING_INFO,
  ROUTE_SHARE_PRODUCE_ITEMS,
  ROUTE_SUPPLIER_SHIPMENT_ITEM,
  ROUTE_SUPPLIER_SHIPMENT_ITEM_TRACKING
} from './router-constants'

import Home from '../pages/home'
import NotFound from '../pages/404'
import Login from '../pages/login'
import SupplierUser from '../pages/supplier/user'
import Shop from '../pages/shop/shop'
import OrderDetail from '../pages/order/order-detail'
import OrderProduceItems from '../pages/order/order-produce-items'
import OrderDelivery from '../pages/order/order-delivery'
import AllOrderDelivery from '../pages/order/all-order-delivery'
import ProduceItemDetail from '../pages/supplier/produce-item/detail'
import My from '../pages/my'
import TrackingInfo from '../pages/tracking-info'
import SettingShippingInfo from '../pages/setting/shipping-info'
import ShareProduceItems from '../pages/share-produce-items'
import SupplierShipmentItem from '../pages/supplier/produce-item/supplier-shipment-item'

Vue.use(Router)

let router = new Router({
  // 用到了微信jssdk，因此使用默认的hash模式，否则ios下会出现签名失败
  // mode: 'history',
  routes: [
    { name: 'Root', path: '/', redirect: { name: ROUTE_HOME } },
    { name: ROUTE_404, path: '/404', component: NotFound, meta: { auth: false } },
    { name: ROUTE_LOGIN, path: '/login', component: Login, meta: { auth: false } },
    { name: ROUTE_SHARE_PRODUCE_ITEMS, path: '/share/produce_item/:id', component: ShareProduceItems, meta: { auth: false } },
    {
      name: ROUTE_HOME,
      path: '/h',
      component: Home,
      children: [
        { name: ROUTE_SUPPLIER_USER, path: 'supplier/:id/user', component: SupplierUser },
        { name: ROUTE_SHOP, path: 'shop/:id', component: Shop },
        {
          name: ROUTE_ORDER_DETAIL,
          path: 'order/:id',
          component: OrderDetail,
          children: [
            {
              name: ROUTE_ORDER_PRODUCE_ITEMS,
              path: 'produce_items',
              component: OrderProduceItems,
              children: [
                {
                  name: ROUTE_ORDER_PRODUCE_ITEM_DETAIL,
                  path: ':itemId/detail',
                  component: ProduceItemDetail
                }
              ]
            },
            {
              name: ROUTE_ORDER_DELIVERY,
              path: 'delivery',
              component: OrderDelivery,
              children: [
                {
                  name: ROUTE_ORDER_DELIVERY_TRACKING,
                  path: 'tracking',
                  component: TrackingInfo
                }
              ]
            }
          ]
        },
        {
          name: ROUTE_ALL_ORDER_DELIVERY,
          path: 'order_delivery',
          component: AllOrderDelivery,
          children: [
            {
              name: ROUTE_ALL_ORDER_DELIVERY_TRACKING,
              path: 'tracking',
              component: TrackingInfo
            }
          ]
        },
        {
          name: ROUTE_SUPPLIER_PRODUCE_ITEM_DETAIL,
          path: 'supplier_produce_item/:itemId/detail',
          component: ProduceItemDetail,
          children: [
            {
              name: ROUTE_PRODUCE_ITEM_TRACKING,
              path: 'tracking',
              component: TrackingInfo
            }
          ]
        },
        {
          name: ROUTE_MY,
          path: 'my',
          component: My
        },
        {
          name: ROUTE_SETTING_SHIPPING_INFO,
          path: 'setting/shipping-info',
          component: SettingShippingInfo
        },
        {
          name: ROUTE_SUPPLIER_SHIPMENT_ITEM,
          path: 'supplier_shipment_item/:trackNo',
          component: SupplierShipmentItem
        },
        {
          name: ROUTE_SUPPLIER_SHIPMENT_ITEM_TRACKING,
          path: 'supplier_shipment_item_tracking',
          component: TrackingInfo
        }
      ]
    }
  ]
})

router = Security(router)
router = RouterBefore(router)

export default router
