import kit from '@/utils/kit'

export const SHOP_TYPE_SHOPIFY = 'shopify'
export const SHOP_TYPE_MY = 'my'

export const SHOP_TYPE_MAPPING = {
  [SHOP_TYPE_SHOPIFY]: 'Shopify',
  [SHOP_TYPE_MY]: '自建站'
}

export const SHOP_TYPE_LIST = kit.obj.toArray(SHOP_TYPE_MAPPING)
