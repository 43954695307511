<template>
  <div class="container">
    <h1>{{appName}} - 登录</h1>

    <van-form @submit="login">
      <van-field
          v-model="form.username"
          clearable
          name="username"
          label="手机号码"
          :rules="[{ required: true, message: '必填' }]"
      />

      <van-field
          v-model="form.smsCode"
          center
          clearable
          label="短信验证码"
          :rules="[{ required: true, message: '必填' }]"
      >
        <template #button>
          <van-button size="small"
                      type="primary"
                      @click.stop.prevent="handleGetCode"
                      :loading="smsCode.loading"
                      :disabled="smsCode.disabled"
          >
            {{smsCode.buttonText}}
          </van-button>
        </template>
      </van-field>

      <div style="margin-top: 20px;" class="left-right-content">
        <div style="padding: 0 5px 0 10px;">
          <van-button :loading="loading" block type="primary" @click="handleNavWeChatLogin">微信登录</van-button>
        </div>
        <div style="padding: 0 10px 0 5px;">
          <van-button :loading="loading" block type="info" native-type="submit">手机登录</van-button>
        </div>
      </div>
    </van-form>

    <editor title="微信登录绑定手机号码"
            :visible.sync="wechatBind.visible"
            :saving="loading"
            @save="handleWeChatBind"
            save-button-text="确定"
    >
      <div>
        <van-field
            v-model="wechatBind.phone"
            clearable
            name="username"
            label="手机号码"
            placeholder="请输入手机号码"
            :rules="[{ required: true, message: '必填' }]"
        />
      </div>
    </editor>

  </div>
</template>

<script>
import { login, wechatBind, wechatLogin, getLoginCode } from '../http/api/admin'
import { ROUTE_HOME } from '../router/router-constants'
import router from '../router'

export default {
  data () {
    return {
      form: {
        username: null,
        smsCode: null
      },
      loading: false,
      wechatBind: {
        visible: false,
        phone: null
      },
      smsCode: {
        loading: false,
        disabled: false,
        countdown: 60,
        buttonText: '获取验证码',
        timerClear: null
      }
    }
  },
  computed: {
    appName () {
      return this.$appStore.state.name
    },
    wechatAction () {
      return this.$route.query.wechat
    },
    wechatOpenId () {
      return this.$route.query.openId
    }
  },
  methods: {
    login () {
      this.loading = true
      login()
        .complete(() => (this.loading = false))
        .success(() => {
          localStorage.setItem('username', this.username)
          if (this.$route.query.from) {
            this.$router.push(this.$route.query.from)
          } else {
            this.$router.push({ name: ROUTE_HOME })
          }
        })
        .send(this.form.username, this.form.smsCode)
    },
    handleWeChatBind () {
      wechatBind()
        .complete(() => (this.loading = false))
        .validationFail(resp => {
          if (resp.details && resp.details.length > 0) {
            let msg = ''
            for (const d of resp.details) {
              msg += d.message + '\n'
            }
            this.$message.error(msg)
          }
        })
        .success(() => {
          this.$router.push({ name: ROUTE_HOME })
        })
        .send(this.wechatOpenId, this.wechatBind.phone)
    },
    wechatLogin () {
      this.loading = true
      wechatLogin()
        .complete(() => (this.loading = false))
        .success(() => {
          this.$router.push({ name: ROUTE_HOME })
        })
        .send(this.wechatOpenId)
    },
    handleNavWeChatLogin () {
      window.location = '/api/backend/wechat/auth?redirect_path=' + encodeURIComponent(this.getCurrentUrlPath())
    },
    handleGetCode () {
      if (this.smsCode.loading || this.smsCode.disabled) {
        return
      }
      this.smsCode.loading = true
      this.smsCode.countdown = 60
      getLoginCode()
        .complete(() => (this.smsCode.loading = false))
        .success(() => {
          this.$message.success('验证码已经发到你手机上，请注意查收。')
          this.smsCode.disabled = true
          this.smsCode.buttonText = `重新发送（${this.smsCode.countdown}）`
          this.smsCode.timerClear = setInterval(() => {
            this.smsCode.countdown--
            if (this.smsCode.countdown < 0) {
              clearInterval(this.smsCode.timerClear)
              this.smsCode.buttonText = '获取验证码'
              this.smsCode.disabled = false
            } else {
              this.smsCode.buttonText = '获取验证码'
              this.smsCode.buttonText = `重新发送（${this.smsCode.countdown}）`
            }
          }, 1000)
        })
        .send(this.form.username)
    },
    getCurrentUrlPath () {
      let path
      if (router.mode === 'hash') {
        path = window.location.pathname + window.location.hash
      } else {
        path = window.location.pathname
      }
      if (path.includes('?')) {
        return path.substring(0, path.indexOf('?'))
      } else {
        return path
      }
    }
  },
  mounted () {
    this.username = localStorage.getItem('username')
    if (this.wechatAction === 'bind') {
      this.wechatBind.visible = true
      this.wechatBind.phone = this.form.username
    } else if (this.wechatAction === 'login') {
      this.wechatLogin()
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding: 10px 0;
}
h1 {
  text-align: center;
  color: #2066b4;
  font-size: 22px;
}
</style>
