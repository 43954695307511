import defineApi from '../define-api'
import http from '../application-api'

export const listStockProducts = defineApi((config, params) => {
  config.params = params
  http.get('/stock/list', config)
})

/**
 * @param sku 多个使用逗号分割
 * @param [warehouseId]
 */
export const getStockBySku = defineApi((config, sku, warehouseId) => {
  config.params = { sku: sku, warehouseId: warehouseId }
  http.get('/stock/sku/stock', config)
})

