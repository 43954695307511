import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './vant-ui-import'

import AppPage from '@/components/app-page'
import Editor from '@/components/editor'
import OptionPicker from '@/components/option-picker'
import ListOptionPicker from '@/components/list-option-picker'
import EmptyData from '@/components/empty-data'
import Message from '@/components/message'
import Loading from '@/components/loading'
import FakeSearch from '@/components/fake-search'
import DatePicker from '@/components/date-picker'
import ImagePreview from '@/components/image-preview'
import { initAuthorityConstants } from './directives/authority'

Vue.component(AppPage.name, AppPage)
Vue.component(Editor.name, Editor)
Vue.component(OptionPicker.name, OptionPicker)
Vue.component(ListOptionPicker.name, ListOptionPicker)
Vue.component(EmptyData.name, EmptyData)
Vue.component(FakeSearch.name, FakeSearch)
Vue.component(DatePicker.name, DatePicker)
Vue.use(Message)
Vue.use(Loading)
Vue.use(ImagePreview)

Vue.config.productionTip = false

initAuthorityConstants(Vue)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
