import { Notify, Dialog } from 'vant'
export const Message = {
  success (message) {
    Notify({ type: 'success', message: message })
  },
  error (message) {
    Notify({ type: 'danger', message: message, duration: 2000 })
  },
  /**
   * this.$message.alert('hello').then(() => ())
   * @param title
   * @param message
   * @return {*}
   */
  alert (title, message) {
    if (message === undefined) {
      message = title
      title = null
    }
    return Dialog.alert({
      title: title,
      message: message,
      theme: 'round-button'
    })
  },
  confirm (title, message, onOk, onCancel) {
    Dialog.confirm({
      title: title,
      message: message
    })
      .then(() => {
        onOk && onOk()
      })
      .catch(() => {
        onCancel && onCancel()
      })
  }
}

export default {
  install (Vue) {
    Object.defineProperties(Vue.prototype, {
      $message: {
        get () {
          return Message
        }
      }
    })
  }
}
