import defineApi from '../define-api'
import http from '../application-api'

export const listByOrder = defineApi((config, orderId) => {
  config.params = {
    orderId: orderId
  }
  http.get('/order/delivery/list/by_order', config)
})

export const list = defineApi((config, params) => {
  config.params = params
  http.get('/order/delivery/list', config)
})

export const getShippingMethods = defineApi((config, companyId) => {
  config.params = {
    companyId: companyId
  }
  http.get('/order/delivery/shipping_methods', config)
})

export const getCountries = defineApi((config) => {
  http.get('/order/delivery/countries', config)
})

export const delivery = defineApi((config, data) => {
  config.data = data
  http.post('/order/delivery/ship', config)
})

export const uploadImageByDelivery = defineApi((config, imageBase64) => {
  config.params = {
    base64: imageBase64
  }
  http.post('/order/delivery/ship/upload/image', config)
})

export const cancelDelivery = defineApi((config, id) => {
  config.params = { id: id }
  http.post('/order/delivery/ship/cancel', config)
})

export const printLabel = defineApi((config, idList) => {
  config.params = {
    ids: idList
  }
  http.get('/order/delivery/print/label', config)
})

export const getProducts = defineApi((config, orderId) => {
  config.params = { orderId: orderId }
  http.get('/order/delivery/products', config)
})

/**
 */
export const getFeeSummary = defineApi((config, p) => {
  config.params = p
  http.get('/order/delivery/fee/summary', config)
})

export const getCountWithExceptionAndUnArrive = defineApi((config, days) => {
  config.params = {
    days: days
  }
  http.get('/order/delivery/count/exception_and_un_arrive', config)
})
