<template>
  <delivery v-if="initPage"></delivery>
</template>

<script>
import Delivery from './order/all-order-delivery'
export default {
  components: { Delivery },
  data () {
    return {
      initPage: false
    }
  },
  methods: {
    onTabFirstView () {
      this.initPage = true
    },
    onTabViewVisible () {
    },
    onTabViewInvisible () {
    }
  }
}
</script>

<style scoped>

</style>
