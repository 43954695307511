<template>
  <app-page :use-back-icon="false" title="待发货">
    <van-pull-refresh v-model="loading" @refresh="handleReload">
      <empty-data v-if="dataList.length === 0" />

      <div style="min-height: 300px;">
        <van-swipe-cell
            v-for="item in dataList"
            :key="item.id"
        >
          <supplier-produce-item
              :item="item"
              @imageClick="$productImagePreview.open(item)"
          >
            <div style="display: flex; align-items: center; justify-content: flex-end;">
              <div class="image-item"
                   v-if="item.deliveryImageUrl"
                   @click.stop.prevent="$imagePreview.show([item.deliveryImageUrl])"
                   style="width: 32px;height: 32px;display: inline-block; margin-right: 10px; border-color: #2584d7;"
                   :style="{'background-image': `url(${item.deliveryImageUrl})`}"></div>
              <van-button size="small" type="info" @click.stop.prevent="takeImage(item)" :loading="item._uploading">
                拍照留档
              </van-button>
            </div>
          </supplier-produce-item>
          <template #right>
            <van-button square type="danger" text="删除" @click="handleRemove(item)" />
          </template>
        </van-swipe-cell>

      </div>
    </van-pull-refresh>

    <div slot="footer" v-show="dataList.length > 0">
      <van-field v-model="delivery.trackNo" label="运单号" placeholder="请输入运单号" :clearable="true">
        <template #button>
          <van-icon name="scan" size="20" @click="handleScanTrackNo" />
        </template>
      </van-field>
      <option-picker v-model="delivery.logisticsCode" label="物流公司" :options="logisticsOptions"></option-picker>
      <van-field v-if="delivery.logisticsCode === 'other'" v-model="delivery.logisticsName" label="物流名称" placeholder="请输入物流公司名称"/>
      <van-button type="info" block @click="handleDelivery" :loading="buttonLoading">发货（{{dataList.length}}）</van-button>
    </div>
  </app-page>
</template>

<script>
import SupplierProduceItemModel from '@/model/supplier-produce-item'
import SupplierProduceItem from '@/components/business/supplier-produce-item'
import { listProduceItem, delivery, uploadImage, removeFromDeliveryList } from '../http/api/delivery-produce-item'
import WeChatUtil from '../utils/wechat'
import kit from '@/utils/kit'

export default {
  components: { SupplierProduceItem },
  data () {
    return {
      loading: false,
      buttonLoading: false,
      dataList: [],
      delivery: {
        trackNo: null,
        logisticsCode: '100012',
        logisticsName: null
      },
      logisticsOptions: [
        { id: '100012', name: '顺丰速运' },
        { id: '190157', name: '圆通' },
        { id: '190324', name: '申通' },
        { id: 'other', name: '其他' }
      ]
    }
  },
  watch: {
    'delivery.logisticsCode': {
      immediate: true,
      handler (code) {
        if (code !== 'other') {
          this.delivery.logisticsName = this.logisticsOptions.find(item => item.id === code).name
        }
      }
    }
  },
  computed: {
  },
  methods: {
    onTabFirstView () {
      this.handleReload()
    },
    onTabViewVisible () {
    },
    onTabViewInvisible () {
    },
    handleReload () {
      this.loading = true
      listProduceItem()
        .complete(() => (this.loading = false))
        .success(resp => {
          const arr = []
          for (const data of resp.data) {
            const item = new SupplierProduceItemModel(data)
            item._uploading = false
            arr.push(item)
          }
          this.dataList = arr
        })
        .send()
    },
    handleDelivery () {
      this.$message.confirm('确认', '确认信息无误', () => {
        const data = Object.assign({}, this.delivery)
        data.items = this.dataList.map(item => {
          return { deliveryId: item.deliveryId, produceItemId: item.id }
        })
        this.buttonLoading = true
        delivery()
          .complete(() => (this.buttonLoading = false))
          .validationFail(resp => {
            if (resp.details && resp.details.length > 0) {
              let msg = ''
              for (const d of resp.details) {
                msg += d.message + '\n'
              }
              this.$message.error(msg)
            }
          })
          .success(() => {
            this.handleReload()
            this.$message.success('发货成功。')
          })
          .send(data)
      })
    },
    takeImage (item) {
      WeChatUtil.chooseImage(img => {
        item._uploading = true
        uploadImage()
          .complete(() => (item._uploading = false))
          .success(resp => {
            item.deliveryImageUrl = resp.data
          })
          .send(item.deliveryId, img)
      })
    },
    handleScanTrackNo () {
      WeChatUtil.scan(code => {
        this.delivery.trackNo = code
      })
    },
    handleRemove (item) {
      this.$loading.open()
      removeFromDeliveryList()
        .complete(() => (this.$loading.close()))
        .success(resp => {
          kit.arr.removeItem(this.dataList, item)
        })
        .send(item.deliveryId)
    }
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
</style>
