import { userStore } from '@/store'
import { ROUTE_HOME, ROUTE_LOGIN } from '@/router/router-constants'

export default function (router) {
  router.beforeEach((to, from, next) => {
    const isLogin = userStore.get('isLogin')
    if (to.name === ROUTE_LOGIN && isLogin) {
      return next({ name: ROUTE_HOME })
    }
    if (to.meta.auth === false) {
      return next()
    }
    if (isLogin) {
      next()
    } else {
      next({ name: ROUTE_LOGIN, query: { from: to.fullPath } })
    }
  })
  return router
}
