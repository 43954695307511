<template>
  <app-page :use-back-icon="false" title="管理">
    <van-pull-refresh
        v-model="refreshing"
        success-text="刷新成功"
        @refresh="handleRefresh"
        :disabled="hasDataLoading"
    >
      <div>
        <div class="section-group">
          <van-cell-group>
            <div slot="title" class="section-group-head">
              <div class="title">设置</div>
            </div>
            <van-cell value="发货地址" is-link :to="{name: routeNames.shippingInfoSetting }">
            </van-cell>
          </van-cell-group>
        </div>


        <div class="section-group">
          <van-cell-group>
            <div slot="title" class="section-group-head">
              <div class="title">
                供应商
                <van-loading v-show="supplierSection.loading" type="circular" size="16" style="display: inline; margin-left: 6px;" />
              </div>
              <div class="action">
                <van-icon name="plus" color="#267ec1" size="18" @click="$refs.supplierEditor.open()"/>
              </div>
            </div>
            <empty-data v-if="supplierSection.dataList.length === 0" />
            <van-swipe-cell v-for="item in supplierSection.dataList" :key="item.id">
              <van-cell :value="item.enabled ? '正常' : '禁用'" is-link :to="{name: routeNames.supplierUser, params: {id: item.id}}">
                <div slot="title" class="item-title" :class="{'removed': !item.enabled}">{{item.name}}</div>
              </van-cell>
              <template #right>
                <van-button square type="danger" text="删除" @click="handleDeleteSupplier(item)" />
                <van-button square type="default" text="编辑" @click="$refs.supplierEditor.open(item)" />
              </template>
            </van-swipe-cell>
          </van-cell-group>
        </div>

        <div class="section-group">
          <van-cell-group>
            <div slot="title" class="section-group-head">
              <div class="title">
                店铺
                <van-loading v-show="shopSection.loading" type="circular" size="16" style="display: inline; margin-left: 6px;" />
              </div>
              <div class="action">
                <van-icon name="plus" color="#267ec1" size="18" @click="shopSection.actionSheet.visible = true"/>
              </div>
            </div>
            <empty-data v-if="shopSection.dataList.length === 0" />
            <van-cell
                v-for="item in shopSection.dataList"
                :key="item.id"
                is-link
                :to="{name: routeNames.shop, params: {id: item.id}}"
            >
              <div slot="title" class="item-title">{{item.name}}</div>
            </van-cell>
          </van-cell-group>
        </div>

        <div class="section-group">
          <van-cell-group>
            <div slot="title" class="section-group-head">
              <div class="title">
                管理员
                <van-loading v-show="userSection.loading" type="circular" size="16" style="display: inline; margin-left: 6px;" />
              </div>
              <div class="action">
                <van-icon name="plus" color="#267ec1" size="18" @click="$refs.userEditor.open()"/>
              </div>
            </div>
            <user-list :users="userSection.dataList" @editClick="handleUserEdit"></user-list>
          </van-cell-group>
        </div>
      </div>
    </van-pull-refresh>

    <user-editor ref="userEditor" @saved="handleUserSaved"></user-editor>
    <supplier-editor ref="supplierEditor" @saved="handleSupplierSaved"></supplier-editor>
    <shop-editor ref="shopEditor" @saved="handleShopSaved"></shop-editor>
    <shop-create-editor ref="shopCreateEditor" @saved="handleShopSaved"></shop-create-editor>

    <van-action-sheet
        v-model="shopSection.actionSheet.visible"
        :actions="shopSection.actionSheet.actions"
        cancel-text="取消"
        close-on-click-action
        @select="handleAddShopTypeSelected"
    />
  </app-page>
</template>

<script>
import SupplierEditor from './supplier/comp/supplier-editor'
import UserList from './user/comp/user-list'
import UserEditor from './user/comp/user-editor'
import ShopEditor from './shop/comp/shop-editor'
import ShopCreateEditor from './shop/comp/shop-create-editor'
import { listSupplier, deleteSupplier } from '../http/api/supplier'
import { managerList } from '../http/api/admin'
import { listShop } from '../http/api/shop'
import kit from '@/utils/kit'
import { ROUTE_SUPPLIER_USER, ROUTE_SHOP, ROUTE_MY, ROUTE_SETTING_SHIPPING_INFO } from '../router/router-constants'
import { SHOP_TYPE_SHOPIFY, SHOP_TYPE_MY, SHOP_TYPE_MAPPING } from '../constants/shop-type'

export default {
  components: { SupplierEditor, UserList, UserEditor, ShopEditor, ShopCreateEditor },
  data () {
    return {
      routeNames: {
        supplierUser: ROUTE_SUPPLIER_USER,
        shop: ROUTE_SHOP,
        shippingInfoSetting: ROUTE_SETTING_SHIPPING_INFO
      },
      refreshing: false,
      supplierSection: {
        loading: false,
        dataList: []
      },
      userSection: {
        loading: false,
        dataList: []
      },
      shopSection: {
        loading: false,
        dataList: [],
        actionSheet: {
          visible: false,
          actions: [
            { id: SHOP_TYPE_SHOPIFY, name: SHOP_TYPE_MAPPING[SHOP_TYPE_SHOPIFY] },
            { id: SHOP_TYPE_MY, name: SHOP_TYPE_MAPPING[SHOP_TYPE_MY] }
          ]
        }
      }
    }
  },
  computed: {
    hasDataLoading () {
      return this.supplierSection.loading || this.userSection.loading || this.shopSection.loading
    }
  },
  methods: {
    onTabFirstView () {
      this.handleRefresh()
    },
    onTabViewVisible () {
    },
    onTabViewInvisible () {
    },
    handleRefresh () {
      this.loadSupplier()
      this.loadUser()
      this.loadShop()
      this.refreshing = false
    },
    loadSupplier () {
      this.supplierSection.loading = true
      listSupplier()
        .complete(() => (this.supplierSection.loading = false))
        .success(resp => {
          this.supplierSection.dataList = resp.data
        })
        .send()
    },
    loadUser () {
      this.userSection.loading = true
      managerList()
        .complete(() => (this.userSection.loading = false))
        .success(resp => {
          this.userSection.dataList = resp.data
        })
        .send()
    },
    loadShop () {
      this.shopSection.loading = true
      listShop()
        .complete(() => (this.shopSection.loading = false))
        .success(resp => {
          this.shopSection.dataList = resp.data
        })
        .send()
    },
    handleSupplierSaved (data) {
      kit.arr.pushOrReplace(this.supplierSection.dataList, data)
    },
    handleDeleteSupplier (data) {
      this.$message.confirm('删除确认', `你确定要删除【${data.name}】吗？`, () => {
        this.$loading.open()
        deleteSupplier()
          .complete(() => (this.$loading.close()))
          .success(() => {
            this.$message.success('删除成功')
            kit.arr.removeItem(this.supplierSection.dataList, data)
          })
          .send(data.id)
      })
    },
    handleUserSaved (data) {
      kit.arr.pushOrReplace(this.userSection.dataList, data)
    },
    handleUserEdit (user) {
      this.$refs.userEditor.open(user)
    },
    handleShopSaved (shop) {
      kit.arr.pushOrReplace(this.shopSection.dataList, shop)
    },
    handleAddShopTypeSelected (item) {
      this.$refs.shopCreateEditor.open(item.id)
    },
    handleNavSetting () {
      this.$router.push({ name: ROUTE_MY })
    }
  }
}
</script>

<style lang="less" scoped>
.section-group + .section-group {
  margin-top: 20px;
}
.section-group-head {
  display: flex;
  .title {
    flex: 1;
  }
  .action {
    padding-left: 10px;
  }
}

.item-title.removed {
  color: #bb3e30;
  text-decoration: line-through;
}
</style>
