<!--
  入库供应商生产的产品。
-->
<template>
  <editor :visible.sync="visible" title="供应商生产入库" :saving="saving" @save="handleSave">
    <div>
      <div style="border-bottom: solid 1px #eee; padding: 10px; margin: 5px;" class="text-normal font-mini">请选择入库的仓库</div>
      <div class="warehouse-wrapper"
           :class="{'warehouse-selected': w._selected}"
           v-for="w in warehouseOptions"
           :key="w.id"
           @click="handleSelectWarehouse(w)"
      >
        <div class="warehouse">{{w.name}}</div>
      </div>
    </div>

    <div style="border-bottom: solid 1px #eee; padding: 10px; margin: 5px;" class="text-normal font-mini">入库商品</div>

    <div class="item-list">
      <div class="produce-item auto-layout center-ver" v-for="item in produceItems" :key="item.id">
        <div class="image-item" v-lazy:background-image="item.getFirstImage()"></div>
        <div class="full" style="margin: 0 15px;">
          <div class="text-normal font-bold"
               :class="{'text-danger': skuNotInStocks.includes(item.id), 'text-success': item.isStockIn}"
          >
            {{item.sku}}
          </div>
          <div class="text-warning font-mini" v-if="!item.sku">没有SKU，此项会被忽略</div>
          <div class="text-warning font-mini" v-else-if="item.warehouseStockRecordId">已经入库，此项会被忽略</div>
          <div class="text-danger font-mini" v-if="skuNotInStocks.includes(item.id)">此SKU不存在系统，无法入库</div>
          <div v-if="item.sku" style="margin-top: 12px;">
            <van-stepper v-model="item.quantity" integer input-width="40px" button-size="32px" />
          </div>
        </div>
        <div class="text-normal font-mini">{{item.number}}</div>
      </div>
    </div>
  </editor>
</template>

<script>
import { stockIn } from '../../../http/api/supplier-produce-item'
import { listWarehouse } from '../../../http/api/warehouse'
import SupplierProduceItem from '../../../model/supplier-produce-item'

export default {
  data () {
    return {
      visible: false,
      saving: false,
      produceItems: [],
      warehouseOptions: [],
      // 入库后，服务端返回入库失败的ID，表示这些SKU不存在系统中
      skuNotInStocks: []
    }
  },
  computed: {
    selectedWarehouse () {
      return this.warehouseOptions.find(item => item._selected)
    }
  },
  watch: {
    visible (v) {
      if (!v) {
        this.close()
      }
    }
  },
  methods: {
    open (supplierProduceItems) {
      this.visible = true
      const arr = []
      for (const data of supplierProduceItems) {
        const item = new SupplierProduceItem(data)
        item.isStockIn = false
        arr.push(item)
      }
      this.produceItems = arr
      if (this.warehouseOptions.length === 0) {
        listWarehouse().success(resp => {
          for (const data of resp.data) {
            data._selected = false
          }
          this.warehouseOptions = resp.data
        }).send()
      }
    },
    close () {
    },
    handleSave () {
      if (this.selectedWarehouse) {
        const dataList = []
        for (const data of this.produceItems) {
          dataList.push({
            id: data.id,
            quantity: data.quantity,
            warehouseId: this.selectedWarehouse.id
          })
        }
        this.skuNotInStocks = []
        this.saving = true
        stockIn()
          .complete(() => (this.saving = false))
          .success(resp => {
            this.$emit('success')
            this.skuNotInStocks.push(...resp.data)
            for (const item of this.produceItems) {
              item.isStockIn = !this.skuNotInStocks.includes(item.id)
            }
            if (this.skuNotInStocks.length > 0) {
              this.$message.success('入库操作成功，部分SKU不存在系统，请检查。')
            } else {
              this.$message.success('入库成功。')
            }
          })
          .send(dataList)
      } else {
        this.$message.error('请选择入库的仓库。')
      }
    },
    handleSelectWarehouse (w) {
      if (this.selectedWarehouse === w) {
        w._selected = false
      } else {
        if (this.selectedWarehouse) {
          this.selectedWarehouse._selected = false
        }
        w._selected = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
.warehouse-wrapper {
  display: inline-block;
  padding: 5px 10px;
  .warehouse {
    margin: 5px;
    padding: 6px 12px;
    border-radius: 5px;
    border: solid 1px #eee;
  }
  &.warehouse-selected {
    .warehouse {
      background-color: #2066b4;
      color: #fff;
    }
  }
}

.item-list {
  margin: 10px;
  .produce-item + .produce-item {
    margin-top: 10px;
    border-top: solid 1px #eee;
  }
  .produce-item {
    &:not(:first-of-type) {
      padding-top: 10px;
    }
  }
}
</style>
